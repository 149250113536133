import eyecare from "assets/images/banners/home/pedia.png";
import aboutus from "assets/images/banners/about/about.png";
import facility from "assets/images/banners/facility/pedia.png";
import info from "assets/images/banners/info/pedia.png";

export const data = {
  id: "pediatrics",
  label: "Paediatrics",
  banner: [
    "Best Pediatric Hospital in Chennai for Every Child",
    "Healing Little Ones with Compassion & Expertise",
  ],
  bannerSize: ["3.375rem", "2.375rem"],
  bannerImg: eyecare,
  aboutImg: aboutus,
  about: [
    {
      text: "40+",
      subText: "Years of Experience",
    },
    {
      text: "2 Lac+",
      subText: "Surgeries",
    },
    {
      text: "4.8 Lac+",
      subText: "Happy Patients",
    },
    {
      text: "25000+",
      subText: "Specs Removed",
    },
  ],
  facilityImg: facility,
  infoImg: info,
  info: {
    heder: "Paediatric CONGENITAL HEART SURGERY",
    subheader: "Transforming Hearts, Saving Lives",
    point1: "Advanced Surgical Techniques, Minimally Invasive Procedures",
    point2: "Expert Paediatric Surgeons, Swift Recovery",
  },
  facility: {
    mainHeader: "Expert Care for Your Little One’s Health",
    subHeader: "Best Pediatrician in Chennai for Your Child's Well-Being",
    content:
      "Searching for the best pediatric hospital in Chennai? Our paediatricians provide individualized, compassionate care to foster the well-being of your child. We cater to each child's unique needs, providing the best possible care in a friendly, welcoming environment for healthy, joyful lives.",
  },
  serviceHeader: "Our Paediatrics Services",
  services: [
    {
      id: "generalPaediatrics",
      label: "General Paediatrics",
      content:
        "Comprehensive care for children, from routine check-ups to treating acute and chronic illnesses. We ensure your child's well-being at every stage of development.",
    },
    {
      id: "paediatricsSurgery",
      label: "Paediatrics Surgery",
      content:
        "Advanced & minimally invasive surgical procedures tailored specifically for children. You can trust our expert surgeons for compassionate and effective care.",
    },
    {
      id: "paediatricsAndNeonatalICU",
      label: "Paediatrics and Neonatal ICU",
      content:
        "State-of-the-art ICU facilities for infants and children requiring intensive care, with specialized medical teams available around the clock.",
    },
    {
      id: "childPsychology",
      label: "Child Psychology",
      content:
        "Expert psychological care for your child’s mental and emotional well-being. Our specialists help children overcome challenges and thrive.",
    },
    {
      id: "childRehabilitation",
      label: "Child Rehabilitation",
      content:
        "Specialized rehabilitation services to aid in your child’s recovery from injury or illness, promoting physical and cognitive development through therapy programs.",
    },
    {
      id: "paediatricsNutrition",
      label: "Paediatrics Nutrition",
      content:
        "Expert guidance on nutrition to ensure your child’s healthy growth. Our nutritionists work closely with families to create balanced, child-friendly dietary plans.",
    },
  ],
  bestPediatricServices: {
    heading: "Best Pediatric Services in Chennai",
    services: [
      {
        id: "generalPediatrics",
        label: "1.General Pediatrics",
        content:
          "Extensive pediatric care, including acute and chronic sickness treatment and routine checkups. Your child's health and happiness are guaranteed at every developmental stage by our best pediatric hospital in Chennai.",
      },
      {
        id: "pediatricEmergencyCare",
        label: "2.Pediatric Emergency Care",
        content:
          "Expert and immediate treatment for kids during medical emergencies. Our child hospital is equipped with the best pediatric team, providing quick and effective care for your child’s urgent needs.",
      },
      {
        id: "pediatricVaccinesPreventiveCare",
        label: "3. Pediatric Vaccines & Preventive Care",
        content:
          "Stay ahead with vital vaccinations and preventive treatment at our Chennai baby hospital. Our expert pediatricians provide thorough immunization schedules and health checks.",
      },
      {
        id: "pediatricSurgeryRecovery",
        label: "4. Pediatric Surgery and Recovery",
        content:
          "We offer postoperative recovery care and pediatric surgery. The comfort, safety, and speedy recovery of your child are guaranteed at every stage by skilled surgeons and pediatricians in Chennai.",
      },
      {
        id: "childDevelopmentGrowthMonitoring",
        label: "5. Child Development & Growth Monitoring",
        content:
          "Tracking your child's growth and development milestones with professional care. Our Chennai pediatric hospital has routine check-ups so that your child develops healthily with customized support from pediatricians.",
      },
      {
        id: "pediatricNeurologyCare",
        label: "6. Pediatric Neurology Care",
        content:
          "Children with neurological conditions, such as seizures or developmental delays, receive professional care. The best pediatricians in Chennai provide professional care for your child's neurological health at our top pediatric hospital.",
      },
      {
        id: "pediatricCardiologyServices",
        label: "7. Pediatric Cardiology Services",
        content:
          "Expert care for heart disorders in children. At our child hospital in Chennai, cardiologists provide state-of-the-art diagnosis and treatment to safeguard your child's heart health with empathy.",
      },
      {
        id: "pediatricDermatologyCare",
        label: "8. Pediatric Dermatology Care",
        content:
          "Treatments of skin disorders in children with special pediatric dermatology care. From rashes to eczema, our Chennai’s best pediatricians give the best treatment gently and effectively to maintain your child's skin safe and healthy.",
      },
    ],
  },
  faqsSection: {
    heading: "FAQs Section",
    services: [
      {
        label: "1. Why Amrit Hospital for Your Child's Care?",
        content:
          "Amrit Hospital is the most popular option among parents who desire skilled pediatric services. Our team of skilled pediatricians, advanced medical equipment, and play environment ensure your child gets the world's best care. Ranging from regular check-ups to acute and chronic illnesses, our best pediatricians in Chennai offer high-quality services for your child's maintenance during growth.",
      },
      {
        label: "2. What Pediatric Facilities Does Amrit Hospital Provide?",
        content:
          "At Amrit Hospital, patients gain access to multiple pediatric facilities, which include standard examinations together with specialized treatments for extended health problems. We function as Chennai's best pediatric hospital and deliver such services—surgical care alongside urgent health services, vaccine distribution, and ongoing disease management for short-term and long-term conditions. The pediatricians in Chennai monitor your child's health throughout their developmental phases.",
      },
      {
        label: "3. Is Amrit Hospital Ready for Pediatric Emergencies?",
        content:
          "Amrit Hospital is highly capable of handling pediatric emergencies. Our baby hospital in Chennai has an independent emergency unit with the best modern medical equipment. The medical team provides instant vital intervention for dangerous medical situations that supports both stabilization and recovery for your child.",
      },
      {
        label: "4. How Does Amrit Hospital Ensure Your Child's Safety While Undergoing Surgery?",
        content:
          "The safety of your child during child surgery is maintained at Amrit Hospital through the newest surgical techniques and machinery. We take all the necessary steps with our best-trained pediatric surgeons and pediatricians in Chennai. We offer extensive pre-operative tests and expert post-operative care to achieve a safe and hassle-free recovery.",
      },
      {
        label: "5. Does Amrit Hospital Provide Vaccination for Children?",
        content:
          "The vaccination services at Amrit Hospital encompass every needed immunization for pediatric patients. We operate as the best pediatric hospital in Chennai and follow the current vaccination guidelines while protecting your child from preventable diseases. Our physicians help with child vaccination while simultaneously maintaining children's health and safety.",
      },
      {
        label: "6. Why Amrit Hospital Stands Apart From Other Pediatric Hospitals in Chennai?",
        content:
          "Amrit Hospital is considered the best pediatric hospital in Chennai for holistic treatment of the health of a child. Our pediatricians bring together medical competence and loving care, working toward keeping your child happy and healthy on an emotional as well as a physical level. Our centers for diagnosis are state-of-the-art and have all the amenities to treat different conditions and our facilities rank among the top from infancy to early adulthood.",
      },
      {
        label: "7. How do I book an appointment at Amrit Hospital?",
        content:
          "It is quite simple to book an appointment at Amrit Hospital. Patients can reach out to our hospital either through calling or through our online appointment booking system. The staff at our facility will organize an appointment with Chennai’s best pediatricians in a time slot that is convenient for you. We offer prompt, efficient treatment to your child.",
      },
    ],
  },
};