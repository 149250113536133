import { Box, Typography, Button } from "@mui/material";
import { BookAppointmentModel } from "components";
import { useState } from "react";

const ProgramCard = ({ header, description, icon, children }) => {
 

  return (
    <Box
      boxShadow={"0 0 10px 0 rgba(153, 153, 153, 0.2)"}
      p={4}
      mt={6}
      height={"calc(100% - 3rem)"}
      borderRadius={"1rem"}
      bgcolor={"common.white"}
      display={"flex"}
      flexDirection={"column"}
    >
     
      <Typography variant="h4" fontWeight="bold" >
        {header}
       
      </Typography>
      {children}
      <Typography variant="body2" color="#999" py={3.5}>
        {description}
      </Typography>
     
    </Box>
  );
};

export default ProgramCard;
