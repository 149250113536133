import { Box, Typography, Button, Grid } from "@mui/material";

import { ProgramCard, BookAppointmentModel } from "components";
import { useState } from "react";
import opinon from "assets/images/opinon.svg";
import checkup from "assets/images/health.svg";
import eye from "assets/images/eye.svg";
import med from "assets/images/med.svg";
import Tick from "assets/images/Tick.png";

import bgpattern from "assets/images/background/bg-pattern.png";
import bgpatternRight from "assets/images/background/bg-pattern-right.png";
import { Padding } from "@mui/icons-material";

const Programs = () => {
   const [openModel, setOpenModel] = useState(false);
  
    const openPopup = () => setOpenModel(true);
    const closePopup = () => setOpenModel(false);
  return (
    <Box
      pb={{ md: 10, xs: 5 }}
      pt={{ md: 13, xs: 8 }}
      px={{ md: 18, xs: 5 }}
      
      sx={{
        background: `url(${bgpattern}) no-repeat, url(${bgpatternRight}) no-repeat`,
        backgroundSize: "35rem, 35rem",
        backgroundPosition: "left bottom, right bottom",
      }}
    >
      <Typography variant="subHeader" mb={2}>
        Our Health Checkup Programs
      </Typography>
      <Grid container spacing={4} columnSpacing={20} pt={8}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <ProgramCard
          header="Master Health Check - Up"
        >
          <Grid container spacing={4} pt={8} sx={{ alignItems: 'center', }}>
            <Grid item xs={12} sm={6} md={6} lg={6}  sx={{ padding: '0 0 0 25px !important', }}>
              <div className="pricembr">₹ 2500 <span>/ for Male</span></div>
              <div className="pricembr">₹ 2700 <span>/ / for Female</span></div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: '0 0 0 0 !important', }}>
              <Box mt={"auto"} sx={{ textAlign:'right', }}>
              <Button
                  variant="outlined"
                  onClick={openPopup}
                  sx={{
                    backgroundColor: '#2EAD9F', 
                    color: '#fff', 
                    borderColor: '#2EAD9F', 
                    fontWeight: 600, 
                    fontSize: '14px',
                    padding:'8px 24px',
                    '&:hover': {
                      backgroundColor: '#2EAD9F', 
                      borderColor: '#2EAD9F' 
                    }
                  }}
                >
                  Book Now
                </Button>
              </Box>
              {openModel && <BookAppointmentModel onClose={closePopup} />}
            </Grid>
              <ul className="ticklistmbr">
                <li><img src={Tick} /> Complete Haemogram</li>
                <li><img src={Tick} /> Lipid Profile</li>
                <li><img src={Tick} /> LFT</li>
                <li><img src={Tick} /> Blood Urea</li>
                <li><img src={Tick} /> S. Creatinine</li>
                <li><img src={Tick} /> Uric Acid</li>
                <li><img src={Tick} /> FBS</li>
                <li><img src={Tick} /> PPBS</li>
                <li><img src={Tick} /> Urine Routine</li>
                <li><img src={Tick} /> Blood Grouping & Typing</li>
                <li><img src={Tick} /> ECG</li>
                <li><img src={Tick} /> Chest X-Ray</li>
                <li><img src={Tick} /> USG - Abdomen</li>
                <li><img src={Tick} /> PAP Smear for Women</li>
              </ul>

          </Grid>

        </ProgramCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <ProgramCard
          header="Well Women Health Check - Up"
        >
          <Grid container spacing={4} pt={8} sx={{ alignItems: 'center', }}>
            <Grid item xs={12} sm={6} md={6} lg={6}  sx={{ padding: '0 0 0 25px !important', }}>
              <div className="pricembrr">₹ 3200 </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: '0 0 0 0 !important', }}>
              <Box mt={"auto"} sx={{ textAlign:'right', }}>
              <Button
                  variant="outlined"
                  onClick={openPopup}
                  sx={{
                    backgroundColor: '#2EAD9F', 
                    color: '#fff', 
                    borderColor: '#2EAD9F', 
                    fontWeight: 600, 
                    fontSize: '14px',
                    padding:'8px 24px',
                    '&:hover': {
                      backgroundColor: '#2EAD9F', 
                      borderColor: '#2EAD9F' 
                    }
                  }}
                >
                  Book Now
                </Button>
              </Box>
              {openModel && <BookAppointmentModel onClose={closePopup} />}
            </Grid>
              <ul className="ticklistmbr">
                <li><img src={Tick} /> Complete Haemogram</li>
                <li><img src={Tick} /> Lipid Profile</li>
                <li><img src={Tick} /> LFT</li>
                <li><img src={Tick} /> Blood Urea</li>
                <li><img src={Tick} /> S. Creatinine</li>
                <li><img src={Tick} /> Uric Acid</li>
                <li><img src={Tick} /> FBS</li>
                <li><img src={Tick} /> PPBS</li>
                <li><img src={Tick} /> Urine Routine</li>
                <li><img src={Tick} /> Blood Grouping & Typing</li>
                <li><img src={Tick} /> ECG</li>
                <li><img src={Tick} /> Chest X-Ray</li>
                <li><img src={Tick} /> USG - Abdomen</li>
                <li><img src={Tick} /> PAP Smear for Women</li>
              </ul>

          </Grid>

        </ProgramCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <ProgramCard
          header="Diabetic Check - Up"
        >
          <Grid container spacing={4} pt={8} sx={{ alignItems: 'center', }}>
            <Grid item xs={12} sm={6} md={6} lg={6}  sx={{ padding: '0 0 0 25px !important', }}>
              <div className="pricembrr">₹ 2050 </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: '0 0 0 0 !important', }}>
              <Box mt={"auto"} sx={{ textAlign:'right', }}>
              <Button
                  variant="outlined"
                  onClick={openPopup}
                  sx={{
                    backgroundColor: '#2EAD9F', 
                    color: '#fff', 
                    borderColor: '#2EAD9F', 
                    fontWeight: 600, 
                    fontSize: '14px',
                    padding:'8px 24px',
                    '&:hover': {
                      backgroundColor: '#2EAD9F', 
                      borderColor: '#2EAD9F' 
                    }
                  }}
                >
                  Book Now
                </Button>
              </Box>
              {openModel && <BookAppointmentModel onClose={closePopup} />}
            </Grid>
              <ul className="ticklistmbr">
                <li><img src={Tick} /> Complete Haemogram</li>
                <li><img src={Tick} /> Lipid Profile</li>
                <li><img src={Tick} /> LFT</li>
                <li><img src={Tick} /> Blood Urea</li>
                <li><img src={Tick} /> S. Creatinine</li>
                <li><img src={Tick} /> Uric Acid</li>
                <li><img src={Tick} /> FBS</li>
                <li><img src={Tick} /> PPBS</li>
                <li><img src={Tick} /> Urine Routine</li>
                <li><img src={Tick} /> Blood Grouping & Typing</li>
                <li><img src={Tick} /> ECG</li>
                <li><img src={Tick} /> Chest X-Ray</li>
                <li><img src={Tick} /> USG - Abdomen</li>
                <li><img src={Tick} /> PAP Smear for Women</li>
              </ul>

          </Grid>

        </ProgramCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <ProgramCard
          header="Advanced Health Check - Up"
        >
          <Grid container spacing={4} pt={8} sx={{ alignItems: 'center', }}>
            <Grid item xs={12} sm={6} md={6} lg={6}  sx={{ padding: '0 0 0 25px !important', }}>
              <div className="pricembrr">₹ 3700 </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: '0 0 0 0 !important', }}>
              <Box mt={"auto"} sx={{ textAlign:'right', }}>
              <Button
                  variant="outlined"
                  onClick={openPopup}
                  sx={{
                    backgroundColor: '#2EAD9F', 
                    color: '#fff', 
                    borderColor: '#2EAD9F', 
                    fontWeight: 600, 
                    fontSize: '14px',
                    padding:'8px 24px',
                    '&:hover': {
                      backgroundColor: '#2EAD9F', 
                      borderColor: '#2EAD9F' 
                    }
                  }}
                >
                  Book Now
                </Button>
              </Box>
              {openModel && <BookAppointmentModel onClose={closePopup} />}
            </Grid>
              <ul className="ticklistmbr">
                <li><img src={Tick} /> Complete Haemogram</li>
                <li><img src={Tick} /> Lipid Profile</li>
                <li><img src={Tick} /> LFT</li>
                <li><img src={Tick} /> Blood Urea</li>
                <li><img src={Tick} /> S. Creatinine</li>
                <li><img src={Tick} /> Uric Acid</li>
                <li><img src={Tick} /> FBS</li>
                <li><img src={Tick} /> PPBS</li>
                <li><img src={Tick} /> Urine Routine</li>
                <li><img src={Tick} /> Blood Grouping & Typing</li>
                <li><img src={Tick} /> ECG</li>
                <li><img src={Tick} /> Chest X-Ray</li>
                <li><img src={Tick} /> USG - Abdomen</li>
                <li><img src={Tick} /> PAP Smear for Women</li>
              </ul>

          </Grid>

        </ProgramCard>
        </Grid>
      </Grid>
      <style>
{`
.ticklistmbr {
    Padding: 15px 0 0 0;
    margin: 15px 0 0 26px;
    list-style: none;
    text-align: left;
    border-top: 1px solid #D8D8D8;
}
.ticklistmbr li {
    float: left;
    width: 50%;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 30px;
}
.pricembr span{
  font-weight: 400;
  font-size: 18px;
  color:#7C818F;
}
.pricembr {
    font-size: 23px;
    margin: 0 0 10px;
    font-weight: 600;
}
.pricembrr {
    font-size: 23px;
    margin: 0 0 0;
    font-weight: 600;
}
`}
</style>
    </Box>
    
  );
};

export default Programs;
