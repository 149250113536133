import ent from "assets/images/banners/internalDepartment/ENT.jpg";
import dermatologist from "assets/images/banners/internalDepartment/dermatology.jpg";
import nerurology from "assets/images/banners/internalDepartment/neurology.jpg";
import nephrology from "assets/images/banners/internalDepartment/nephrology.jpg";
import diabetology from "assets/images/banners/internalDepartment/diabetology.jpg";
import generalSurgery from "assets/images/banners/internalDepartment/gensurgery&laproscopic.jpg";
import cardio from "assets/images/banners/internalDepartment/cardiology.jpg";
import psiyo from "assets/images/banners/internalDepartment/physio.jpg";
import gastro from "assets/images/banners/internalDepartment/gastrointerology.jpg";
import radio from "assets/images/banners/internalDepartment/radio.jpg";
import internalMed from "assets/images/banners/internalDepartment/internalmedicine.jpg";
import cc from "assets/images/banners/internalDepartment/cc.jpg";

import ent1 from "assets/images/banners/home/ent.png";
import dermatologist1 from "assets/images/banners/home/derma.png";
import nerurology1 from "assets/images/banners/home/neuro.png";
import nephrology1 from "assets/images/banners/home/nephro.png";
import diabetology1 from "assets/images/banners/home/dieb.png";
import generalSurgery1 from "assets/images/banners/home/gen.png";
import cardio1 from "assets/images/banners/home/cardio.png";
import psiyo1 from "assets/images/banners/home/physio.png";
import gastro1 from "assets/images/banners/home/gastro.png";
import radio1 from "assets/images/banners/home/radio.png";
import internalMed1 from "assets/images/banners/home/internalmeds.png";
import cc1 from "assets/images/banners/home/cc.png";

export const list = [
  {
    id: "internalMedicine",
    label: "Internal Medicine",
    content: "datamap",
  },
  {
    id: "diabetology",
    label: "Diabetology",
    content: "datamap",
  },
  {
    id: "generalSurgery",
    label: "General Surgery and Laparoscopic",
    content: "datamap",
  },
  {
    id: "ent",
    label: "ENT",
    content: "datamap",
  },
  {
    id: "cardiology",
    label: "Cardiology",
    content: "datamap",
  },
  {
    id: "nephrology",
    label: "Nephrology",
    content: "datamap",
  },
  {
    id: "neurology",
    label: "Neurology",
    content: "datamap",
  },
  {
    id: "dermatology",
    label: "Dermatology",
  },
  {
    id: "physiotherapy",
    label: "Physiotherapy",
    content: "datamap",
  },

  {
    id: "gastroenterology",
    label: "Gastroenterology",
    content: "datamap",
  },

  {
    id: "radiology",
    label: "Radiology",
    content: "datamap",
  },

  {
    id: "criticalCare",
    label: "Critical Care",
    content: "datamap",
  },
];

export const internalData = [
  {
    id: "internalMedicine",
    label: "Department of || Internal Medicine",
    banner: internalMed1,
    img: internalMed,
    data: [
      {
        header: "Internal Medicine: Comprehensive Healthcare Management",
        content:
          "Internal Medicine at Amrit Hospital focuses on the diagnosis, treatment, and prevention of adult diseases. Our department is dedicated to managing a wide range of medical conditions, from chronic illnesses like diabetes and hypertension to acute infections and complex disorders affecting multiple organ systems. Led by experienced physicians, we provide personalized care aimed at optimizing each patient's health and well-being. Common conditions managed by our Internal Medicine specialists include cardiovascular diseases, respiratory disorders, gastrointestinal issues, and endocrine disorders such as thyroid dysfunction. We emphasize preventive care, early detection through regular health screenings, and evidence-based treatments to achieve the best possible outcomes for our patients.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "Recognizing symptoms that may indicate an underlying medical issue is crucial for timely intervention and effective management. Here are some symptoms that should prompt a consultation with an Internal Medicine specialist:",
        points: [
          "Persistent high blood pressure or fluctuations in blood pressure readings.",
          "Unexplained weight changes, especially rapid or unintended weight loss.",
          "Chronic fatigue or unexplained tiredness despite adequate rest.",
          "Persistent cough or shortness of breath, particularly with exertion.",
          "Digestive issues such as persistent abdominal pain, bloating, or changes in bowel habits.",
          "Elevated blood sugar levels or symptoms of diabetes, such as increased thirst and frequent urination.",
        ],
        summary:
          "If you experience any of these symptoms or have concerns about your overall health, our Internal Medicine team is here to provide comprehensive evaluation, diagnosis, and treatment tailored to your specific needs.",
      },
    ],
  },
  {
    id: "dermatology",
    label: "Best Dermatology Hospital in Chennai for Healthy Skin || Revitalize Your Skin with Advanced Treatments ",
    banner: dermatologist1,
    img: dermatologist,
    
    data: [
      {
        header: "Experience Flawless Skin at Chennai’s Best Hospital",
        subheader: "Expert Skin Care by Chennai’s Best Dermatologists",
        content:
          "Searching for Chennai's best dermatology hospital? Our team of skilled dermatologists provides the latest treatments for every skin type and concern. Need to get rid of acne, scars, or rejuvenate? We give you individualized care and state-of-the-art solutions to help you achieve unblemished, radiant skin. Try trusted skincare today.",
        points: null,
       
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "It's essential to recognize early signs of dermatological conditions to seek timely medical intervention.",
        points: [
          "Persistent itching or rash that doesn’t improve with over-the-counter treatments.",
          "Unusual moles or growths on the skin that change in size, shape, or color.",
          "Chronic dryness or scaling that doesn't respond to moisturizing.",
          "Severe acne that causes scarring or emotional distress.",
          "Hair loss or thinning that seems unusual or sudden.",
          "Nail abnormalities such as discoloration, thickening, or detachment.",
        ],
        summary:
          "If you experience any of these symptoms, it's crucial to consult a dermatologist.",
          
          
      },
      
      {
        heading: "Best Dermatology  Services in Chennai",
        label: "1. Acne Treatment",
        contentt:
          "Get smooth, healthy skin with cutting-edge acne treatment at the best dermatology hospital in Chennai. Our experienced dermatologists provide personalized acne therapy, scar therapy, and skin rejuvenation.",
      },
      {
        label: "2. Anti-Aging Solutions",
        contentt:
          "Reverse aging with state-of-the-art anti-aging therapies. Get wrinkle reduction, skin tightening, and rejuvenating treatments for naturally glowing and younger-looking skin from our Chennai dermatologists.",
      },
      {
        label: "3. Skin Allergy Management",
        contentt:
          "Find relief from skin allergies at the top dermatology hospital in Chennai. Our Chennai dermatologists offer personalized solutions for robust, healthy, and radiant hair.",
      },
      {
        label: "4. Skin Pigmentation and Lightening",
        contentt:
          "Achieve a perfect even tone with the most modern pigmentation solutions. Our skin hospital in Chennai provides effective, safe solutions for dark spots, melasma, and glowing, brighter-looking skin.",
      },
      {
        label: "5. Treatment of Eczema and Psoriasis",
        contentt:
          "Experience expert psoriasis and eczema care at the best skin hospital in Chennai, with tailored attention to experience silky smooth, comfortable, and healthy skin.",
      },
      {
        label: "6. Laser Treatments",
        contentt:
          "Experience top laser hair reduction, scar revision, and skin rejuvenation treatments. Our dermatologists in Chennai perform safe and enduring, long-term results treatments.",
      },
      {
        label: "7. Cosmetic Dermatology",
        contentt:
          "Look and feel younger with premium cosmetic dermatology treatments. Our best Chennai dermatologists provide fillers, chemical peels, and cosmetic treatments for flawless and glowing skin.",
      },

      {
        faqheading: "FAQs Section",
        labelfaq: "1. Why Amrit Hospital is the Best Dermatology Hospital in Chennai?",
        contenttfaq:
          "Amrit Hospital is the best skin hospital in Chennai due to our experts, advanced treatment, and patient care. We apply the most advanced treatments for acne, pigmentation, skin allergies, and cosmetic dermatology. We apply medical science with the latest technology to give better care and visible outcomes.",
      },
      {
        labelfaq: "2. What are the facilities for skin treatment at Amrit Hospital?",
        contenttfaq:
          "We have full dermatology treatment for acne, anti-aging, skin allergy management, whitening treatment and pigmentation treatment, and cosmetic dermatology at Amrit Hospital. Our top dermatologists in Chennai treat you with personalized solutions to fix individual skin issues and provide you with healthy, glowing skin. We adopt the latest technology and tested methods to provide the best treatments.",
      },
      {
        labelfaq: "3. How does Amrit Hospital treat hair and scalp issues?",
        contenttfaq:
          "Amrit Hospital provides the best hair loss, dandruff, and scalp treatment. Our Chennai dermatologists apply medical treatment, cutting-edge treatments, and personalized care to encourage hair and scalp to grow healthy. We make every treatment safe, effective, and personalized according to individual needs.",
      },
      {
        labelfaq: "4. What laser treatments does Amrit Hospital offer?",
        contenttfaq:
          "Amrit Hospital offers comprehensive laser treatments such as laser hair reduction, scar minimization, and skin restoration. Our top dermatology hospital in Chennai applies the most updated technology for safe, efficient, and minimally possible interventions. Our dermatologists are highly experienced in providing exact and traceable results, improving looks and confidence.",
      },
      {
        labelfaq: "5. How Can Amrit Hospital Help with Skin Allergies and Problems?",
        contenttfaq:
          "In our Amrit Hospital, our Chennai-based dermatologists are skilled in treating eczema, psoriasis, skin allergies, and other chronic skin diseases. We offer customized treatment plans, i.e., medicine, a skin care regimen, and state-of-the-art treatments to control the symptoms and to achieve an improvement in the skin condition. Our empathetic approach comforts and provides long-lasting satisfaction.",
      },
      {
        labelfaq: "6. Does Amrit Hospital offer cosmetic dermatology services?",
        contenttfaq:
          "Yes, we have the best cosmetic dermatology treatments like fillers, chemical peels, skin tightening, and anti-aging at Amrit Hospital. Our best dermatologists in Chennai offer safe and trustworthy aesthetic treatments to let one's inner glow shine and attain perfect, flawless skin. We offer natural-looking outcomes and satisfactory results.",
      },
      {
        labelfaq: "7. How Do I Book an Appointment with the Amrit Hospital Dermatologists?",
        contenttfaq:
          "It is simple to make an appointment with our best dermatologists in Chennai. You can call us on our website, dial our toll-free helpline number, or visit our hospital. Our cheerful staff will assist you and provide the best-class care and personalized treatment for your hair and skin issues.",
      },
    ],
    
  },
  {
    id: "diabetology",
    label: "Best Diabetes Hospital in Chennai—Live Healthier || Comprehensive Diabetes Care Under One Roof",
    banner: diabetology1,
    img: diabetology,
    data: [
      {
        header: "Your Diabetes, Our Expertise—Chennai’s Best Hospital",
        subheader: "Best Diabetologists in Chennai, Trusted by Many",
        content:
          "Looking for the best diabetes hospital in Chennai? Our experienced diabetologists ensure world-class treatment, the latest technology, and personal attention to manage diabetes effectively. Avail the best medical care for a healthier tomorrow. Book your appointment today!",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content: "Monitoring symptoms is key to effective diabetes management.",
        points: [
          "Frequent urination.",
          "Increased thirst.",
          "Unexplained weight loss.",
          "Fatigue or weakness.",
          "Blurred vision.",
          "Slow-healing wounds.",
        ],
        summary:
          "If you notice any of these symptoms or have concerns about your blood sugar levels, our team is here to provide expert guidance and support to help you manage your diabetes effectively and maintain a healthy active lifestyle.",
      },
      {
        heading: "Best Diabetes Services in Chennai",
        label: "1. Comprehensive Diabetes Care:",
        contentt:
          "Get expert experience and personalized treatment from skilled diabetologists in the best diabetes hospital in Chennai for advanced diabetes management and prevention.",
      },
      {
        label: "2. Advanced Diabetes Screening:",
        contentt:
          "Know before it shows! Our expert specialists apply modern diagnostic technologies to detect the risks of diabetes to manage your disease effectively in the best diabetes hospital in Chennai.",
      },
      {
        label: "3. Personalized Diabetes Care",
        contentt:
          "Our specialist diabetologists plan customized treatment programs, merging diet, lifestyle modification, and medications for successful diabetes management at our top-ranked hospital in Chennai.",
      },
      {
        label: "4. Gestational Diabetes Care:",
        contentt:
          "Pregnant women are treated with special attention to manage gestational diabetes by the best Chennai diabetes hospital's professional care. We provide personalized treatment programs, follow-up, and dietary counseling.",
      },
      {
        label: "5. Diabetes Foot Care & Treatment",
        contentt:
          "Protect your feet from complications. Our experts offer advanced screenings, wound care, and preventional treatments for diabetic foot complications with experienced diabetologists.",
      },
      {
        label: "6. Reversal Programs of Diabetes",
        contentt:
          "Adopt our evidence-based reversal procedures for lifestyle modification, nutrition, and medical advice by expert diabetologists in Chennai to have long-term blood glucose control.",
      },
      {
        label: "7. Insulin Therapy & Medication Management",
        contentt:
          "Avail individualized insulin therapy and drug management to maintain the blood glucose level. Our diabetologists in Chennai provide accurate treatment for healthy diabetes control and improved well-being.",
      },
      {
        label: "8. Diabetes Education & Lifestyle Counseling",
        contentt:
          "Improve your life with expert dietary and health guidance and lifestyle change for appropriate control of diabetes at the best hospital in Chennai.",
      },

      {
        faqheading: "FAQs Section",
        labelfaq: "1. Why is Amrit Hospital Chennai the best hospital for diabetes?",
        contenttfaq:
          "Quality treatment, newest technology, and patient friendliness have brought Amrit Hospital to the position of the best hospital for diabetes. With highly experienced diabetologists in Chennai, our patients receive specialized care such as insulin therapy, treatment of diabetes complications, and care for gestational diabetes.",
      },
      {
        labelfaq: "2. What are the different diabetes care centers provided by Amrit Hospital?",
        contenttfaq:
          "We offer a broad range of diabetes care, including Type 1 and Type 2 diabetes care, insulin therapy, continuous glucose monitoring (CGM), dietary and lifestyle advice, and complication management like neuropathy and retinopathy. Our best diabetologists in Chennai offer personalized treatment plans through the most modern technology and medical skills to produce optimal outcomes.",
      },
      {
        labelfaq: "3. What are the diabetes complications treatments at Amrit Hospital?",
        contenttfaq:
          "Diabetic complications have to be treated with great caution. We prevent and manage complications like neuropathy, retinopathy, and cardiovascular disease by following patients regularly, utilizing the most updated diagnosis, and personalized treatment procedures. Our Chennai diabetologists treat the patient, give proper treatment, and improve the quality of life.",
      },
      {
        labelfaq: "4. What is the gestational diabetes protocol in Amrit Hospital?",
        contenttfaq:
          "We have gestational diabetes special care with additional care for the mother and baby's well-being. We provide personalized treatment plans, follow-up, and dietary advice for proper control of blood sugar during pregnancy. We have an equilibrium risk control policy and a healthy pregnancy with greater emphasis on individualized attention, counseling, and care for the pregnant woman.",
      },
      {
        labelfaq: "5. What Is the Role of Lifestyle and Diet Counseling in Diabetes?",
        contenttfaq:
          "Diabetes management is more than just medication. Our best hospital for diabetes in Chennai provides personal counseling for diet, exercise, and health to manage blood glucose, maintain and attain a healthy weight, and prevent complications. Our experts commit themselves to patient education and enabling patients to create healthy habits and sustain long-term well-being.",
      },
      {
        labelfaq: "6. Does Amrit Hospital Have Continuous Glucose Monitoring (CGM)?",
        contenttfaq:
          "Yes, we offer the newest continuous glucose monitoring (CGM) technology for accurate, real-time measurement of blood glucose. CGM makes it easy for diabetologists and patients to make fact-based decisions to achieve best control of blood sugar. CGM is particularly useful in those patients requiring stringent glucose control because it helps recognize trends at an early stage.",
      },
      {
        labelfaq: "7. How to Book a Consultation with Amrit Hospital's Diabetologists?",
        contenttfaq:
          "You can visit our website, give us a call on our toll-free number, or just come to our Chennai diabetes hospital and book your appointment. We will assist you with the procedure so that you get treated within time and in a professional way. Our professionals will analyze your condition, tell you about the likely treatment methods, and prepare a personalized plan to control your diabetes effectively.",
      },
    ],
  },
  {
    id: "generalSurgery",
    label: "Department of || General Surgery and Laparoscopic",
    banner: generalSurgery1,
    img: generalSurgery,
    data: [
      {
        header: "Advanced and Minimally Invasive Surgeries",
        content:
          "The Department of General Surgery and Laparoscopic Surgery at Amrit Hospital offers expert care for a wide range of surgical conditions. Our team of highly skilled surgeons is equipped with advanced technology to perform both traditional and minimally invasive procedures. We focus on delivering personalized care, ensuring that each patient receives the most effective treatment for their specific condition. Our general surgery services include the management of abdominal issues, hernias, and various soft tissue surgeries. Laparoscopic surgery, also known as minimally invasive surgery, allows for quicker recovery times and less post-operative discomfort compared to traditional open surgeries. We are committed to providing excellent surgical outcomes while prioritizing patient safety and comfort throughout the process.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "Certain symptoms may indicate the need for a consultation with a general or laparoscopic surgeon.",
        points: [
          "Persistent abdominal pain.",
          "Unexplained weight loss.",
          "Difficulty swallowing.",
          "Severe hernia or bulge.",
          "Chronic nausea or vomiting.",
          "Noticeable lumps or growths.",
        ],
        summary:
          "Prompt evaluation can help in early diagnosis and effective management of surgical conditions, ensuring you receive the appropriate care to address your health concerns.",
      },
    ],
  },
  {
    id: "ent",
    label: "Trusted & Best ENT Hospital in Chennai || Restoring Health with Expert ENT Treatment",
    banner: ent1,
    img: ent,
    data: [
      {
        header: "Breathe Easy, Hear Clear, Live Better!",
        subheader: "Consult the Best ENT Specialists in Chennai",
        content:
          "Experience the world's finest treatment at Chennai's Best ENT Hospital, where highly skilled ENT specialists provide expert care for all throat, ear, and nose ailments. Our emphasis on cutting-edge technology and patient comfort guarantees efficient, personalized treatment options. Trust us for complete ENT solutions customized to suit you.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "Certain symptoms may indicate a need for an ENT consultation.",
        points: [
          "Persistent ear pain or discomfort.",
          "Chronic nasal congestion or bleeding.",
          "Hoarseness or loss of voice.",
          "Frequent sore throats or infections.",
          "Dizziness or balance issues.",
          "Hearing loss or ringing in the ears.",
        ],
        summary:
          "Early assessment by an ENT specialist can help diagnose underlying issues and ensure appropriate treatment to relieve symptoms and restore your health.",
      },
      {
        heading: "Best ENT Services in Chennai",
        label: "1. Enhanced Ear Care & Hearing Solutions",
        contentt:
          "Our experts diagnose and rectify hearing loss, infections, and tinnitus using the most advanced technology, providing customized treatment for optimal ear care in Chennai's leading ENT hospital.",
      },
      {
        label: "2. Sinus & Nasal Allergy Treatment",
        contentt:
          "We provide comprehensive treatment for sinusitis, nasal blockage, and allergies using accurate diagnostics and tailored treatments to allow you to breathe comfortably and feel better at Chennai's leading ENT hospital.",
      },
      {
        label: "3. Voice & Throat Disorder Treatments",
        contentt:
          "Our Chennai ENT experts deliver personalized treatment for voice disorders, throat infections, and swallowing disorders. With advanced diagnostics and individualized treatments, we restore your comfort.",
      },
      {
        label: "4. Pediatric ENT Treatment & Care",
        contentt:
          "We offer specialized ENT care for children with ear infections, breathing issues, and tonsillitis with skilled pediatric care at the most trusted ENT hospital in Chennai.",
      },
      {
        label: "5. Sleep Apnea & Snoring Solutions",
        contentt:
          "Gain experience in professional sleep apnea, snoring, and breathing disorder treatment with personalized therapy from the top ENT specialists to sleep better and live better.",
      },
      {
        label: "6. Endoscopic & Minimally Invasive ENT Surgery",
        contentt:
          "Our ENT surgeons undertake sophisticated endoscopic nasal polyp treatment, tonsillitis, and pharyngitis treatment to achieve safe, effective, accurate, and proper treatment in Chennai.",
      },
      {
        label: "7. Treatment for Balance & Vertigo Disorder",
        contentt:
          "We provide expert diagnosis and treatment of vertigo, dizziness, and balance disorders with newer methods in expert hands at the finest ENT hospital in Chennai.",
      },
      {
        label: "8. Head & Neck Tumor Management",
        contentt:
          "Our ENT specialists diagnose and treat benign and malignant with the most advanced technology, offering safe and effective treatment at one of the best ENT hospitals in Chennai.",
      },

      {
        faqheading: "FAQs Section",
        labelfaq: "Q1: Why Choose Amrit Hospital for ENT Care?",
        contenttfaq:
          "Amrit Hospital is one of the best ENT hospitals in Chennai with the best hearing, nose, throat, head, and neck disorder experts. Our experienced ENT specialists utilize advanced diagnostic technology and minimally invasive surgical techniques to diagnose and treat most disorders. We offer individualized treatment planning, state-of-the-art surgery, and complete post-op care to facilitate and provide pain-free recovery.",
      },
      {
        labelfaq: "Q2: What are the most common conditions treated by Amrit Hospital's ENT Department?",
        contenttfaq:
          "Our ENT specialists in Chennai treat and diagnose throat disease, voice disorder, sinusitis, hearing loss, nasal polyps, dizziness, and sleep apnea. We also treat tonsillitis, thyroid disorders, tinnitus, and allergic disorders. We offer the latest, minimally invasive therapies, with an emphasis on quicker recovery and long-term results.",
      },
      {
        labelfaq: "Q3: What Advanced Treatments Are Available for Sinus and Nasal Problems?",
        contenttfaq:
          "Being one of the best ENT hospitals in Chennai, we treat nasal and sinus, including balloon sinuplasty, functional endoscopic sinus surgery (FESS), septoplasty, and allergy control. Our advanced treatments provide lasting relief for sinusitis, nasal congestion, and breathing disorders and guarantee less-invasive treatment procedures to heal sooner and have healthier noses.",
      },
      {
        labelfaq: "Q4: How are Ear Infections and Hearing Disorders Treated?",
        contenttfaq:
          "We have special treatment in our hospital for chronic ear infections, tinnitus, hearing loss, and balance disorders. We apply audiology tests, hearing aids, cochlear implants, and advanced ear surgeries to heal hearing and avoid future issues. Our ENT specialists based in Chennai follow an integrated method of treating ear health to recover clear hearing and improved balance in patients",
      },
      {
        labelfaq: "Q5: What Are the Best Treatment Options for Throat Disorders?",
        contenttfaq:
          "Our ENT doctors in Chennai offer sophisticated treatment for throat conditions, such as tonsillitis, acid reflux, voice disorders, and swallowing disorders. We offer laser treatment, voice treatment, and minimal interventions for quick relief and enhanced vocal well-being. Our tailored treatment ensures patients receive the best treatment for sustainable throat and voice functioning improvement.",
      },
      {
        labelfaq: "Q6: Do You Treat Sleep Apnea and Snoring?",
        contenttfaq:
          "Yes, we offer complete treatment of snoring and sleep apnea in the form of sleep studies, CPAP treatment, repair of the nasal airway, and Uvulopalatopharyngoplasty (UPPP). Our specialists make all efforts to improve breathing, reduce snoring, and improve the quality of sleep. We are considered one of the top ENT hospitals in Chennai and provide personalized solutions for long-term relief from sleep disorders.",
      },
      {
        labelfaq: "Q7: What Head and Neck Surgeries are done at Amrit Hospital?",
        contenttfaq:
          "Advanced head and neck surgeries like thyroidectomy, salivary gland surgery, excision of tumor, and reconstructive surgery are provided in our hospital. Our experienced ENT specialists in Chennai perform minimally invasive surgeries for safer treatment, quicker healing, and less scarring. We provide accurate pre-surgery assessment, actual treatment, and post-surgery rehabilitation for long-term recovery.",
      },
      {
        labelfaq: "Q8: How to Book a Consultation with an ENT Specialist?",
        contenttfaq:
          "It is easy to schedule an appointment with one of the top ENT hospitals in Chennai. Call our helpline, book online, or visit our hospital. Our skilled ENT specialists provide thorough consultations, state-of-the-art diagnostic workups, and personalized treatment plans to ensure the best care for you.",
      },

    ],
  },
  {
    id: "cardiology",
    label: "Department of || Cardiology",
    banner: cardio1,
    img: cardio,
    data: [
      {
        header: "Comprehensive Cardiac Care",
        content:
          "The Department of Cardiology at Amrit Hospital is dedicated to providing advanced care for a wide range of heart conditions. Our team of experienced cardiologists uses the latest diagnostic and therapeutic technologies to treat conditions such as coronary artery disease, heart failure, arrhythmias, and hypertension. We focus on delivering personalized care, with an emphasis on preventive cardiology to reduce the risk of heart disease. Our services include non-invasive diagnostics, interventional cardiology, and cardiac rehabilitation programs designed to improve heart health and overall well-being.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "Recognizing early signs of heart problems can lead to timely intervention and better outcomes.",
        points: [
          "Chest pain or discomfort.",
          "Shortness of breath, especially during physical activity.",
          "Irregular heartbeat or palpitations.",
          "Swelling in the legs, ankles, or feet.",
          "Unexplained fatigue or weakness.",
          "Dizziness or lightheadedness.",
        ],
        summary:
          "If you experience any of these symptoms, it's essential to seek medical attention immediately to prevent serious complications.",
      },
    ],
  },
  {
    id: "nephrology",
    label: "Chennai’s Best Nephrology Hospital for Expert Care || Compassionate & Specialized Kidney Care Services",
    banner: nephrology1,
    img: nephrology,
    data: [
      {
        header: "Care Beyond Treatment, Trust Beyond Words",
        subheader: "Chennai’s Best Nephrologists for Kidney Health",
        content:
          "Are you looking for kidney treatment? Our best nephrology hospital in Chennai offers treatment with top nephrologists dedicated to your well-being. From diagnosis to transplant and dialysis, we have compassionate, state-of-the-art treatments rendered specifically for you. Trust our services for the best kidney health solutions and a brighter tomorrow.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "Early detection of kidney issues is crucial for effective management.",
        points: [
          "Persistent fatigue or weakness.",
          "Swelling in the legs, ankles, or feet.",
          "Decreased urine output or changes in urine color.",
          "Difficulty breathing or shortness of breath.",
          "Nausea or vomiting without a clear cause.",
          "High blood pressure that is difficult to control.",
        ],
        summary:
          "If you experience any of these symptoms, consult a nephrologist to evaluate your kidney health and discuss potential treatment options.",
      },
      {
        heading: "Best Nephrology Services in Chennai",
        label: "1. Extensive Kidney Disease Management",
        contentt:
          "We offer professional diagnosis and treatment of kidney disease to ensure high-quality care with tailored treatment plans at the top nephrology hospital in Chennai for long-term kidney health.",
      },
      {
        label: "2. Advanced Dialysis & Renal Care",
        contentt:
          "Our top nephrologists in Chennai offer dialysis treatment using advanced technology to cure chronic kidney disease safely, with enhanced results and healthier kidneys under expert supervision.",
      },
      {
        label: "3. Kidney Transplant & Post-Operative Care",
        contentt:
          "We provide stress-free kidney transplant procedures with pre-operative evaluation, professional surgery, and tailored post-operative treatment. Our nephrologists in Chennai provide safe recovery and long-term kidney health.",
      },
      {
        label: "4. Hypertension & Kidney Health Management",
        contentt:
          "Our patients benefit from the latest care for hypertensive kidney problems with the use of diet and lifestyle modifications, drugs, and new therapy for preserving renal function and inducing optimal long-term well-being.",
      },
      {
        label: "5. Pediatric Nephrology & Specialized Treatment",
        contentt:
          "We deliver specialized treatment for children affected with kidney disorders, early detection, customized treatments, and special therapies. Our Chennai nephrologists excel at focusing on long-term kidney care and maximum growth.",
      },
      {
        label: "6. Chronic Kidney Disease (CKD) Therapy",
        contentt:
          "Through early diagnosis and best treatments, we retard the development of CKD and maximize wellness. Individualized care in our best nephrology hospital in Chennai maximizes kidney function and health.",
      },
      {
        label: "7. Acute Kidney Injury (AKI) treatment",
        contentt:
          "We provide instant AKI treatments that reduce kidney damage and also restore the optimum functioning of kidneys. Our Chennai nephrologists adopt high-tech diagnosis equipment and treatment methods for maximum benefit. ",
      },
      {
        label: "8. Diabetic Kidney Disease Management",
        contentt:
          "We provide long-term diabetic kidney complications management in the best nephrology hospital in Chennai. Our personalized treatment protocols provide kidney protection, prevention of disease, and prolonged patient survival.",
      },

      {
        faqheading: "FAQs Section",
        labelfaq: "1. Why is Amrit Hospital the Best Nephrology Hospital in Chennai?",
        contenttfaq:
          "Amrit Hospital stands as the best nephrology hospital in Chennai due to our expert nephrology services, modern diagnostic facilities, and a team of committed nephrologists in Chennai. Advanced dialysis, kidney transplant surgery, pediatric nephrology, and chronic kidney disease care are offered by us with world-class treatment and a patient-centric approach.",
      },
      {
        labelfaq: "2. What Services Does Amrit Hospital Provide for Kidney Care?",
        contenttfaq:
          "Amrit Hospital offers full kidney care, such as diagnosis of kidney disease, advanced dialysis, kidney transplant, and postoperative care. We also have expertise in hypertension management, pediatric nephrology, treatment of CKD, management of AKI, and diabetic kidney disease management. Our skilled nephrologists in Chennai provide excellent care for long-term kidney health.",
      },
      {
        labelfaq: "3. How Does Amrit Hospital Handle Kidney Transplant Procedures?",
        contenttfaq:
          "Amrit Hospital provides convenient kidney transplant facilities like pre-op evaluation, expertise in surgical procedures, and customized post-surgical therapies. Our nephrologists in Chennai ensure safe and proper rehabilitation, promoting long-term kidney well-being through customized treatment protocols at the best nephrology hospital in Chennai.",
      },
      {
        labelfaq: "4. Why Is Amrit Hospital's Dialysis Service Unique?",
        contenttfaq:
          "Our dialysis facility is well-furnished with updated technology to treat chronic kidney disease patients safely and effectively. We provide hemodialysis and peritoneal dialysis with the help of the best nephrologists in Chennai. We also stress patient safety, comfort, and improved outcomes.",
      },
      {
        labelfaq: "5. Does Amrit Hospital offer Pediatric Nephrology Services?",
        contenttfaq:
          "Yes, Amrit Hospital also includes a specialized unit of pediatric nephrology to detect and treat kidney disease early in children. Our nephrologists at Chennai provide personalized treatment for renal disorders in pediatric patients to have long-term stability of the kidney and maximum growth.",
      },
      {
        labelfaq: "6. How Does Amrit Hospital Treat Hypertension-Induced Kidney Issues?",
        contenttfaq:
          "We offer specialized treatment for kidney complications of hypertension by combining lifestyle changes, medications, and innovative therapy. Being the best nephrology hospital in Chennai, we provide successful control of blood pressure to avoid damage to the kidneys and overall well-being.",
      },
      {
        labelfaq: "7. How is Chronic Kidney Disease (CKD) treated at Amrit Hospital?",
        contenttfaq:
          "Our Chennai nephrologists focus on early detection and tailored treatment to decelerate CKD progression and improve quality of life. We develop personalized care plans with lifestyle modification, medication, diet, and novel therapies to help preserve kidney function. As the best nephrology hospital in Chennai, we offer every patient full, long-term care for better kidney function and overall well-being.",
      },
      {
        labelfaq: "8. How Can I Schedule an Appointment with a Nephrologist at Amrit Hospital?",
        contenttfaq:
          "It is easy to book an appointment with our best nephrologists in Chennai. You can visit our website, give us a call on our toll-free number, or just come to our Chennai Nephrology Hospital and book your appointment. We will assist you with the procedure so that you get treated within time and in a professional way.",
      },
    ],
  },
  {
    id: "physiotherapy",
    label: "Department of || Physiotherapy",
    banner: psiyo1,
    img: psiyo,
    data: [
      {
        header: "Restoring Movement and Function",
        content:
          "The Department of Physiotherapy at Amrit Hospital is committed to helping patients regain mobility, strength, and independence following injury, surgery, or illness. Our team of experienced physiotherapists works closely with patients to create individualized treatment plans that include exercises, manual therapy, and other modalities to promote recovery. Whether you are recovering from a sports injury, orthopedic surgery, or neurological condition, our physiotherapy services are designed to improve your quality of life and restore your physical function.",
        points: null,
      },
      {
        header: "Conditions Treated",
        content:
          "Physiotherapy can be beneficial for a wide range of conditions.",
        points: [
          "Musculoskeletal injuries such as sprains, strains, and fractures.",
          "Post-operative rehabilitation following joint replacement or spinal surgery.",
          "Neurological conditions such as stroke, multiple sclerosis, and Parkinson's disease.",
          "Chronic pain management for conditions like arthritis and fibromyalgia.",
          "Cardiac and pulmonary rehabilitation after heart attack or lung surgery.",
          "Sports injuries including ligament tears and muscle strains.",
        ],
        summary:
          "Our physiotherapy team is dedicated to helping you achieve your rehabilitation goals and improve your overall health and well-being.",
      },
    ],
  },
  {
    id: "gastroenterology",
    label: "Your Trusted Gastroenterology Hospital in Chennai || Comprehensive Care for All Digestive Disorders",
    banner: gastro1,
    img: gastro,
    data: [
      {
        header: "Advanced Treatments, Trusted Hands, Better Health",
        subheader: "Best Gastroenterologist in Chennai Awaits You",
        content:
          "Do you have digestive issues? The best gastroenterology hospital in Chennai provides advanced treatment, with leading gastroenterologists providing the latest treatments. From diagnosis to recovery, we offer customized solutions for permanent relief. Opt for your gut health today with specialist treatment and state-of-the-art medical equipment for a healthy life.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "Pay attention to these symptoms as they may indicate a gastrointestinal issue.",
        points: [
          "Persistent abdominal pain or discomfort.",
          "Chronic heartburn or acid reflux.",
          "Unexplained weight loss.",
          "Blood in the stool or black, tarry stools.",
          "Chronic diarrhea or constipation.",
          "Nausea or vomiting that persists without explanation.",
        ],
        summary:
          "If you experience any of these symptoms, it's important to consult a gastroenterologist to identify the underlying cause and receive appropriate treatment.",
      },
      {
        heading: "Best Gastroenterologist Services in Chennai",
        label: "1. Advanced Endoscopy & Colonoscopy",
        contentt:
          "Accurate diagnosis and treatment by expert gastroenterologists with endoscopy and colonoscopy for gastrointestinal problems at the leading gastro hospital in Chennai for guaranteed results and complete care.",
      },
      {
        label: "2. Liver Disease Management",
        contentt:
          "Get expert care for liver diseases, including fatty liver, hepatitis, and cirrhosis, with advanced diagnostics and personalized treatment plans at the top gastroenterology hospital in Chennai for better liver health.",
      },
      {
        label: "3. GERD & Acid Reflux Treatment",
        contentt:
          "Say goodbye to acid reflux, GERD, and chronic heartburn with cutting-edge treatments by experienced best gastroenterologists in Chennai for complete digestive health and long-term relief.",
      },
      {
        label: "4. Irritable Bowel Syndrome (IBS) Treatment",
        contentt:
          "Our experts provide cutting-edge treatment for IBS, bloating, and diarrhoea, ensuring customized treatment for better digestive health and symptomatic relief..",
      },
      {
        label: "5. Gastrointestinal Surgery & Treatment",
        contentt:
          "Avail best-in-class surgical care for gallbladder surgery, hernia, and colorectal diseases from Chennai’s best gastroenterologists to recover better.",
      },
      {
        label: "6. Pancreatic & Biliary Disorders",
        contentt:
          "Board-certified specialist gastroenterologists provide professional care for pancreatitis, gallstone disease, and bile duct disease in the top gastro hospital in Chennai to introduce enhanced care towards enhanced digestion and recovery.",
      },
      {
        label: "7. Screening and Digestive Cancer Treatment",
        contentt:
          "Early diagnosis of colon, stomach, and esophageal cancer by advanced screening and individualized treatment protocols assures quality care. Our professionals offer complete cancer treatment for better results and recovery.",
      },
     

      {
        faqheading: "FAQs Section",
        labelfaq: "1. Why Amrit Hospital is the Best Gastroenterology Hospital in Chennai?",
        contenttfaq:
          "At Amrit Hospital, we have expertise in treating acid reflux, pancreatitis, gallstones, and cancer of the gastrointestinal tract. Our gastro specialists in Chennai utilize the newest endoscopic techniques, laparoscopic surgery, and advanced liver treatments for precise care. We focus on providing a holistic, patient-centered experience, ensuring effortless recovery, long-term wellness, and the highest quality standard of digestive health care.",
      },
      {
        labelfaq: "2. What Conditions Do Gastroenterologists at Amrit Hospital Treat?",
        contenttfaq:
          "Our skilled gastroenterologists in Chennai are experts in the treatment of IBS, GERD, fatty liver, ulcers, Crohn's disease, hepatitis, and colon cancer. We provide cutting-edge diagnostic screenings, endoscopic assessments, and personalized treatment plans to effectively treat chronic digestive disorders. As one of the premier gastro hospitals, we provide advanced care for optimal digestion and long-term well-being.",
      },
      {
        labelfaq: "3. What Gastroenterology Treatments Are Available at Amrit Hospital?",
        contenttfaq:
          "As the best gastro hospital in Chennai, we provide start-to-finish procedures like endoscopy, colonoscopy, laparoscopic surgery, liver disease, surgical removal of the gall bladder, hernia repair, and cancer treatment of the gastrointestinal system. Our highly qualified experts, aided by the latest medical equipment, will provide you with the correct diagnosis and successful treatment.",
      },
      {
        labelfaq: "4. Do You Provide Minimally Invasive Gastrointestinal Surgeries?",
        contenttfaq:
          "Yes, our gastroenterologists are trained in minimally invasive surgery, including laparoscopic herniotomy, cholecystectomy, and endoscopic polyp excision. These advanced procedures ensure quicker recovery, minimal or no pain, and shorter hospital stays. As one of the best gastroenterology hospitals in Chennai, we place a high value on patient safety and the latest surgical techniques for the best care and improved outcomes.",
      },
      {
        labelfaq: "5. What Does Amrit Hospital Do in the Management of Liver Diseases?",
        contenttfaq:
          "Our specialist doctors in Chennai offer innovative treatment for liver infection, fatty liver, hepatitis, and cirrhosis. With the latest diagnostics, customized treatment guidelines, and health advice, we ensure optimal care for liver illnesses. Our patient-centered care strives to deliver the best outcomes and improve overall liver health and wellness.",
      },
      {
        labelfaq: "6. When Should You Visit a Gastroenterologist at Amrit Hospital?",
        contenttfaq:
          "If you have chronic acid reflux, bloating, stomach pain, dysphagia, jaundice, or unintended weight loss, see a gastroenterologist in Chennai immediately. Our experts offer precise diagnoses and cutting-edge treatments to avoid complications. With individualized care and professional medical solutions, we restore optimal digestive health for an improved quality of life.",
      },
      {
        labelfaq: "7. What Diagnostic Tests Are Done at Amrit Hospital?",
        contenttfaq:
          "We provide the latest diagnostic care, including upper GI endoscopy, colonoscopy, liver function tests, ultrasound, CT scans, and stool examination to diagnose gastric issues, ulcers, polyps, tumors, and liver diseases. Our Chennai gastroenterologists are experts and will conduct a precise diagnosis to help in creating a personalized treatment plan for the proper management of digestive health.",
      },
      {
        labelfaq: "8. How Do I Schedule a Consultation with a Gastroenterologist at Amrit Hospital?",
        contenttfaq:
          "Scheduling an appointment with the best gastroenterologist in Chennai at Amrit Hospital is easy and prompt. You can book it through our website by calling our helpline number or by visiting our hospital in person. Our experts provide professional treatment and diagnosis of GERD, IBS, liver disease, and other forms of digestion problems for quality treatment.",
      },
    ],
  },
  {
    id: "neurology",
    label: "Department of || Neurology",
    banner: nerurology1,
    img: nerurology,
    data: [
      {
        header: "Expert Neurological Care",
        content:
          "The Department of Neurology at Amrit Hospital is dedicated to the diagnosis and treatment of a wide range of neurological disorders. Our team of neurologists is experienced in managing conditions such as stroke, epilepsy, multiple sclerosis, Parkinson's disease, and migraines. We use advanced diagnostic tools and treatment options to provide comprehensive care for patients with complex neurological conditions. Our goal is to improve the quality of life for our patients by offering personalized treatment plans that address their specific needs.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content:
          "Neurological symptoms should not be ignored, as they may indicate serious underlying conditions.",
        points: [
          "Frequent headaches or migraines.",
          "Sudden onset of weakness or numbness, especially on one side of the body.",
          "Difficulty speaking or understanding speech.",
          "Loss of coordination or balance.",
          "Seizures or convulsions.",
          "Memory loss or confusion.",
        ],
        summary:
          "If you experience any of these symptoms, it's crucial to seek immediate medical attention to ensure timely diagnosis and treatment.",
      },
    ],
  },
  {
    id: "radiology",
    label: "Department of || Radiology",
    banner: radio1,
    img: radio,
    data: [
      {
        header: "Advanced Imaging Services",
        content:
          "The Department of Radiology at Amrit Hospital provides a comprehensive range of imaging services to support accurate diagnosis and treatment planning. Our team of skilled radiologists uses state-of-the-art equipment, including MRI, CT scans, X-rays, and ultrasound, to deliver high-quality imaging results. We offer a wide array of diagnostic and interventional radiology services to detect and monitor various medical conditions. Our focus is on providing precise, timely imaging that contributes to effective patient care across all departments.",
        points: null,
      },
      {
        header: "Common Imaging Procedures",
        content:
          "Radiology encompasses a variety of imaging techniques used to diagnose and monitor medical conditions.",
        points: [
          "MRI (Magnetic Resonance Imaging) for detailed images of soft tissues and organs.",
          "CT (Computed Tomography) scans for cross-sectional images of bones, blood vessels, and soft tissues.",
          "X-rays for diagnosing fractures, infections, and lung conditions.",
          "Ultrasound for imaging soft tissues, blood flow, and monitoring pregnancy.",
          "Mammography for breast cancer screening and diagnosis.",
          "Interventional radiology procedures such as biopsies and angiography.",
        ],
        summary:
          "Our radiology department is committed to delivering accurate and detailed imaging results to assist in the diagnosis and treatment of various medical conditions.",
      },
    ],
  },
  {
    id: "criticalCare",
    label: "Department of || Critical Care",
    banner: cc1,
    img: cc,
    data: [
      {
        header: "Intensive and Critical Care",
        content:
          "The Department of Critical Care at Amrit Hospital provides specialized care for patients with severe, life-threatening conditions that require constant monitoring and intensive treatment. Our team of highly trained intensivists, nurses, and support staff are equipped to handle complex medical emergencies, ensuring comprehensive and compassionate care in a high-tech environment. We focus on stabilizing critical patients and managing complex health issues with precision and expertise. Our critical care unit is equipped with advanced technology to monitor and treat a range of serious conditions, including respiratory failure, cardiac emergencies, and sepsis. We provide 24/7 care for patients in need of intensive medical intervention, including those recovering from major surgeries or severe trauma. Our multidisciplinary approach ensures that each patient receives tailored treatment plans and continuous support to achieve the best possible outcomes.",
        points: null,
      },
      {
        header: "Symptoms to Look Out For",
        content: "Seek immediate critical care if you experience:",
        points: [
          "Severe shortness of breath or difficulty breathing.",
          "Chest pain or severe discomfort.",
          "Sudden loss of consciousness or confusion.",
          "Persistent high fever with severe symptoms.",
          "Rapid or irregular heartbeat.",
          "Severe trauma or injury.",
        ],
        summary:
          "In case of these urgent symptoms, our critical care team is ready to provide advanced interventions and support to stabilize and treat critical conditions effectively.",
      },
    ],
  },
];
export const listheader = "Our Services";

export const general = { list, internalData, listheader };
