import b1 from "assets/images/blog/1.jpg";
import b2 from "assets/images/blog/2.jpg";
import b3 from "assets/images/blog/3.jpg";
import b4 from "assets/images/blog/4.jpg";

import b5 from "assets/images/blog/5.jpg";
import b6 from "assets/images/blog/6.jpg";
import b7 from "assets/images/blog/7.jpg";
import b8 from "assets/images/blog/8.jpg";

import b9 from "assets/images/blog/9.jpg";
import b10 from "assets/images/blog/10.jpg";
import b11 from "assets/images/blog/11.jpg";
import b12 from "assets/images/blog/12.jpg";
import b13 from "assets/images/blog/13.jpg";
import b14 from "assets/images/blog/14.jpg";
import b15 from "assets/images/blog/15.jpg";
import b16 from "assets/images/blog/16.jpg";
import b17 from "assets/images/blog/17.jpg";
import b18 from "assets/images/blog/18.jpg";
import b19 from "assets/images/blog/19.jpg";
import b20 from "assets/images/blog/20.jpg";
import b21 from "assets/images/blog/21.jpg";
import b22 from "assets/images/blog/22.jpg";
import b23 from "assets/images/blog/23.jpg";
import b24 from "assets/images/blog/24.jpg";

export const blogs = [
  {
    id: "1",
    img: b1,
    header: "Chennai’s Leading Endoscopic Thyroid Surgery at Amrit Hospital",
    data: [
      {
        subheader: "Introduction:",
        text: "Thyroid surgery has traditionally involved a visible neck incision, which can leave patients with noticeable scars and longer recovery times. However, Amrit Hospital in Chennai is changing the game by being the only hospital in the city to offer thyroid removal through endoscopy. This innovative, minimally invasive technique represents a significant advancement in thyroid care, combining medical precision with patient comfort.",
      },
      {
        subheader: "A Revolutionary Technique:",
        text: "Endoscopic thyroid surgery, also known as scarless thyroidectomy, allows surgeons to remove the thyroid gland through small incisions, typically made in areas like the armpit or behind the ear. This means patients can avoid the visible neck scar that’s common with traditional thyroid surgery. At Amrit Hospital, this cutting-edge procedure is performed by a team of highly skilled surgeons who are experts in the latest surgical techniques.",
      },
      {
        subheader: "Benefits of Endoscopic Thyroid Surgery:",
        text: "The advantages of endoscopic thyroid surgery are numerous. First and foremost, it’s minimally invasive, which leads to a quicker recovery, less pain, and minimal scarring. Patients also experience fewer complications compared to traditional thyroidectomy. For many, the cosmetic benefit of not having a visible neck scar is a significant relief, especially for those concerned about their appearance post-surgery.",
      },
      {
        subheader: "Why Choose Amrit Hospital?",
        text: "As the only hospital in Chennai offering this advanced procedure, Amrit Hospital is at the forefront of thyroid care. The hospital’s commitment to using the latest medical technology and techniques ensures that patients receive the highest standard of care. Moreover, the multidisciplinary team at Amrit Hospital provides comprehensive support throughout the entire treatment process, from diagnosis to post-surgery recovery.",
      },
      {
        subheader: "Conclusion:",
        text: "If you or a loved one needs thyroid surgery and you're looking for the most advanced, minimally invasive option available, Amrit Hospital in Chennai should be your first choice. With their pioneering endoscopic thyroid surgery, you can achieve the best outcomes with the least impact on your daily life.",
      },
    ],
  },
  {
    id: "2",
    img: b2,
    header: "The Importance of Regular Eye Check-Ups",
    data: [
      {
        subheader: "Introduction:",
        text: "Regular eye check-ups are vital to maintaining clear vision and overall eye health. Unfortunately, many people overlook the importance of visiting an ophthalmologist until a problem arises. At Amrit Hospital in Chennai, the expert team in the Ophthalmology Department stresses the significance of routine eye examinations.",
      },
      {
        subheader: "Early Detection of Eye Conditions:",
        text: "Regular check-ups can detect early signs of eye conditions like glaucoma, cataracts, and macular degeneration—conditions that can lead to vision loss if not addressed promptly. The advanced diagnostic tools and experienced ophthalmologists at Amrit Hospital ensure that even the smallest issues are identified early, allowing for timely intervention.",
      },
      {
        subheader: "Protecting Your Vision in a Digital Age:",
        text: "With the increasing screen time in our daily lives, the risk of eye strain and other related issues is higher than ever. By scheduling regular eye check-ups at Amrit Hospital, you can protect your vision and ensure that your eyes are healthy for years to come. Don’t wait until it’s too late—prioritize your eye health today!",
      },
    ],
  },
  {
    id: "3",
    img: b3,
    header: "Navigating Multi-Specialty Healthcare",
    data: [
      {
        subheader: "Introduction:",
        text: "When it comes to healthcare, convenience and comprehensive care are crucial. Amrit Hospital in Chennai excels in providing multi-specialty healthcare, making it a one-stop solution for all your medical needs. With a range of specialties under one roof, patients can receive holistic care without the hassle of visiting multiple hospitals.",
      },
      {
        subheader: "Integrated Healthcare Approach:",
        text: "From cardiology to neurology, orthopedics to gastroenterology, and more, Amrit Hospital’s team of highly qualified specialists works together to provide integrated care tailored to each patient. This collaborative approach ensures that your treatment plan is well-coordinated, efficient, and effective.",
      },
      {
        subheader: "Patient-Centered Care:",
        text: "What sets Amrit Hospital apart is its commitment to patient-centered care. The hospital prioritizes your health and comfort, offering state-of-the-art facilities and a compassionate medical team. Whether you're seeking routine care or specialized treatment, you can trust Amrit Hospital to deliver expert care with a personal touch.",
      },
      {
        subheader: "Conclusion:",
        text: "In a city as busy as Chennai, having a reliable multi-specialty hospital like Amrit Hospital is invaluable. Experience seamless healthcare that meets all your needs under one roof.",
      },
    ],
  },
  {
    id: "4",
    img: b4,
    header: "Expert Cataract Surgery at Amrit Hospital",
    data: [
      {
        subheader: "Introduction:",
        text: "Cataracts are one of the leading causes of vision impairment, particularly in older adults. If left untreated, cataracts can lead to significant vision loss, severely affecting your quality of life. Fortunately, cataract surgery is a highly effective treatment that can restore your vision and improve your overall well-being. At Amrit Hospital in Chennai, we specialize in providing expert cataract surgery, utilizing the latest technology to ensure the best possible outcomes for our patients.",
      },
      {
        subheader: "Why Cataract Surgery Is Essential:",
        text: "Cataracts occur when the eye’s natural lens becomes cloudy, leading to blurred vision, difficulty with glare, and eventually, significant vision impairment. While glasses or stronger lighting can help manage early symptoms, surgery is the only way to effectively treat cataracts. During the procedure, the clouded lens is removed and replaced with an artificial intraocular lens (IOL), restoring clear vision.",
        text2:
          "Delaying cataract surgery can have serious consequences. As cataracts progress, they can lead to complete blindness, making everyday tasks like reading, driving, or even recognizing faces increasingly difficult. Additionally, untreated cataracts can increase the risk of falls and accidents, particularly in older adults. Therefore, timely cataract surgery is essential to maintaining your independence and quality of life.",
      },
      {
        subheader: "Why Choose Amrit Hospital for Cataract Surgery:",
        text: "Amrit Hospital in Chennai is a leader in ophthalmic care, with a dedicated team of experienced eye surgeons who are experts in cataract surgery. We use state-of-the-art technology, including advanced diagnostic tools and the latest surgical techniques, to ensure precise and successful outcomes. Our surgeons are skilled in performing both traditional and laser-assisted cataract surgeries, tailoring the procedure to meet your specific needs.",
        text2:
          "At Amrit Hospital, patient care is our top priority. From your initial consultation to post-operative follow-up, our team provides comprehensive support to ensure a smooth and comfortable experience. We understand that undergoing surgery can be daunting, which is why we are committed to offering personalized care, addressing all your concerns, and providing clear information every step of the way.",
      },
      {
        subheader: "Affordable, Quality Care:",
        text: "We believe that quality healthcare should be accessible to everyone, which is why Amrit Hospital offers affordable cataract surgery without compromising on the quality of care. Our transparent pricing ensures that you know exactly what to expect, with no hidden costs.",
      },
      {
        subheader: "Conclusion:",
        text: "Cataract surgery is not just about restoring your vision—it’s about enhancing your quality of life. At Amrit Hospital in Chennai, we are dedicated to providing top-notch cataract surgery with the highest standard of care. Don’t let cataracts diminish your vision or your life. Schedule a consultation with us today, and take the first step toward seeing clearly again.",
      },
    ],
  },
  {
    id: "5",
    img: b5,
    header: "5 Cataract Surgery Side Effects & How to Recover",
    data: [
      {
        subheader: "Introduction:",
        text: "Cataract surgery is one of the most successful medical procedures, with very high success rates and rapid recovery for most patients. But it’s not necessarily risk-free, like any surgical procedure. Preparation for cataract surgery side effects can help you both understand what to expect in the days following surgery and when to call if you experience something that's out of the ordinary. Read on to learn more about the most common side effects, potential complications, and the practical steps to achieve a quick, safe healing.",
      },
      {
        subheader: "Blurry Vision",
        text: "One of the most common problems after cataract surgery is blurry vision, which many patients experience during the initial stages of recovery. This is generally a short-term issue that occurs as your eye adjusts to the new intraocular lens (IOL) and the healing process progresses. Blurry vision is your eye's way of adapting to the changes following the procedure, and it typically improves over time.",
        text2:
          "Recovery Tip: To support your recovery, it is advisable to rest your eyes during the first few days after surgery. Avoid looking at screens or engaging in activities like reading for extended periods, as these can put additional strain on your eyes. Prioritize rest and give your body the time it needs to heal properly.",
      },
      {
        subheader: "Dry Eyes",
        text: "Experiencing dry eyes is a common complaint among patients who have undergone cataract surgery. This side effect is primarily due to the temporary disruption of the tear film on the eye's surface caused by the procedure. While this can feel uncomfortable, it is a normal response during the healing process and will typically improve with proper care.",
        text2:
          "Recovery Tip: To alleviate dryness and promote healing, consider using lubricating eye drops as recommended by your ophthalmologist. These drops will provide much-needed hydration to your eyes, helping reduce discomfort and support your body’s natural healing process.",
      },
      {
        subheader: "Light Sensitivity",
        text: "Another frequent side effect of cataract surgery is light sensitivity, which many people experience during the first few weeks following their operation. This happens because the healing process and adjustment to the new lens can make your eyes more sensitive to bright light, particularly sunlight or artificial lighting. This can cause discomfort, especially outdoors or in brightly lit areas.",
        text2:
          "Recovery Tip: To minimize discomfort caused by light sensitivity, wear sunglasses with UV protection when outdoors. Not only will they protect your healing eyes from harsh sunlight, but they will also reduce the likelihood of irritation or discomfort while you recover.",
      },
      {
        subheader: "Glare or Halos Around Lights",
        text: "After cataract surgery, it is common to notice halos or glare around lights, especially at night. This can make driving or being in poorly lit conditions challenging, but it is important to note that this is a temporary side effect of cataract surgery. These visual disturbances are your eyes adjusting to the new IOL and will usually resolve on their own after a few days of healing.",
        text2:
          "Recovery Tip: To stay safe, avoid driving at night until the halos or glare subside. Allow your eyes the time they need to heal and adapt, and contact your doctor if you have concerns about prolonged symptoms.",
      },
      {
        subheader: "Eye Redness",
        text: "Eye redness is a natural occurrence following cataract surgery and should not typically cause concern. This is due to the temporary inflammation of the blood vessels in the eye that happens as a normal part of the healing process. While it can look alarming, this redness will subside as your eye continues to heal.",
        text2:
          "Recovery Tip: To reduce inflammation and support the healing process, use the prescribed anti-inflammatory eye drops as directed by your doctor. These drops are essential for easing inflammation, promoting faster recovery, and ensuring a smoother healing process. Additionally, allow your eyes time to rest and heal naturally by avoiding unnecessary strain.",
      },
      {
        subheader: "Conclusion",
        text: "Knowing what you may experience with cataract surgery side effects will help you to be able to recover from your surgery as effectively as possible. Most side effects are mild and temporary but knowing what to expect and how to deal with them will help to pave a smooth healing course. These side effects that so often go along with it are usually something you can manage as long as you take proper care of yourself and ask for medical guidance. ",
        text2:
          "Remember, cataract surgery is a very successful operation resulting in a dramatic increase in quality of life. If you stay in the loop about possible post-cataract surgery complications and follow your doctor’s advice, you’ll get the greatest rewards from clearer, sharper vision.",
        text3:
          "Take the first step towards clear vision with expert cataract treatment at Amrit Hospitals. Book your consultation and discover personalized care tailored to your needs!",
      },
    ],
  },
  {
    id: "6",
    img: b6,
    header: "5 Best Foods for Eye Health",
    data: [
      {
        subheader: "Introduction:",
        text: "A healthy, balanced diet is required at all times, not just to improve your health but also to improve the health of your eyes and prevent a host of issues. Including the best food for eyes in your diet can go a long way in maintaining good vision and they can also act as preventive measures against eye problems as you age. The following article provides information on friendly-eye foods and defines how to improve your eyesight by consuming the right foods.",
      },
      {
        subheader: "Carrots",
        text: "Carrots are often associated with good vision, and for good reason—they are rich in nutrients that support eye health. Carrots contain a good portion of beta-carotene (a type of vitamin A); they help in the healthy formation of the cornea and improve eye vision. Beta-carotene also helps to minimise the likelihood of macular degeneration and cataracts and so carrots are excellent for your eyes.",
        text2:
          "Recovery Tip: You can add carrots to your diet as snacks, blend them into juices, or incorporate them into meals to reap their full benefits.",
      },
      {
        subheader: "Leafy Green Vegetables",
        text: "To naturally support eye health, including leafy greens like spinach, Swiss chard, and kale in your diet, as they are rich in lutein and zeaxanthin, two powerful antioxidants beneficial for vision. These nutrients help filter harmful blue light and protect your retina, reducing the risk of macular degeneration and cataracts. And leafy greens are packed with vitamin C. Vit C is best to support the veins in your eyes.",
        text2:
          "Recovery Tip: Enjoy leafy greens in salads, smoothies, or soups to make them a regular part of your diet.",
      },
      {
        subheader: "Fish Rich in Omega-3 Fatty Acids",
        text: "Fish like salmon, tuna, and sardines are excellent sources of omega-3 fatty acids, which are crucial for maintaining the health of the retina and preventing dry eye syndrome. Omega-3s also play a role in reducing inflammation, supporting tear production, and maintaining the structural integrity of the eyes. Foods to improve eyesight without glasses often include oily fish, as they provide essential nutrients for overall eye health and protection against age-related vision loss.",
        text2:
          "Recovery Tip: Try including fatty fish in your meals (2 times in 7 days). If you do not like fish that much, then you can buy omega-3 supplements.",
      },
      {
        subheader: "Sunflower Seeds and Nuts",
        text: "Sunflower seeds, almonds, and walnuts are excellent for eye health due to their high content of vitamin E. This powerful antioxidant helps protect the eyes from free radicals, which can cause cell damage and lead to conditions like cataracts or age-related macular degeneration.",
        text2:
          "Recovery Tip: Snack on sunflower seeds or mix them with nuts for a nutrient-packed treat that benefits your vision.",
      },
      {
        subheader: "Citrus Fruits and Berries",
        text: "Citrus fruits such as oranges, grapes, and lemons come loaded with vitamin C, which is an antioxidant needed for a better eye state. This vitamin toughens the eye veins. Thus, it cuts the chances of cataracts. Berries, including strawberries and blueberries, are the best food for the eyes, as they provide additional antioxidants that combat oxidative stress in the eyes.",
        text2:
          "Recovery Tip: Start your day with citrus fruit or enjoy berries as a midday snack to boost your vitamin C intake and protect your eyes.",
      },
      {
        subheader: "How These Foods Help Naturally",
        text: "Improving eyesight naturally involves the synergy of nutrients present in specific foods. Vitamins C, A, & E work together with lutein, zeaxanthin, and omega-3 fatty acids. They help to nourish our eyes and protect them from damage. Plus, these nutrients help cut the risk of macular degeneration, cataracts, & dry eye issues.",
      },
      {
        subheader: "Meal Ideas to Include the Best Foods for Eyes",
        text: "Breakfast: People can have a smoothie of spinach, oranges, and sunflower seeds.",
        text2:
          "Lunch: A salad with kale, carrots, grilled salmon, and a lemon will be great.",
        text3:
          "Dinner: One should try tuna (baked) with veggies & roasted almonds.",
        text4:
          "Snacks: Eat sunflower seeds, carrot sticks, and fresh berries. These are some of the best food for eyes.",
      },
      {
        subheader: "Conclusion",
        text: "Taking care of your eyes begins with your plate. Including foods rich in vitamins, antioxidants, and omega-3 fatty acids can significantly enhance your eye health and even help prevent age-related vision problems. Whether it’s carrots, fish, leafy greens, sunflower seeds, or citrus fruits, these nutrient-dense options are your allies in maintaining sharp, healthy vision for years to come.",
        text2:
          "Explore these options today and see the difference a good diet can make for your eyes. Remember, the best food for your eyes is not just about what you eat but how consistently you include these superfoods in your daily meals.",
        text3:
          "Enhance your eye health with expert care and guidance. Visit Amrit Hospitals today for personalized vision solutions!",
      },
    ],
  },
  {
    id: "7",
    img: b7,
    header:
      "Femto Laser Cataract Surgery: Revolutionizing Modern Cataract Treatment",
    data: [
      {
        subheader: "Introduction:",
        text: "Cataracts, one of the most common causes of vision loss worldwide, are no longer the challenge they once were. Due to advancements in ophthalmology, procedures like femto laser cataract surgery have redefined the approach to cataract treatment. This cutting-edge technology combines precision, safety, and better outcomes for patients, marking a new era in eye care.",
      },
      {
        subheader: "What is Femto Laser Cataract Surgery?",
        text: "Femto-laser cataract surgery, also known as femtosecond laser cataract surgery, uses laser technology to perform key steps in cataract removal with remarkable precision. Unlike traditional methods, which rely on manual instruments, femto cataract surgery leverages a computer-guided laser to automate critical stages like corneal incisions, capsulotomies, and lens fragmentation.",
        text2:
          "The laser enhances the procedure's accuracy, making it safer and more effective. It minimizes risks associated with manual handling and delivers consistent results, especially for patients opting for advanced intraocular lenses (IOLs).",
      },
      {
        subheader: "How Femto Laser Cataract Surgery Works",
        text: "The process of femto laser cataract surgery involves the following key steps:",
        text2:
          "Corneal Incisions: The surgeon uses the femto laser to create precise and small incisions in the cornea. This automated step reduces tissue damage and accelerates healing.",
        text3:
          "Capsulotomy: A laser creates a perfectly circular opening in the lens capsule, allowing for smooth cataract removal and precise IOL placement.",
        text4:
          "Lens Fragmentation: The laser divides the cataract into smaller, manageable pieces, reducing the need for ultrasonic energy during removal. This minimizes complications like corneal swelling.",
        text5:
          "By automating these steps, femto laser surgery ensures greater safety and precision, leading to improved patient outcomes.",
      },
      {
        subheader: "Benefits of Femto Laser Cataract Surgery",
        text: "Enhanced Precision: The use of laser technology in femto cataract surgery eliminates variability caused by manual instruments, ensuring a high level of accuracy.",
        text2:
          "Improved Safety: Femto laser surgery minimizes the risks of tissue damage and other complications. Its accuracy ensures that critical steps are executed with minimal error.",
        text3:
          "Better Visual Outcomes: Patients undergoing femto laser cataract surgery often report sharper vision, thanks to the precise placement of IOLs.",
        text4:
          "Reduced Recovery Time: The minimally invasive nature of the procedure promotes faster healing and less post-operative discomfort.",
        text5:
          "Less Use of Ultrasound Energy: Breaking down the cataract with a laser reduces the need for ultrasound, which lowers the risk of damage to surrounding eye tissues.",
      },
      {
        subheader:
          "How Femto Laser Cataract Surgery Differs From Traditional Surgery",
        text: "In traditional cataract surgery, the surgeon manually performs all steps, from making corneal incisions to breaking up the cataract. This approach relies heavily on the surgeon’s expertise and is prone to variability. Femto laser cataract surgery, by contrast, automates these tasks, delivering consistent and predictable results.",
        text2:
          "Additionally, femto laser reduces the need for ultrasound energy, a common source of post-surgical complications in traditional methods. This not only enhances patient comfort but also ensures quicker recovery.",
      },
      {
        subheader: "Who Should Consider Femto Laser Cataract Surgery?",
        text: "Femto cataract surgery is suitable for most individuals requiring cataract treatment, especially those who:",
        text2:
          "Desire premium IOLs, such as multifocal or toric lenses, where precise placement is critical.",
        text3:
          "Have complex cataracts that may benefit from enhanced surgical accuracy.",
        text4: "Seek a safer, less invasive procedure with quicker recovery.",
        text5:
          "Patients should consult their ophthalmologist to determine if femto laser cataract surgery is the right option for their specific needs.",
      },
      {
        subheader: "Limitations of Femto Laser Cataract Surgery",
        text: "While femto laser cataract surgery offers numerous advantages, it is essential to consider its limitations:",
        text2:
          "Higher Costs: The advanced technology involved makes femto laser surgery more expensive than traditional cataract surgery.",
        text3:
          "Limited Availability: Not all surgical centres are equipped with femto laser systems, which may limit accessibility.",
        text4:
          "Suitability: Some patients with specific eye conditions may not be ideal candidates for femtosecond laser cataract surgery. A thorough evaluation is necessary to determine eligibility",
      },
      {
        subheader: "Procedure Overview for Femto Laser Cataract Surgery",
        text: "Pre-Surgical Assessment: The patient undergoes a detailed eye examination to assess their suitability for femto laser cataract surgery. The surgeon evaluates factors like corneal health, cataract type, and visual needs.",
        text2:
          "Surgery Day: The procedure typically lasts 15–20 minutes. Local anesthesia is used to numb the eye, ensuring a pain-free experience. The surgeon uses the femto laser to perform the required steps before removing the cataract and implanting the IOL.",
        text3:
          "Post-Surgery Recovery: Most patients experience improved vision within days. Regular follow-up appointments are necessary to monitor healing and address any concerns.",
      },
      {
        subheader: "Conclusion",
        text: "Femto laser cataract surgery has truly transformed cataract treatment, offering precision, safety, and faster recovery for patients. If you're considering this advanced procedure or want to learn more, reach out to Amrit hospitals. Their team of experienced ophthalmologists and state-of-the-art technology ensure the best care for your vision.",
        text2:
          "Schedule your consultation today at Amrit Hospitals and take the first step toward a clearer, brighter vision!",
      },
    ],
  },
  {
    id: "8",
    img: b8,
    header: "Post Cataract Surgery Precautions & Recovery",
    data: [
      {
        subheader: "Introduction:",
        text: "Cataract is easily operated, and it is one of the most successful surgeries through which you can get your vision back. However, the recovery process is very effective across different countries and cultures, and it still should be done strictly following the medical practitioners’ advice. This article focuses on the crucial post-cataract surgery precautions that people should adhere to after their cataract surgery. ",
      },
      {
        subheader: "Immediate Post-Surgery Care",
        text: "The first 2 days of someone’s cataract surgery are very important for genuine healing. During this time, the eye is most vulnerable. Better follow these guidelines: Avoid touching or rubbing your eyes. Else, it can bring bacteria to the eye & irritate. Wear the protective eye shield or glasses your doctor provides, particularly while sleeping, to avoid accidental pressure or injury. Keep the operated eye clean, but avoid washing your face vigorously. Rather, you can use a moist cloth to wipe your face. Don’t touch the operated eye. Your doctor will prescribe eye drops used after cataract surgery. These will include antibiotics and anti-inflammatory drops.",
      },
      {
        subheader: "Do’s and Don’ts for a Smooth Recovery",
        text: "Postoperative care is about making momentary tunings to regular habits. Knowing the dos and don’ts after cataract surgery can help you dodge issues.",
        text2:
          "Do’s: One of the best post-cataract surgery precautions is to use sunglasses outdoors. Then he/she can protect their eyes from bright sunlight, dust particles, & wind. You must stick to your prescribed medication schedule. These drops are integral to preventing infections & promoting healing. You must sleep such that it avoids pressure on the operated eye.",
        text3:
          "Don’ts: Some post-cataract surgery precautions are: don’t do swimming, hot tubs, or any water-based activities (at least 14 days). The person can’t lift heavy objects or engage in strenuous activities. They must avoid places with excessive dust or smoke.",
      },
      {
        subheader: "Adjusting to Everyday Activities",
        text: "Changing your daily schedule during this time is chief. Many people worry about cooking & eating.",
        text2:
          "Light cooking is permissible. But you must not bend over the stove or work with super-hot oil. Think about taking help for heavy weight lifting or veggie chopping to lessen strain. A good diet plays a massive role in healing. Patients can have edibles rich in vitamins A & C. Avoid spicy or oily meals immediately post-surgery. Then you’ll be able to prevent bloating or discomfort.",
      },
      {
        subheader: "Using Medications Effectively",
        text: "The names of eye drops used after cataract surgery may vary depending on your surgeon’s preference, but they typically include antibiotic eye drops to prevent infections & steroid/non-steroidal anti-inflammatory medicines to lessen the pain of the operation. Always wash your hands before applying eye drops and follow the prescribed schedule for optimal results.",
      },
      {
        subheader: "Long-Term Precautions",
        text: "Even when the initial healing period is over, some protections must be followed to preserve the health of your eyeballs. Avoid direct exposure to bright sunlight by wearing UV-protective sunglasses. Refrain from vigorous activities for at least four weeks. Don’t skip your doctor appointments. This will help you check your healing process. By taking these precautions to take after cataract surgery, you can minimize complications and preserve your vision for years to come.",
      },
      {
        subheader: "Conclusion",
        text: "After cataract surgery, healing is not immediate, and you should pay much attention to what the doctor is advising you and follow any recommendations given to the letter on the diet, manner of living, and so on. Post-cataract surgery precautions that are recommended should be followed aggressively; adequate rest and eye protection, together with timing your medications appropriately, will help in avoiding complications during your healing process. ",
        text2:
          "When it comes to this period, it means modifying some daily routines or, taking the necessary measures, individuals can make a difference. Keep in mind that the vision is priceless and the money that will be spent to restore it will bring you a better and clearer tomorrow.",
      },
    ],
  },
  {
    id: "9",
    img: b9,
    header: "5 Important Stages of Child Growth and Development",
    data: [
      {
        subheader: "Introduction:",
        text: "Understanding the stages of growth and development is crucial for parents, educators, and healthcare professionals. Children's growth is a dynamic process influenced by genetics, environment, and nutrition. Proper knowledge of child growth and development helps children achieve their full potential. Recognising the specific developmental stages allows for timely interventions and the provision of appropriate support to promote healthy progress.",
        text2:
          "In this blog, we'll talk about the 5 stages of growth and development and their significance. Every stage provides specific physical, cognitive, and emotional developments, all of which will help determine the overall success and well-being of the children.",
      },
      {
        subheader: "Infancy (0-2 Years)",
        text: "The infancy stage marks the beginning of pediatric growth and development, characterized by rapid physical and cognitive changes. Babies grow at an astonishing rate, doubling their birth weight within the first six months. Key milestones during this stage include rapid increases in height and weight, developing control over head movements, rolling over, crawling, and eventually walking. Proper nutrition and responsive caregiving play a crucial role in supporting the infant's development.",
        text2:
          "Cognitive development includes recognizing familiar faces, responding to stimuli, and beginning to explore surroundings. Emotional development involves forming attachment bonds with parents and caregivers. The importance of growth and development during infancy lies in building a strong foundation for future learning and social interactions. Encouraging sensory experiences and consistent routines help infants thrive during this crucial stage.",
      },
      {
        subheader: "Early Childhood (2-6 Years)",
        text: "This period is critical to a child's growth and development stages. It is considered the preschool years when children start gaining independence and develop their motor skills. This is a time for rapid vocabulary increase and basic sentence formation, interactions with peers, learning to share, and beginning to understand another's feelings through empathy. A play-based approach to learning develops creativity and intellectual abilities.",
        text2:
          "Cognitive development involves knowledge of simple ideas like colours, shapes, and numbers. Fine and gross motor skills are achieved through climbing, running, drawing, and using utensils.",
        text3:
          "At this age, creativity and exploration should be encouraged for holistic development. A safe and stimulating environment provides better learning opportunities and social skills.",
      },
      {
        subheader: "Middle Childhood (6-12 Years)",
        text: "During middle childhood, children experience steady growth and develop a sense of identity. Learning to read and write and solving simple mathematical calculations are all about this stage. Socialization makes them understand other people's attitudes. They continue to develop the habit of being good at their studies and make time for outside activities that generally assist in their development.",
        text2:
          "Physical development sees improved coordination and participation in sports. Emotional growth includes developing self-esteem and coping mechanisms for challenges. This period emphasizes the importance of growth and development through a balanced routine of physical activities, academics, and social interactions. Encouraging problem-solving skills and resilience is vital for future success.",
      },
      {
        subheader: "Adolescence (12-18 Years)",
        text: "Adolescence is one of the most transformative child growth and development stages, with significant physical, emotional, and social changes. Key aspects of this stage include hormonal changes leading to physical maturity, enhanced critical thinking and decision-making abilities, managing mood swings, self-identity exploration, and peer pressure. Providing emotional support and open communication helps adolescents navigate challenges effectively.",
        text2:
          "Autonomy-seeking and building on future expectations characterize adolescents. This is when the parents or the educators will help the adolescent face these transitions by providing direction and support in leading a healthy life and keeping one's mind mentally healthy.",
      },
      {
        subheader: "Young Adulthood: 18-21 years",
        text: "Though not traditionally considered part of childhood, young adulthood is an extension of pediatric growth and development, focusing on independence and responsibility. Key developments include making educational and professional choices, understanding relationships and self-awareness, contributing to society, and building long-term relationships. Young adults need encouragement to set personal and professional goals.",
        text2:
          "The individual also has to learn to manage personal finances and personal responsibilities. The stage of transition to adulthood provides great chances for growth and development. Thus well-prepared to face life boldly. Healthy habits, lifelong learning, and securing the chances of success are indispensable in this period.",
      },
      {
        subheader: "Conclusion",
        text: "Understanding the 5 stages of growth and development allows parents and caregivers to provide appropriate support and interventions at each phase. Monitoring child growth and development ensures that children meet their milestones and develop into well-rounded individuals.",
        text2:
          "Encouraging a nurturing environment throughout all child growth and development stages can significantly impact a child’s future success and well-being. By recognizing the unique needs of each stage, caregivers can foster a positive and enriching developmental journey.",
        text3:
          "Get your child the best advice on growth and development at Amrit Hospital, where children can receive proper care under skilled pediatric specialists to maintain their health at every step of life. Choose our services to help your little ones grow up healthy under the guidance of medical professionals with a healthy future outlook.",
      },
    ],
  },
  {
    id: "10",
    img: b10,
    header: "10 Foods That Increase Fertility in Women",
    data: [
      {
        subheader: "Introduction:",
        text: "Food intake plays an important role in fertility boosters and chances of conception. Balanced and nutritious dieting helps control your hormonal health, supports ovulation, and improves the quality of eggs in the ovaries. If one wants to take the natural approach to fertility, one can start by focusing on foods that boost fertility. In this post, we’ll explore 10 foods that increase fertility in women.",
      },
      {
        subheader: "Leafy Greens: A powerhouse of nutrition",
        text: "Leafy greens such as spinach, kale, and Swiss chard are a good source of folate, a B vitamin essential for fertility. Food for ovulation must therefore include these nutrient-dense vegetables to help facilitate regular menstrual cycles and healthy egg development. Folate also helps in DNA synthesis, which is critical for the healthy development of the fetus once you conceive.",
      },
      {
        subheader: "Berries: Rich in Antioxidants",
        text: "Berries, for example, blueberries, strawberries, and raspberries, are filled with antioxidants. Oxidative stress has been proven to damage eggs and sperm, so berry intake is a great way toward better female egg quality. Eating foods that increase fertility in females, like berries, every day helps enhance the health of your reproductive system and thereby improves fertility in females.",
      },
      {
        subheader: "Eggs: Nature's Fertility Superfood",
        text: "Eggs are known to be good sources of proteins, healthy fats, and vitamin D, among other foods that are very fundamental for reproductive health. The presence of proteins can support the formation of hormones; vitamin D improves menstrual cycles and boosts ovulation.",
      },
      {
        subheader: "Nuts and Seeds: A Healthy Source of Fat",
        text: "Nuts and seeds, like almonds, walnuts, and flaxseeds, are excellent sources of omega-3 fatty acids, which have been proven to improve the quality of eggs and balance hormonal levels. Incorporating these healthy fats into your diet can aid foods that boost ovulation and be very helpful for women trying to maximize their fertility.",
      },
      {
        subheader: "Full-Fat Dairy: A Boost for Ovulation",
        text: "Full-fat dairy, including milk, yoghurt, and cheese, may help women with fertility issues. Full-fat dairy has been found to support better ovulatory function and more conception chances compared to low-fat dairy. Rich in calcium, vitamin D, and healthy fats, dairy supports a healthy reproductive system and aids in foods that increase fertility in women.",
      },
      {
        subheader: "Avocados: Fertility-Boosting Fats",
        text: "Avocados are another rich source of healthy fats, particularly monounsaturated fats, which aid in hormone regulation and increase fertility. Additionally, avocados contain folate, which is essential for the proper functioning of the reproductive system. Adding avocados to your diet supports the foods to increase ovulation and creates a fertile environment.",
      },
      {
        subheader: "Salmon: Rich in Omega-3 Fatty Acids",
        text: "Salmon, along with other fatty fish like mackerel and sardines, is an excellent source of omega-3 fatty acids. These healthy fats can improve egg quality, reduce inflammation, and regulate hormones, all of which are essential for foods that increase fertility in females. Including fatty fish like salmon in your meals can boost your chances of conception by supporting overall reproductive health.",
      },
      {
        subheader: "Legumes: A Plant-Based Fertility Powerhouse",
        text: "Legumes such as beans, lentils, and chickpeas are packed with protein, fibre, and essential nutrients like folate. These nutrients help support hormonal balance, which is key for ovulation and fertility. Legumes are also a great source of iron, which is necessary for maintaining healthy egg quality. Including these foods to improve female egg quality can improve your chances of conception.",
      },
      {
        subheader: "Citrus Fruits: Rich in Vitamin C",
        text: "Citrus fruits, such as oranges, grapefruits, and lemons, are high in vitamin C, a powerful antioxidant that supports immune function and helps regulate the menstrual cycle. Vitamin C also plays a role in hormone production, which is critical for ovulation. By adding these foods to help conceive to your diet, you can boost your fertility and improve overall reproductive health.",
      },
      {
        subheader: "Whole Grains: Supporting Hormonal Balance",
        text: "Whole grains like quinoa, brown rice, and oats are rich in B vitamins and fibre, which help stabilize blood sugar levels and regulate hormones. Maintaining balanced blood sugar levels is important for fertility because fluctuations can negatively impact ovulation. Whole grains are essential for a fertility-boosting diet, providing steady energy and supporting food that boosts ovulation.",
      },
      {
        subheader: "Conclusion",
        text: "Improving fertility and increasing your chances of conception involves more than just taking supplements or medications—it’s about nourishing your body with the right nutrients. The foods that increase fertility in women listed above can help regulate hormones, support ovulation, and improve the quality of your eggs. By incorporating these nutrient-rich foods into your diet, you're taking an important step toward a healthier reproductive system and better fertility outcomes.",
        text2:
          "Seeking a boost in fertility and a journey into parenthood? Amrit Hospital's expert team offers personal care and advanced treatments for your reproductive health. Contact us today to start your journey toward parenthood with confidence.",
      },
    ],
  },
  {
    id: "11",
    img: b11,
    header: "Apple Cider Vinegar During Pregnancy: Good or Bad?",
    data: [
      {
        subheader: "Introduction:",
        text: "Apple cider vinegar has recently gained much attention for its various health benefits. But when it comes to pregnancy, all expectant mothers have the following query: Is it safe for pregnant women to drink apple cider vinegar? Or is apple cider vinegar good for pregnancy? This article covers the benefits and risks of ACV during pregnancy so that you may make an informed decision.",
      },
      {
        subheader: "What is Apple Cider Vinegar?",
        text: "ACV, or apple cider vinegar, is a fermented liquid made from crushed apples by some yeast and bacteria. It has acetic acid, which makes it sour and could also be beneficial for health. Very rich in antioxidants and beneficial enzymes, ACV is used by most people to support digestion, weight management, and blood sugar regulation.",
      },
      {
        subheader: "Possible Benefits of Apple Cider Vinegar During Pregnancy",
        text: "Most pregnant women seek out the possible health benefits of apple cider vinegar. These include:",
      },
      {
        subheader: "1. Supports Digestion",
        text: "Pregnancy causes digestive problems, such as bloating and indigestion. ACV improves the gut's health by increasing beneficial bacteria growth. This will ensure easier digestion and higher nutrient intake.",
      },
      {
        subheader: "2. Regulates Blood Sugar",
        text: "ACV may help regulate blood sugar levels by improving the sensitivity of insulin, which may be beneficial for pregnant women who are at risk of developing gestational diabetes. It should not, however, replace medical advice or prescribed treatment.",
      },
      {
        subheader: "3. Boosts Immunity",
        text: "ACV is endowed with antimicrobial properties that help enhance the immune system. This can therefore protect pregnant women from the risks of infection. Organic acids and enzymes within ACV activate natural body immunity in pregnant women.",
      },
      {
        subheader: "4. Reduces Morning Sickness",
        text: "Many women suffer nausea and morning sickness in the first trimester. Diluted ACV has been used as anecdotal evidence to balance the stomach acid level, relieving nausea and discomfort.",
      },
      {
        subheader: "5. Encourages Healthy Urinary Tract",
        text: "UTIs are common during pregnancy. The antimicrobial property of the ACV will not favour the proliferation of harmful bacteria in any environment, thus promoting the overall health of the urinary tract and good bladder functions.",
      },
      {
        subheader: "6. Helps in Healthy Weight Gain",
        text: "Pregnancy is not something one loses weight for; instead, one must gain weight healthily. So, ACV supports cravings and fullness, creating a fine balance toward weight gain.",
      },
      {
        subheader:
          "Risks and Precautions of Taking Apple Cider Vinegar During Pregnancy",
        text: "Though apple cider vinegar contains various benefits, there are a few risks that should be considered while consuming it:",
      },
      {
        subheader: "1. Acidic Nature",
        text: "ACV is very acidic. If consumed in high concentration, then it erodes the tooth enamel and irritates the stomach lining. Therefore, pregnant women are forbidden to take it undiluted because it may cause heartburn and acid reflux due to irritation.",
      },
      {
        subheader: "2. Effects on Potassium Level",
        text: "An apple cider vinegar overdose results in a lack of potassium. Potassium is needed for the normal functioning of muscles and nerves during pregnancy. Potassium deficiency causes muscle cramps and weakness.",
      },
      {
        subheader: "3. Gastrointestinal Upset",
        text: "There are those women who feel bloated or uncomfortable in the stomach after drinking ACV. Dosage should thus be taken in small quantities and there is a need to observe how the body reacts.",
      },
      {
        subheader: "4. Raw ACV and Pasteurised ACV",
        text: "The unpasteurised apple cider vinegar should be avoided by pregnant women due to harmful bacteria that can prove dangerous to the mother and her baby. Hence, pasteurised versions can be chosen, which are safe from pathogens.",
      },
      {
        subheader: "5. Interaction with Other Medications",
        text: "ACV may interact with some drugs, like insulin or diuretics. Women who are pregnant and on medications should consult their doctor before taking apple cider vinegar.",
      },
      {
        subheader: "How to Take Apple Cider Vinegar Safely During Pregnancy",
        text: "If you have decided to take apple cider vinegar during pregnancy, here are the safe practices to consider:",
      },
      {
        subheader: "1. Dilution is the Key",
        text: "Dilute ACV in water before consumption. One tablespoon in a large glass of water is commonly recommended.",
      },
      {
        subheader: "2. Moderation is the Key",
        text: "Avoid excessive intake. Stick to small amounts to avoid side effects.",
      },
      {
        subheader: "3. Consult Your Doctor",
        text: "Talk to your healthcare provider before incorporating ACV into your diet to ensure it aligns with your health needs.",
      },
      {
        subheader: "4. Use in Cooking",
        text: "Use ACV in salad dressings or marinades for more enjoyable and safer consumption of its benefits.",
      },
      {
        subheader: "Conclusion",
        text: "So, is apple cider vinegar good for pregnancy? While ACV may offer certain benefits, such as aiding digestion, supporting immunity, and managing blood sugar levels, it is important to consume it in moderation and under medical guidance.",
        text2:
          "If you're considering incorporating apple cider vinegar during pregnancy, always consult your doctor to ensure it is suitable for your unique health condition. Stay informed and prioritise your well-being for a smooth and healthy pregnancy journey.",
        text3:
          "For expert guidance on maintaining a healthy pregnancy, consult the specialists at Amrit Hospital. Our dedicated team is here to provide you with the best prenatal care and personalised health advice. Book an appointment today to ensure a safe and happy pregnancy.",
      },
    ],
  },
  {
    id: "12",
    img: b12,
    header: "Cosmetic Gynaecology: Everything You Need to Know",
    data: [
      {
        subheader: "Introduction:",
        text: "Cosmetic gynaecology has become very popular as women seek treatments that enhance the look and function of their intimate areas. These treatments address a variety of issues ranging from aesthetic enhancement to physical relief, providing remedies that can elevate self-esteem and sexual well-being for many women.",
        text2:
          "Such treatments may be targeted to concerns like vaginal laxity, labial size, and colour of the skin in the genital area. Cosmetic gynaecology provides cosmetic treatments that make women confident and comfortable with their bodies for aesthetic improvement or relief. This article explores some of the most common procedures and their benefits.",
      },
      {
        subheader: "What is Cosmetic Gynaecology?",
        text: "Cosmetic gynaecology is a combination of procedures designed to improve the appearance, functionality, and health of the female genital area. Aesthetic treatments would be those procedures that enhance the look of the vulva and improve comfort as well as sexual satisfaction. While traditional gynaecology is more concerned with medical issues, cosmetic gynaecology involves procedures that serve both functional and cosmetic purposes, thus enabling women to attain holistic reproductive health.",
      },
      {
        subheader: "Common Cosmetic Gynaecology Procedures",
        text: "These are some of the most popular cosmetic gynaecology procedures that women opt for:",
      },
      {
        subheader: "1. Vaginal Rejuvenation",
        text: "This is one of the cosmetic gynaecological procedures most common to tighten muscles and tissues that have loosened due to birth or old age. It deals with problems like vaginal laxity, dryness, and pain, which transform the female reproductive organ to not only look more attractive but also function better.",
        text2:
          "Laser vaginal rejuvenation is a non-surgical procedure that encourages the production of collagen, reconstructing the walls of the vagina and its associated sensations. This minimally invasive procedure has a fast recovery time and minimizes downtime, giving most women an easy option to gain tightness and comfort in the vagina.",
      },
      {
        subheader: "2. Labia Reduction (Labiaplasty)",
        text: "Aesthetic gynaecology often includes labiaplasty, which is a reduction in the size of the labia minora, that is, the inner lips of the vulva. It is mainly done to attain a more balanced or youthful appearance. Other reasons for performing labiaplasty include physical activities and sexual intercourse.",
      },
      {
        subheader: "3. Vaginal Bleaching",
        text: " This non-surgical method of vaginal bleaching lightens the skin around the vaginal area. Some women seek this treatment so that the appearance of the genital region can again be more uniform and youthful. While vaginal bleaching may not be medically required, it improves a woman's confidence as it enhances her perception of her body.",
      },
      {
        subheader: "4. Hymenoplasty",
        text: "Hymenoplasty is a reconstructive surgical procedure restoring the hymen. The procedure may be undertaken for cultural, religious, or personal reasons. It is essentially the surgical reconstitution of the hymen to its pre-existing state. Although it does not affect sexual function, it is an alternative for those wishing to restore the look of their genital area for reasons of culture or personal belief.",
      },
      {
        subheader: "Advantages of Cosmetic Gynaecology",
        text: "Women opt for cosmetic gynaecology for various reasons, including:",
      },
      {
        subheader: "1. Improving their self-esteem:",
        text: "It enhances body image and confidence level in appearance.",
      },
      {
        subheader: "2. Improving Comfort:",
        text: "This removes discomfort due to large labia or vaginal laxity.",
      },
      {
        subheader: "3. Improving Sexual Function:",
        text: "It improves sensation and makes the vaginal area appear much more youthful and functional in its appearance.",
      },
      {
        subheader: "4. Postpartum Restoration:",
        text: "The aim is the restoration of the genital area back to its former appearance and function post-delivery.",
        text2:
          "These treatments give women various physical and psychological benefits that aim to improve aesthetic and functional changes.",
      },
      {
        subheader: "Is Cosmetic Gynaecology Safe?",
        text: "Cosmetic gynaecology, like any other medical procedure, has its risks; however, they are efficiently minimized if handled by an experienced professional. Some of the treatments or procedures that are not invasive include laser vaginal rejuvenation, which has minimal risks and nearly no downtime.",
        text2:
          "Like all surgeries, labiaplasty can be associated with risks like infection or scarring. The best way to achieve this while minimizing most risks is to select a practitioner who is professionally trained in aesthetic gynaecology. Consulting with your practitioner on expectations, diagnosis, potential complications, and post-treatment care is of utmost importance.",
      },
      {
        subheader: "Conclusion",
        text: "Cosmetic gynaecology offers women a range of treatments to build confidence, comfort, and satisfaction with their bodies. It can either be the rejuvenation of the cosmetic aspect of the vulva or the enhancement and facilitation of sexual function; the procedures are relevant for women with several personal concerns. Therefore, if you are considering a procedure, find a trusted professional who will lead you through options and give you a knowledgeable choice.",
        text2:
          "Amrit Hospital provides individualized attention and a variety of cosmetic gynaecology treatments to suit your specific requirements. Whether you are looking for aesthetic improvement or physical comfort, the staff is there to help you. Contact us today to schedule your consultation and take the first step toward a more confident and comfortable you.",
      },
    ],
  },
  {
    id: "13",
    img: b13,
    header: "Normal Delivery Tips: What to Do to Avoid a C-Section",
    data: [
      {
        subheader: "Introduction:",
        text: "Pregnancy is indeed one of the most exciting times; however, most expectant mothers fear whether they will have a normal delivery or will require a C-section. Although both are safe options, most women prefer a vaginal birth for quicker recovery and a more natural experience.",
      },
      {
        subheader: "Understanding Normal Delivery and C-Section",
        text: "Before we move on to tips and exercises, let us make a difference between normal delivery and a C-section. Normal delivery means that the baby is delivered vaginally. A C-section involves a surgical procedure whereby the baby may be delivered from an incision in the mother's abdomen and uterus. Usually, normal delivery is preferred; however, there are some circumstances under which a C-section becomes necessary, such as complications with fetal position or labour progression.",
      },
      {
        subheader: "Pregnancy Exercises for Normal Delivery",
        text: "An active lifestyle during pregnancy is the key to preparing for healthy delivery. Some of these pregnancy exercises for normal delivery can strengthen one's body and improve flexibility.",
      },
      {
        subheader: "• Pelvic Tilts",
        text: "These can help strengthen the core muscles and remove lower back pain while helping in positioning the baby for birth.",
      },
      {
        subheader: "• Squats",
        text: "Squatting helps open up your pelvis and prepares your body for a smoother delivery.",
      },
      {
        subheader: "• Kegel Exercises",
        text: "The strengthening of pelvic floor muscles prepares you for labour and can also help with recovery afterwards.",
      },
      {
        subheader: "• Walking",
        text: "Walking is one of the simplest and most effective exercises, which may help the baby settle into a proper position while also improving overall stamina for labour.",
      },
      {
        subheader: "The Importance of Staying Hydrated and Eating Well",
        text: "What you put inside your body makes a difference. A healthy diet full of fibre, protein, and all the foods your body needs keeps your energy up and flowing well with the labour process. Hydration is also key—the contractions become stalling if the patient becomes dehydrated, increasing the risk of a C-section. Drinking plenty of water ensures your body has what it needs for the demands of labour.",
      },
      {
        subheader: "Consider Prenatal Yoga",
        text: "Prenatal yoga can be an excellent method for preparing the body for a normal delivery. Stretching and breathing techniques of yoga enhance flexibility, release tension, and induce relaxation. Many positions, such as the cat-cow stretch, can help to open the pelvis, making it easier for a baby to move through it. Mindfulness practices in yoga also reduce anxiety and enhance one's ability to manage pain when in labour.",
      },
      {
        subheader: "Stay Active, But Mind Your Position",
        text: "How you move and position your body during labour matters. Avoid lying flat on your back, as this can hinder the birth process. Try positions like standing, walking, or kneeling on all fours that encourage the baby to move into the optimal position for normal delivery.",
      },
      {
        subheader: "Exercises to Avoid If You Have a C-section",
        text: "If you end up needing a C-section, then recovery is your top priority. Stay away from high-impact exercises such as running or heavy lifting until your doctor clears you. Opt for gentle exercises such as walking or pelvic floor exercises, which restore your strength gradually and progress your recovery.",
      },
      {
        subheader: "Best Exercises for Normal Delivery",
        text: "These exercises, when done regularly, prepare the body for the physical demands of labour and delivery.",
      },
      {
        subheader: "• Pelvic Floor Exercises (Kegels)",
        text: "Strong pelvic muscles are important for labour support and postpartum recovery.",
      },
      {
        subheader: "• Prenatal Swimming",
        text: "Prenatal swimming is a low-impact activity, thereby engaging the muscles and providing endurance without imposing unnecessary pressure on joints.",
      },
      {
        subheader: "• Birthing Ball Exercises",
        text: "Sitting on a birthing ball and gently bouncing can help align your pelvis, and rotating on it can assist in the baby's descent.",
      },
      {
        subheader: "Conclusion: Preparing for a Safe, Normal Delivery",
        text: "A normal delivery is an empowering experience but also requires preparation and proactivity. Regular pregnancy exercises for normal delivery, maintaining a balanced diet, adequate hydration, and optimal positions during labour can increase the chances of having a natural birth.",
        text2:
          "Every pregnancy is unique. Flexibility is key; sometimes a C-section is required for safety, but with the right care and guidance, choices can be made that best support your goals for labour and delivery.",
        text3:
          "At Amrit Hospital, we strive to ensure that your journey of pregnancy is as smooth and healthy as possible. Our expert team will provide personal care, guide you, and support you towards the best birth experience. So, don't wait; instead, contact us today to book a consultation and begin the journey for a safe and empowering delivery with you and your baby.",
      },
    ],
  },
  {
    id: "14",
    img: b14,
    header: "Best Diet for Renal Calculi Patients: How to Prevent Recurrence",
    data: [
      {
        //subheader: "Introduction:",
        text: "Renal calculi, or kidney stones, are a painful condition that affects millions of people around the globe. If you have experienced kidney stones, you understand how important it is to avoid their recurrence. A properly balanced diet for renal patients actively reduces the chances of stone formation development. This guide explores appropriate eating options together with food restrictions and an example diet chart for kidney patients for maintaining maximum kidney function.",
      },
      {
        subheader: "Understanding Renal Calculi and Their Causes",
        text: "Kidney stones develop when there is a surplus of minerals and salts in the urine that causes crystallization. The risk increases due to dehydration, excess sodium, obesity, and other medical conditions. The most prevalent types of kidney stones are:",
      },
     
      {
        subheader: "• Calcium Oxalate Stones: ",
        text: "The most prevalent one, usually resulting from excessive oxalate foods and dehydration.",
      },
      {
        subheader: "• Uric Acid Stones: ",
        text: "Develops as a result of excess protein consumption and high levels of uric acid.",
      },
      {
        subheader: "• Struvite Stones:",
        text: "They are usually caused by urinary tract infections.",
      },
      {
        subheader: "• Cystine Stones:",
        text: "A less common type caused by a genetic disorder leading to a surplus of cystine in urine.",
      },
      {
        //subheader: "The Importance of Staying Hydrated and Eating Well",
        text: "A specially designed diet for renal calculi patients can control mineral balance so that stone formation is kept to a minimum.",
      },
      {
        subheader: "Ideal Diet for Renal Calculi Patients",
      },
      {
        subheader: "1. Stay Hydrated",
        text: "Consumption of adequate drinking water will help dilute the urine and thus prevent stones from forming. Your daily intake of water should be 2-3 liters, unless advised otherwise by your physician. Lemon water is good because citrate prevents the formation of calcium stones. Other appropriate options include coconut water and barley water.",
      },
      {
        subheader: "2. Reduce Sodium Consumption",
        text: "Diet high in sodium is a risk factor for urinary calcium excretion, resulting in stone formation. To reduce sodium intake:",
      },
      {
        subheader: "• Avoid processed meals, canned soups, and fast foods.",
      },
      {
        subheader: "• Season with herbs and spices rather than salt.",
      },
      {
        subheader: "• Read food labels and select ones with lower sodium.",
      },
      {
        subheader: "3. Moderate Your Calcium Intake",
        text: "It may sound contrary, but adequate calcium is important. Low calcium can lead to oxalate accumulation and increase stone risk. Instead of excluding calcium, take natural sources like dairy foods (in moderation), almonds, spinach, and plant-based milk with added calcium.",
      },
      {
        subheader: "4. Limit Oxalate-Rich Foods",
        text: "For those with calcium oxalate stones, cutting down on oxalate-rich foods can help prevent further stone formation. Oxalate-containing foods include spinach, beets, nuts (walnuts, almonds, and cashews), chocolate, tea, and sweet potatoes ",
      },
      {
       
        text: "Supplementing oxalate foods with calcium sources can lower their absorptive capacity in the intestine, minimizing the risk for stone development.",
      },
      {
        subheader: "5. Balance Protein Intake",
        text: "Animal protein excess may result in increased uric acid levels, thus increasing the risk of uric acid stones. In place of red meat, use plant sources of protein such as lentils, beans, tofu, and chickpeas. ",
      },
      {
        text: "In case of animal protein intake, have lean meats like chicken or fish and avoid processed meats.",
      },
      {
        subheader: "6. Avoid Sugary Drinks and Alcohol",
        text: "Sodas, energy drinks, and alcohol may lead to dehydration and enhance stone formation risk. Drink water, herbal teas, and natural fruit juices in moderation.",
      },
      {
        subheader: "Diet Chart for Kidney Patients",
        text: "A planned diet chart for dialysis patients and those with a risk of kidney stones can simplify meal planning. Here's a sample diet plan:",
      },
      {
        subheader: "Breakfast:",
        
      },
      {
        text: "• Oatmeal with almonds and lemon water",
        text2: "• Whole wheat toast with peanut butter (unsalted)",
        text3: "• A banana (for non-restricted potassium patients)",
      },
      {
        subheader: "Mid-Morning Snack:",
      },
      {
        text: "• Small bowl of fresh fruit (apple, pear, or grapes)",
        text2: "• Unsweetened yogurt",
      },
      {
        subheader: "Lunch:",
      },
      {
        text: "• Brown rice with grilled chicken or tofu",
        text2: "• Steamed vegetables (broccoli, carrots, bell peppers)",
        text3: "• Bowl of lentil soup (low sodium)",
      },
      {
        subheader: "Afternoon Snack:",
      },
      {
        text: "• Handful of unsalted nuts",
        text2: "• Herbal tea",
      },
      {
        subheader: "Dinner:",
      },
      {
        text: "• Quinoa with baked fish or cottage cheese",
        text2: "• Stir-fried zucchini and green beans",
        text3: "• Cucumber and tomato salad (lightly dressed)",
      },
      {
        subheader: "Before Bed Snack:",
        text: "Low-fat yogurt with flaxseeds",
        text2: "This diet chart for kidney patients provides balanced nutrient intake while lowering the risk of kidney stones.",
      },
      {
        subheader: "Diet for Chronic Renal Failure Patients",
        text: "In the case of patients with chronic kidney disease (CKD), dietary changes are even more essential. The diet for chronic renal failure patients must consist of:",
      },
      {
        text: "• Low-phosphorus foods:  Avoid processed meats, carbonated drinks, and dairy products in large quantities.",
        text2: "• Limited potassium diet: Limit bananas, potatoes, oranges, and tomatoes.",
        text3: "• Restriction of protein: Good protein foods must be chosen and high intake should be avoided.",
        text4: "• Fluid management: Fluid intake needs to be managed according to the function of the kidneys.",
      },
      {
        text: "• Avoid foods rich in phosphate: Processed foods, colas, and certain dairy foods can lower the function of the kidneys.",
      },
      {
        text: "A renal dietitian can customize a diet for kidney patients according to their needs and prevailing medical conditions.",
      },
      {
        subheader: "Other Lifestyle Changes for Prevention of Kidney Stones",
        text: "Besides the proper diet for renal patients, certain lifestyle changes can prevent kidney stones:",
      },
     
      {
        text: "• Healthy weight: Obesity increases the risk of kidney stones.",
        text2: "• Exercise daily: Daily exercise helps maintain healthy kidney function.",
        text3: "• Track medications: Certain medications also cause kidney stones, so consult your doctor before taking supplements.",
      },
      {
        subheader: "Conclusion",
        text: "Prevention is better than a cure, and making the correct food choices can significantly lower your risk of having recurrent kidney stones. A properly planned diet for renal calculi patients, along with frequent follow-ups, can help maintain healthy functioning of the kidneys.",
        text2: "In case you or your dear one is suffering from kidney issues, professional medical advice is essential. At Amrit Hospital, we have experienced kidney care experts who offer professional advice and personalized treatment protocols. Book an appointment with our professionals today and begin your road towards better kidney health.",
      },
    ],
  },
  {
    id: "15",
    img: b15,
    header: "Signs of a Fungal Ear Infection: How to Identify and Treat It",
    data: [
      {
        //subheader: "Introduction:",
        text: "Ear fungal infection, or otomycosis, is a commonly misunderstood condition that affects the outer ear. A fungal infection differs from bacterial infections because it persists stubbornly, which leads to discomfort alongside possible serious complications when medical care is delayed. The infections are caused by fungi such as Aspergillus and Candida, which thrive in warm and moist environments.",
      },
      {
        text: "Individuals prone to fungal ear infections are those who swim frequently, those who use earbuds for long periods, and individuals with compromised immune systems. Seeking prompt fungal ear infection treatment along with recognizing its early warning signs helps patients recover more effectively.",
      },
     
      {
        text: "• Over-cleaning with cotton swabs",
        text2: "• Inserting foreign objects into the ear",
        text3: "• Using unclean earbuds",
      },
      {
        text: "People with compromised immune systems, such as those with diabetes or HIV, or those receiving chemotherapy, are more likely to get a fungal ear infection. Moreover, overuse of antibiotics will kill beneficial bacteria in the ear, paving the way for fungi to grow uncontrollably.",
      },
      {
        subheader: "Recognizing the Signs of a Fungal Ear Infection",
        text: "Symptoms of a fungal ear infection can vary, but the most usual symptoms include:",
      },
      {
        text: "• Recurrent itching in the ear canal, ranging from moderate to severe.",
        text2: "• Feeling full or blocked in the ear, and sometimes it can cause a temporary loss of hearing.",
        text3: "• Discharge in the ear, usually white, yellow, green, or even black (black fungal ear infection), depending on the type of fungus.",
        text4: "• A scaly, flaky buildup or cotton-like, thick discharge.",
      },
      {
        text: "• Mild to moderate burning or pain in the ear.",
        text2: "• Redness and swelling of the outer ear canal, which becomes sensitive to touch.",
      },
      {
        //subheader: "The Importance of Staying Hydrated and Eating Well",
        text: "If left untreated, symptoms may become worse and result in serious complications. If you notice these symptoms, looking at human fungal ear infection pictures online will help you compare and determine if you need a doctor’s advice. But for a proper diagnosis, always seek a healthcare provider.",
      },
      
      {
        subheader: "Diagnosis of Fungal Ear Infection",
        text: "To determine if you have a fungal ear infection, an ENT doctor will inspect your ear with an otoscope to look for fungal growth, redness, or abnormal discharge. They can also take a sample of the discharge for laboratory analysis to identify the specific fungus involved. In chronic or recurrent infections, further tests can be done to rule out conditions like diabetes or immune disorders. Accurate diagnosis guarantees the best treatment plan, avoiding worsening or recurrence of the infection.",
      },
      {
        subheader: "Fungal Ear Infection Treatment",
        text: "Once diagnosed, the physician will prescribe a treatment depending on the severity of the ear fungal infection.",
      },
      {
        text: "• Ear canal cleaning: A healthcare professional will clear out fungal debris using specialized tools, microsuction, or irrigation. This procedure relieves symptoms such as itching and discomfort while also enhancing the efficacy of antifungal drugs by promoting better absorption.",
      },
      {
        text: "• Antifungals: Over-the-counter antifungal ear drops like clotrimazole or miconazole are typically applied for minor to moderate infections. For more severe or chronic infections, oral antifungal drugs like itraconazole or fluconazole can be taken to target the infection internally.",
      },
      {
        text: "• Keeping the ears dry: Moisture creates an ideal environment for fungi to thrive, leading to a greater chance of reinfection. It is advisable not to swim, use earbuds, or put cotton buds in the ear during the healing process.",
      },
      {
        subheader: "Home Remedies (With Caution)",
        text: "Some home remedies may provide relief but should always be used under medical supervision. A diluted vinegar solution or hydrogen peroxide rinse can create an unfavorable environment for fungal growth. However, improper use may irritate or worsen the infection. To ensure safety and effectiveness, always consult a doctor before attempting any home treatments.",
      },
      {
        subheader: "Prevention of Fungal Ear Infection",
        text: "Preventing a fungal ear infection involves practicing good hygiene habits and lifestyle modifications.",
      },
     
      {
        text: "• Dry the ears: Dry the outer ear gently with a clean towel after bathing or swimming and tilt your head to remove excess water.",
        text2: "• Do not clean ears excessively: The ear has a natural mechanism of self-cleaning, and excessive use of cotton swabs removes protective oils.",
        text3: "• Clean earbuds and hearing aids regularly: Make sure they are dry and free from fungal buildup.",
        text4: "• Treat underlying conditions well: People with compromised immune systems must be even more cautious.",
      },
      {
        text: "• Seek a doctor's advice for long-term prevention if you have repeated fungal ear infections.",
      },
      {
        subheader: "When to Get Medical Attention",
        text: "If you have persistent indications of a fungal ear infection that do not improve with simple care or if the symptoms get worse, it is critical to visit a doctor as soon as possible. Delayed treatment may result in chronic infections, ear canal damage, secondary bacterial infections, and repeated infections that need individualized treatment and prevention measures. Timely medical care ensures proper management of the condition and avoids complications that may affect your hearing and overall ear health.",
      },
      {
        text: "For professional diagnosis and full treatment, visit Amrit Hospital today. Our experienced ENT specialists offer sophisticated care to make you recover fast and avoid future infections. Don't let a fungal ear infection interfere with your daily life—get professional assistance now for healthier ears and permanent relief.",
      },
    ],
  },
  {
    id: "16",
    img: b16,
    header: "Understanding Gallbladder Stone Disease: What You Need to Know",
    data: [
      {
        //subheader: "Introduction:",
        text: "Human bodies operate through complex interconnected systems even while specific minor issues sometimes create major discomfort. Gallbladder stone disease represents a common condition that affects millions across the globe. People who encounter gallbladder stones, cholelithiasis, or interact with patients who have it must understand its definition, causes, available prevention methods, and treatment strategies.",
      },
      {
        text: "This guide examines the gallbladder stone condition with an overview of strategies you should use to maintain a healthy digestive system.",
      },
      {
        subheader: "What Is Gallbladder Stone Disease?",
        text: "The gallbladder is a small pear-shaped structure that positions itself beneath the liver. Through bile storage and release functions, the gallbladder serves digestion because it helps break down fats. Stone formation inside the gallbladder leads to gallbladder stone disease. The stones inside the gallbladder differ in size, with the smallest being a grain of sand and the largest resembling a golf ball.",
      },
      {
        subheader: "What Is the Cause of Gallbladder Stones?",
        text: "While the exact cause of gallbladder stones is not always known, several risk factors increase the likelihood of their formation:",
        text2: "Increased cholesterol levels in bile: If bile becomes too rich in cholesterol, it will crystallize and form stones.",
        text3: "Increased bilirubin: In some diseases, such as liver disease or infection, there can be an excess of bilirubin, which will contribute to stone formation.",
        text4: "Poor gallbladder emptying: If the gallbladder does not empty, bile is concentrated and can lead to stone formation.",
      },
      {
        text: "Diet and obesity: A diet low in fiber and high in unsaturated fats can cause gallstone formation.",
        text2: "Genetics: Inheritance increases your risk factor for developing gallstones.",
        text3: "Pregnancy and hormonal changes: Hormonal changes, especially in females, increase movement in the gallbladder and lead to stones.",
      },
     
      {
        subheader: "Identifying Gallbladder Stone Symptoms",
        text: "Gallbladder stones usually do not cause symptoms, particularly if they are small. Large stones or stones that obstruct bile flow, however, can be very painful. Some of the most common gallbladder stone symptoms to look out for are:",
      },
      {
        text: "• Severe pain in the upper right side or upper abdomen: This type of pain, known as biliary colic, may last from minutes to hours and usually happens after consuming fatty foods.",
      },
      {
        text: "• Nausea and vomiting: Recurring nausea, particularly after eating, can be a sign of gallstones.",
      },
      {
        text: "• Indigestion and bloating: If your stomach hurts after a meal, it might be because gallstones are slowing down digestion.",
      },
      {
        text: "• Jaundice (yellowing of skin and eyes): This happens when gallstones obstruct bile flow and cause a bilirubin buildup.",
        text2: "• Fever and chills: Infections caused by blockage of gallstones can lead to fever and chills, and one needs immediate medical care.",
        text3: "• Color changes in stool and urine: Pale stools and dark-colored urine may indicate a blockage of the bile duct due to a gallstone.",
      },
      {
        subheader: "Diagnosis and Treatment of Gallbladder Stones",
        text: "A physician may suggest imaging tests such as an ultrasound or CT scan if you are suspected of having gallbladder stones. A blood test might also be performed to detect infection or liver issues.",
      },
      {
        subheader: "Treatment Options:",
        text: "• Dietary and lifestyle modifications: In case gallbladder stones are not creating severe symptoms, controlling your diet by restricting fatty foods and remaining hydrated will assist in preventing complications.",
      },
     
      {
        text: "• Medications: Occasionally, oral medications can break down smaller gallstones, but the process is time-consuming and not always successful.",
        text2: "• Surgical gallbladder removal (cholecystectomy): If gallstones lead to recurrent pain or problems, surgery to remove the gallbladder is usually the best option. Fortunately, the body can function without it.",
        text3: "• Endoscopic or shockwave therapy: Occasionally, treatment such as ERCP (endoscopic retrograde cholangiopancreatography) or lithotripsy (shockwave therapy) can dissolve stones without the need for surgery.",
      },
      {
        subheader: "Preventing Gallbladder Stone Disease",
        text: "Gallbladder stone disease can be prevented by staying healthy and exercising responsible eating habits. A diet with plenty of fiber in fruits, vegetables, and whole grain foods stabilizes bile composition to decrease the development of gallstones. Staying well-hydrated by drinking enough water is also an important part of digestion and will prevent bile concentration. Regular physical exercise is also useful in body weight control to reduce the chance of developing gallstones.",
        text2: "Prevention of sudden weight loss by extreme dieting is also necessary, as dramatic alterations in metabolism can result in imbalances in bile composition, which in turn will enhance gallbladder stone formation. Decreasing consumption of processed foods, avoiding unhealthy fats, and adding good sources of healthy fats like nuts and fish can help to have a healthy gallbladder. By making these subtle yet significant changes, you can considerably lower your risk of developing symptoms of gallbladder stones and overall digestive wellness.",
      },
      {
        text: "When to Consult a Doctor",
        text: "If you notice recurring gallbladder stone symptoms, particularly sharp pain, fever, or jaundice, seek medical assistance right away. Overlooking gallstone complications may cause severe conditions such as gallbladder infection or pancreatitis.",
        text: "Need specialized treatment for gallbladder stone disease? At Amrit Hospital, we specialize in diagnosing and treating gallbladder stone disease with state-of-the-art medical techniques and caring compassion. Whether you require dietary guidance, medical management, or surgery, our team of experts is at your service. Don't let gallbladder stone symptoms take a toll on your quality of life. Book an appointment today and begin the journey to a pain-free life. Contact Amrit Hospital now for expert advice and customized treatment plans.",
      },
    ],
  },
  {
    id: "17",
    img: b17,
    header: "Hair Fall Treatment Solutions: Medical and Natural Remedies That Work",
    data: [
      {
        //subheader: "Introduction:",
        text: "Hair fall is something that concerns both men and women, leading to distress and loss of confidence. Whatever the cause—be it genetic, hormonal changes, stress, or external factors—finding the ideal hair fall treatment becomes crucial. Fortunately, there are numerous solutions, ranging from medical interventions to natural remedies, that can help control and reduce hair loss.",
      },
     
      {
        subheader: "Medical Treatments for Hair Fall",
      },
      {
        subheader: "1. FDA-Approved Medications",
        text: "For individuals who suffer from extreme hair loss, medications like Minoxidil and Finasteride are generally recommended. Minoxidil is a local solution that helps to grow hair, whereas Finasteride is an oral drug that inhibits hormones that are responsible for thinning hair, thus proving to be an effective hair fall treatment.",
      },
      {
        subheader: "2. Hair Transplantation",
        text2: "A more long-term solution to extensive hair loss is hair transplantation. This is a process of transferring hair follicles from regions of thick growth to balding areas. It is a common hair fall treatment in men, particularly those with male pattern baldness. Women with thinning hair can also be treated with transplantation, though their hair loss pattern is different.",
      },
     
      {
        subheader: "3. Platelet-Rich Plasma (PRP) Therapy",
        text: "During PRP therapy, medical professionals use a patient's concentrated platelets to stimulate scalp hair development. The hair rejuvenation process through PRP maintains a safe and natural approach toward treating hair follicles in both male-pattern baldness and female-pattern hair thinning. PRP is most effective when physicians utilize it in combination with other treatment modalities.",
      },
      {
        subheader: "4. Low-Level Laser Therapy (LLLT)",
        text: "LLLT is a non-surgical therapy that promotes hair growth with the use of red light. It is used to enhance hair density and be prescribed alongside other treatments. It can be used for men and women who have thinning hair.",
      },
      {
        subheader: "5. Alopecia Hair Fall Treatment",
        text: "Alopecia, which results in excessive hair loss, can be managed with corticosteroids, immunotherapy, or specific biologic drugs. It is important to consult a specialist to identify the most suitable treatment strategy for alopecia hair fall treatment. Early intervention and diagnosis help considerably in outcomes.",
      },
      {
        subheader: "Homeopathy for Hair Fall",
      },
      
      {
        subheader: "1. Homeopathy Hair Fall Treatment",
        text: "Homeopathy has been becoming increasingly popular as a natural solution for hair loss. Medicines such as Silicea, Phosphorus, and Lycopodium are usually prescribed according to the individual constitution. Homeopathic treatment for hair loss is a drug-free solution which treats the causative factor responsible for hair fall and not its symptoms.",
      },
      {
        subheader: "2. Scalp Detoxification",
        text: "Homeopathic therapy may involve scalp detoxification to cleanse and rid the scalp of dirt and impurities to balance the pH, which allows healthier hair growth. A dandruff-free and oily scalp supports the growth of stronger follicles.",
      },
      {
        subheader: "3. Stress Management",
        text: "Stress is one of the key causes of hair loss. Homeopathic remedies relieve stress and anxiety, treating hair fall at its core. Lifestyle modification in the form of yoga, meditation, and pranayama can add to the efficacy of homeopathic remedies.",
      },
     
      {
        subheader: "Natural Remedies for Hair Fall",
        text: "If you favor natural medicine, several home hair fall treatment solutions can reduce hair loss effectively.",
       
      },
      {
        subheader: "1. Oil Massages",
        text: "Regular scalp massages with essential oils like coconut, castor, and rosemary enhance blood flow and reinforce hair follicles and can be an effective hair fall treatment. Warm oil treatment is also beneficial for increased absorption and hair growth stimulation.",
       
      },
      {
        subheader: "2. Onion Juice",
        text: "As onion juice is rich in sulfur, it helps in the regrowth of hair follicles and managing hair thinning. Onion juice applied to the scalp twice a week can show noticeable results. Mixing it with honey or aloe vera reduces its strong odor.",
       
      },
      {
        subheader: "3. Aloe Vera",
        text: "Aloe vera contains enzymes that fix dead cells of the scalp, promoting hair growth while slowing down loss of hair. It assists in maintaining the pH balance of the scalp, minimizing irritation and flakiness.",
       
      },
      {
        subheader: "4. Balanced Diet",
        text: "Intake of plenty of iron, protein, omega-3 fatty acids, and biotin greatly improves hair health and prevents excessive shedding of hair. Spinach, eggs, nuts, and fish are of utmost benefit. Lack of these nutrients has been associated with excessive hair loss, and research indicates that iron deficiency is one of the primary reasons for hair loss in women.",
       
      },
      {
        subheader: "5. Herbal Supplements",
        text: "Amla, Bhringraj, and Ashwagandha are all Ayurvedic herbs that make wonderful natural hair strengthening and growth treatments. They reduce inflammation, enhance blood circulation, and nourish the hair roots internally.",
       
      },
      {
        subheader: "6. Avoiding Harsh Hair Treatments",
        text: "Limiting the usage of heat appliances, chemical treatment, and over-dyeing of hair proves beneficial for hair health. Gentle shampooing and conditioning as needed based on your hair type will prevent hair fall.",
       
      },
      {
        subheader: "Choosing the Right Treatment",
        text: "With so many options available, selecting the right hair fall treatment depends on the underlying cause of hair fall. Consulting a specialist can help determine what best suits your treatment using medical, homeopathic, or natural methodologies. Early intervention is key to preventing further damage and promoting hair regrowth.",
        text2: "Struggling with hair loss? We have high-end hair fall treatment solutions specifically for you at Amrit Hospital. Whether you'd like to avail medical treatments, homeopathic hair fall treatment, or even ayurvedic cures, we're at your service. Call us for a consultation and experience the best confidence boost ever with healthier hair that's more beautiful than before.",
       
      },
     
    ],
  },
  {
    id: "18",
    img: b18,
    header: "Lump Behind Ear: Causes, Symptoms, and When to See a Doctor",
    data: [
      {
        //subheader: "Introduction:",
        text: "Finding a lump behind the ear can be alarming. If it's a pea-sized lump behind the earlobe with no pain, a hard painless lump behind the ear, or a small lump behind the ear, it's natural to be concerned. Some lumps are harmless, but others need medical evaluation. In this blog, we will discuss common causes, symptoms, and when you should consult a doctor.",
      },
     
      {
        subheader: "Common Causes of a Lump Behind Ear",
      },
      {
        subheader: "1. Swollen Lymph Nodes",
        text: "Lymph nodes are very important in your immune system and assist in fighting infection. During an infection, such as a cold, ear infection, or sinus infection, lymph nodes will become swollen and cause a lump behind the ear with pain. Swelling in this situation typically resolves once the infection clears. But recurrent swelling or tenderness can necessitate additional medical evaluation, particularly when accompanied by fever or sustained illness.",
      },
      {
        subheader: "2. Sebaceous Cysts",
        text2: "A sebaceous cyst is a harmless lump filled with sebum fluid that protects the skin. The formation of sebaceous cysts occurs when hair follicles get blocked. A sebaceous cyst usually does not hurt, but an infected cyst produces both swelling and redness, which results in a small, pea-sized lump behind the earlobe. Medical drainage or minor surgery becomes necessary for sebaceous cysts that do not disappear on their own or keep growing and causing repeated infections.",
      },
     
      {
        subheader: "3. Lipomas",
        text: "Lipomas are movable, soft, fatty tumors that grow beneath the skin. They tend to be harmless and painless but may become confused with severe conditions at times. If one has a painful lump behind the ear, there is a probability that it can be a lipoma. Lumps are movable and slow-growing beneath the skin. If the size increases or becomes bothersome, a doctor might recommend removing it.",
      },
      {
        subheader: "4. Mastoiditis",
        text: "Mastoiditis is an infection of the mastoid bone, which is situated behind the ear. It is usually a complication of an unhealed ear infection. Signs and symptoms may include swelling, redness, fever, and a painful lump behind the ear. If not treated, mastoiditis can cause serious complications, such as hearing loss or infection to the surrounding tissues. Mastoiditis needs urgent medical care and is typically treated with antibiotics or, in more severe cases, surgery.",
      },
      {
        subheader: "5. Acne or Skin Infections",
        text: "A small pimple or skin infection can sometimes appear as a pea-sized lump behind the earlobe. Bacterial infections of hair follicles or plugged pores can result in redness, swelling, and pain. If the lump is filled with pus or increases in size, it could be a boil or carbuncle, which needs to be drained by a physician.",
      },
     
      {
        subheader: "6. Abscesses",
        text: "An abscess is a pocket of pus that develops as a result of infection. If you see a lump behind the ear with pain that is red and tender to the touch, it may be an abscess. These require medical attention, usually in the form of drainage and antibiotics. An abscess left untreated can cause further infection, so it's essential to get medical attention early.",
      },
      {
        subheader: "7. Tumors (Benign and Malignant)",
        text: "Although uncommon, lumps behind the ear can be tumors. Benign tumors such as fibromas or neuromas are generally harmless, while malignant tumors need to be treated urgently. If a hard lump behind the ear increases in size, changes shape, or becomes tender, it's advisable to get medical attention. Prompt diagnosis of malignant tumors greatly enhances treatment success.",
      },
      {
        subheader: "Symptoms to Look Out For",
        text: "Symptoms of a lump behind the ear can differ depending on the cause. Some of the signs to look out for are listed below:",
      },
      {
        text: "• Pain or tenderness: It could indicate an infection, abscess, or mastoiditis.",
        text2: "• Swelling and redness: It could indicate an infection or an inflammatory reaction.",
        text3: "• Fluid drainage: May be related to an abscess or ruptured cyst.",
      },
      {
        text: "• Hardness and immobility: A painless, hard lump behind the ear that doesn’t move can be a tumor or an implant cyst.",
        text2: "• Change in skin color or texture: If the lump becomes ulcerated or scaly, it needs to be checked urgently.",
        text3: "• Ear pain or hearing loss: A lump affecting ear function must be assessed urgently.",
      },
     
      {
        subheader: "When to See a Doctor",
        text: "Not every lump needs to be treated by a doctor, but you should see one if the lump on the ear bone is increasing rapidly or changing form. Persistent aching, no improvement after some weeks, or other symptoms such as fever, earache, or change in hearing could indicate an underlying issue that needs assessment.",
        text2: "If the lump is hard, fixed, or attached to deeper structures, or if one has frequent lumps in the same location, seeking medical advice is crucial. A lump that causes discomfort or interferes with daily activities should also be assessed to rule out serious conditions and ensure appropriate treatment.",
       
      },
      {
        subheader: "Conclusion",
        text: "A lump behind the ear is not usually something to be concerned about, but it's important to take note of changing sensations and seek medical care when needed. Early treatment can avoid complications and maintain good health. Regardless of whether a lump behind the ear is small, pea-sized behind the earlobe with no pain, or painful, being aware of symptoms can make all the difference.",
       text2: "Get medical attention immediately when you find a small bump behind the ear or are experiencing discomfort. The experts at Amrit Hospital are here to provide comprehensive diagnosis and treatment. Schedule an appointment today and get peace of mind from trusted professionals.",
      },
    ],
  },
  {
    id: "19",
    img: b19,
    header: "Pinched Nerve in the Shoulder Blade: Causes, Symptoms, and Treatment",
    data: [
      {
        //subheader: "Introduction:",
        text: "Shoulder blade pain can be a painful and frustrating experience, hindering simple activities such as lifting, turning, or even sleeping. Whether left shoulder blade pain or right shoulder blade pain, the cause might be a pinched nerve. This discomfort is usually due to nerve compression in the upper back or neck, radiating pain into the shoulder blade area.",
        text2: "Individuals who suffer from either left shoulder blade pain or right shoulder blade pain realize how serious it is. This article will describe what causes shoulder blade pain as it outlines its symptoms and effective treatment for shoulder blade pain.",
      },
     
      {
        subheader: "Causes of a Pinched Nerve in the Shoulder Blade",
        text: "A pinched nerve occurs when there is too much pressure on a nerve from surrounding tissues, like bones, cartilage, muscles, or tendons. Some of the common causes are:",
      },
      {
        text: "• Poor Posture: Leaning forward for hours can cause excessive strain on your shoulder blades and spine.",
        text2: "• Herniated Disc: A slipped disc of the neck can compress nerves leading to the shoulder blade.",
        text3: "• Bone Spurs: Excessive bone growth that occurs due to arthritis can compress nerves.",
      },
      {
        text: "• Trauma or Injury: Trauma due to falls, motor vehicle accidents, or sports injuries can damage nerves in the upper back and shoulder area.",
        text2: "• Repetitive Movements: Repeatedly performing the same movement (talking or computer use) will compress nerves.",
      },
      {
        subheader: "Symptoms of Pinched Nerve Shoulder Blade",
        text: "Pinched nerve shoulder blade pain can be of any type and severity.",
      },
      {
        text: "• Sharp, burning, or shooting pain radiating from the neck to the shoulder blade.",
        text2: "• Numbness or tingling of fingers, arms, or shoulders.",
        text3: "• Weakness of affected arm or hand.",
      },
      {
        text: "• Certain actions, e.g., head tilting or lifting, can exacerbate pain.",
        text2: "• Tensing or spasm of the shoulder blade muscles.",
      },
      {
        subheader: "How to help with Shoulder Blade Pain: Treatment and Relief",
        text: "Those who want to know how to help shoulder blade pain have several home remedies and treatments from which to select.",
      },
      {
        subheader: "1. Rest and Posture Correction",
        text: "The easiest method for shoulder blade pain relief includes sufficient rest for the affected area and proper posture maintenance. While sitting, keep your back straight to minimize nerve compression.",
      },
     
      {
        subheader: "2. Gentle exercise and stretching",
        text: "• Stretching will loosen the tightness and add flexibility. Effective exercises among them are:",
        text2: "• Neck Tilts: Slowly tilt your head to one side to loosen tension in the shoulder and neck.",
        text3: "• Shoulder Rolls: Roll shoulders in a forward and backward direction to loosen up muscles.",
        text4: "• Doorway Stretch: Stand with arms out to the sides in a doorway and lean forward, stretching and expanding the chest.",
      },
      {
        subheader: "3. Ice and Heat Therapy",
        text2: "15-20 minutes of icing can decrease swelling, and a heating pad may relax contracted muscles. Alternate between heat and ice for optimal benefit.",
      },
     
      {
        subheader: "4. Over-the-Counter Shoulder Blade Pain Medicine",
        text: "Nonsteroidal anti-inflammatory medication (NSAIDs) such as ibuprofen or naproxen will decrease pain and swelling. Muscle relaxants may be employed for severe cases.",
      },
      {
        subheader: "5. Physical Therapy",
        text: "The physical therapist can prescribe exercises to make the muscles around the shoulder blade more flexible so that they don't exert further pressure on the nerves.",
      },
      {
        subheader: "6. Chiropractic Care or Massage Therapy",
        text: "Chiropractic adjustment can get the spine straightened and relieve pressure on nerves. Similarly, deep tissue massage will ease muscle tension and improve circulation.",
      },
     
      {
        subheader: "7. Medical Interventions",
        text: "For long-standing or severe shoulder blade pain, physicians can prescribe:",
      },
      {
        text: "Corticosteroid Injections: These are short-term treatments that are pain relievers by reducing swelling around the nerve.",
        text2: "Surgery: Occasionally, surgery such as a discectomy or nerve decompression can be required if other treatments fail.",
      },
     
     
      {
        subheader: "When to Visit a Doctor",
        text: "Light pain in the shoulder blades can be managed with rest, stretching, and over-the-counter shoulder blade pain medication. If the pain continues for weeks after self-treatment, though, it could be a sign of an underlying condition that needs to be treated by a doctor. Severe pain, weakness, or numbness in the arms or hands might indicate nerve involvement or another serious condition.",
        text2: "Shoulder blade pain is sometimes not musculoskeletal. When accompanied by chest pain, shortness of breath, or dizziness, it could be an indicator of a heart issue and not a pinched nerve. Such symptoms must never be ignored, as they could represent a medical emergency needing urgent assessment by a healthcare provider.",
       
      },
      {
        subheader: "Conclusion",
        text: "Having a shoulder blade pinched nerve is a discomfort, but understanding the cause and trying out various treatments of shoulder blade pain can enable you to become comfortable and mobile again. By adjusting your posture, incorporating some stretches, and visiting the physician when necessary, you can help yourself recover from it and also prevent its occurrence.",
       text2: "Experience world-class treatment and generous care at Amrit Hospital. If there is a need for regular visits or medical services, we provide staff specifically available to attend you. Book your appointment today and look after your wellbeing with expert staff. Book your consultation today and call us.",
      },
    ],
  },
  {
    id: "20",
    img: b20,
    header: "Preventive Measures for Lower Back Pain in Women",
    data: [
      {
        //subheader: "Introduction:",
        text: "Lower back pain among women is a common but typically neglected medical condition. With multiple roles in professional, household, and personal life, women tend to experience pain and discomfort in the lower back. Several factors such as lifestyle, posture, hormonal fluctuations, and diseases are the causes of this condition. Nevertheless, by using efficient preventive interventions, women are capable of minimising the risk of contracting chronic pain and maintaining their overall well-being.",
       
      },
     
      {
        subheader: "Preventive Measures for Lower Back Pain in Women",
       
      },
      {
        subheader: "1. Maintain Proper Posture:",
        text: "Maintaining appropriate posture is the most important way to avoid lower back pain. When sitting, standing, or sleeping, the spine should be supported. Be seated with flat feet on the ground to reduce pressure on the lower spine. Slouching, especially when working at a computer or phone, should be avoided. Sit in an ergonomic chair to maintain proper positioning. Rest on a firm mattress to maintain proper spinal alignment and avoid lower back pain.",
      },
      {
        subheader: "2. Regular Exercise",
        text: "Physical activity is a sure way of avoiding and curing lower back pain among women. Core muscles are built up through strength training, and they provide extra support to the lower back, reducing the likelihood of injury. Stretching, like yoga, enhances flexibility and minimizes muscle stiffness, keeping the spine flexible. Low-impact exercises like walking, swimming, and cycling enhance blood flow and avoid muscle tension, keeping the spine healthy overall.",
      },
      {
        subheader: "3. Keep a Healthy Weight",
        text: "Healthy weight management is also another key aspect against lower back pain. Excess weight puts pressure on the spine, leading to pain and even chronic complications. Eating healthy with proper nutrition aids in weight loss and reduces inflammation, hence enhancing general musculoskeletal function.",
      },
      {
        subheader: "4. Wear Supportive Footwear",
        text: "Good support shoes are an important part of spinal alignment. While stylish heels distort body posture and lead to lower back pain in women. Choose comfortable and cushioned shoes, which will support the natural curvature of the spine and offer appropriate alignment.",
      },
     
      {
        subheader: "5. Practice Stress Management",
        text: "Stress reduction can also be done to decrease lower back pain since long-term stress can lead to tension and pain in the muscles. Incorporating relaxation techniques such as deep breathing, meditation, and mindfulness exercises can relieve tension and improve overall well-being. Minimizing stress levels also prevents physical disease caused by strained muscles.",
      },
      {
        subheader: "6. Ensure Proper Lifting Techniques",
        text2: "The proper lifting techniques are essential to avoid putting too much stress on the lower back. Always lift using the knee rather than the waist. Twisting when lifting weights may cause too much stress on the lower back and should therefore be avoided.",
      },
     
      {
        subheader: "7. Remain Hydrated",
        text: "Hydration is also a major contributor to spinal wellbeing. Dehydration can cause muscle cramps and joint stiffness, so adequate water intake is an important factor for reducing lower back pain. Hydration aids in maintaining spinal discs, keeping them cushioned and functioning normally.",
      },
      {
        subheader: "8. Get Quality Sleep",
        text: "Finally, good sleep is involved in muscle recovery and back maintenance. Suitable support with a good mattress and pillows that support the spine to be in a neutral position prevents lower back pain and ensures quality sleep. Quality sleep allows the body to heal and recharge, being less painful and promoting overall well-being.",
      },
      {
        subheader: "Diagnosis of Lower Back Pain",
        text: "If the lower back pain does not go away even after taking preventive measures, one should seek medical attention. Physical examination, imaging such as X-rays and MRIs, blood testing for underlying disease, and nerve function tests to identify potential nerve damage are all part of the diagnosis of lower back pain. Early diagnosis keeps treatment on track and avoids complications.",
      },
     
      {
        subheader: "Treatment of Lower Back Pain",
        text: "Treatment of low back pain varies with the cause and intensity of the pain. Analgesics like anti-inflammatory drugs, physical therapy, and exercise can treat mild to moderate pain. Heat and cold therapy also relieve inflammation and muscle spasms. Other treatments such as acupuncture, massage, and chiropractic manipulation also reduce tension through relaxation and increased blood flow.",
        text2: "Severely chronic or acute lower back pain will require high-level treatment. Corticosteroid injection to relieve inflammation or surgery in conditions such as herniated disks or spinal deformity will be required. Lifestyle changes with proper posture, exercise, and weight control are important to prevent long-term pain and recuperation.",
      },
      
      {
        subheader: "Conclusion",
        text: "Lower back pain in women is a common complaint caused by poor posture, stress, hormonal imbalance, and illness. It can disrupt normal functioning, but practices like good posture, exercise, stress management, proper water intake, and proper rest can minimize the risk of chronic pain.",
       text2: "If there is chronic pain in the lower back, medical treatment must be done. The cause can be identified at the first level by physical checks and scanning such as X-rays, MRIs, and nerve tests. The treatment may be physical therapy, medication, acupuncture, chiropractic adjustment, or, if severe, surgery.",
       text3: "At Amrit Hospital, we provide specialized diagnosis and state-of-the-art treatment of lower back pain in women. Our experts treat each patient as an individual with personalized care for restoring mobility and comfort. Make an appointment now and start toward long-term relief.",
      },
    ],
  },
  {
    id: "21",
    img: b21,
    header: "Small White Patches on Skin: Common Causes and When to Seek Medical Help",
    data: [
      {
        //subheader: "Introduction:",
        text: "Have you ever noticed small white patches on your skin and wondered why they occur? Although most cases are harmless, some can be referring to an underlying health issue that requires diagnosis. Either by dryness, fungal infection, or immune disorder, it becomes important to know why they occur and follow the respective treatment. Let's explore some common reasons for white patches on the skin, and when should you consult a doctor?",
       
      },
     
      {
        subheader: "Common Reasons for White Patches on Skin",
       
      },
      {
        subheader: "1. Fungal Infections",
        text: "The most common reason for fungal white patches on the skin is a condition known as Tinea Versicolor, which arises because of overgrowth of yeast on the skin. This leads to white, pink, or brown pigmented patches, usually on the arms, back, and chest. The patches dry out, itch, and become more elevated after sun exposure. Treatment involves antifungal creams, shampoos, or tablets.",
      },
      {
        subheader: "2. Vitiligo",
        text: "Vitiligo is an autoimmune condition in which the pigment-producing cells are attacked by the immune system and lead to white patches on skin that can appear anywhere on the body, such as the face, hands, and feet. The smooth, depigmented patches are not contagious but may have psychological effects because of changes in appearance. Treatment may involve topical steroids, light therapy, or skin grafting if very severe.",
      },
      {
        subheader: "3. Pityriasis Alba",
        text: "Prevalent among children and adolescents, Pityriasis Alba is a mild skin disorder that results in dry white patches on the skin, especially on the face. Pale spots may have minimal scaling and most commonly are related to eczema, exacerbated in cold, dry weather. Control of the condition is achieved with moisturizing the skin and the use of mild steroid creams.",
      },
      {
        subheader: "4. Eczema and Psoriasis",
        text: "Chronic skin conditions like eczema and psoriasis may cause the dry white patches on the skin. The conditions are defined by scaly, dry skin that becomes more thickened with time, with redness and inflammation. Treatment is carried out with creams for moisturizing, anti-inflammatory medication, and dietary changes for controlling flare-ups.",
      },
     
      {
        subheader: "5. Nutritional Deficiencies",
        text: "Deficiencies in vitamins and minerals such as vitamin B12, vitamin D, and calcium may lead to small white patches on the skin. Discoloration of skin, weakness, and brittle nails may be symptoms of an existing deficiency, and if that is corrected with nutritional supplements and a proper diet, complications would be prevented.",
      },
      {
        subheader: "6. Sun Damage",
        text2: "Excessive sun exposure can lead to the small white spots on the skin, usually in fair-skinned people. The white spots, or idiopathic guttate hypomelanosis, are caused by loss of pigment and are typically harmless. Prevention can be achieved by using sunscreen and protective clothing.",
      },
     
      {
        subheader: "White Patches in Newborns",
        text: "Parents often worry about seeing newborn white patches on the skin. Some of them are harmless causes, such as birthmarks or Pityriasis Alba, while others are due to an underlying disease, such as vitiligo or fungal diseases. White spots are either associated with or without scaling, and although most will need no intervention, a pediatrician can allay their concerns.",
      },
      {
        subheader: "Could White Patches Be a Sign of Cancer?",
        text: "Although rare, white patches on the skin may also be a sign of skin cancer, particularly basal cell carcinoma. If a white patch is firm, waxy, and growing over time, or if it is accompanied by changes in texture, bleeding, or rapid expansion, it is crucial to consult a dermatologist immediately. Treatment may involve surgical removal of the patches, radiation, or chemotherapy.",
      },
      {
        subheader: "When to Seek Medical Care",
        text: "Many of the small white patches on skin are harmless, but see a doctor if the patches develop rapidly, itch, cause pain, scale, or change texture or size, or if treatments at home and with over-the-counter remedies do not work.",
      },
     
      {
        subheader: "Treatment of White Spots on Skin",
        text: "White patches on the skin are cured differently, according to the underlying condition. For fungal infections, antifungal tablets, shampoos, or creams are used. For autoimmune conditions like vitiligo, topical steroids, phototherapy, or immunosuppressive therapy can be undertaken. Dry patches are cured by mild steroid creams and moisturizers, and nutritional deficiencies can be managed by nutritional supplements and diet modification. For skin cancer, timely diagnosis and medical treatment are key.",
      },
      
      {
        subheader: "Conclusion",
        text: "Not all small white patches on the skin are a cause for concern, but it is always best to watch out for changes and seek a doctor's advice when necessary. Whether you have recurring fungal white patches on skin, dry white patches on skin, or any other abnormal symptoms, do not hesitate to consult a dermatologist.",
       text2: "At Amrit Hospital, we have expertise in treating all kinds of skin diseases and diagnosing the same. Our experienced dermatologists can help you if you are worried about white patches on your skin. Make an appointment today and get one step closer to healthy, glowing skin.",
      },
    ],
  },
  {
    id: "22",
    img: b22,
    header: "Tiny Red Spots on Skin: Are They Harmless or a Sign of an Underlying Condition?",
    data: [
      {
        //subheader: "Introduction:",
        text: "Red spots on skin can suddenly emerge & can be alarming for a person. The majority of red spot cases prove harmless, yet particular medical conditions can exist beneath these spots demanding professional healthcare. The spots which develop on skin surfaces show various dimensions alongside surface roughness levels and disease seriousness levels. The variety in itchiness levels between spots requires identification of their underlying sources because it determines the need for medical assistance.",
       
      },
     
      {
        subheader: "Common Causes of Red Spots on Skin",
       text: "There are several causes of red spots on skin, ranging from mild conditions to more serious health issues.",
      },
      {
        subheader: "Petechiae (Leukemia Tiny Red Spots on Skin)",
        text: "Small bleeding beneath the skin surface produces the tiny red spots on skin known as petechiae. The skin spots appearing from petechiae neither cause itching nor respond to touch pressure. These spots present themselves because of small injuries together with infections and excessive straining. Chronically present petechiae require medical investigation due to their potential association with leukaemia or blood clotting disorders.",
      },
      {
        subheader: "Allergic Reactions",
        text: "Allergies to food, medications, or environmental factors can lead to red spots on skin with itching. The reaction may also cause swelling, hives, or difficulty breathing. People should always avoid allergens to prevent these outbreaks.",
      },
      {
        subheader: "Heat Rash",
        text: "Hot & humid weather can cause sweat to get trapped under the skin. It will then lead to heat rashes. This condition results in tiny red spots on skin that are often itchy & painful. Cooling the body and keeping the skin dry can help dismiss such warning signs.",
      },
      {
        subheader: "Cherry Angiomas",
        text: "Cherry angiomas are small growths formed by blood vessels. These are non-cancerous. These flat red spots on skin are normal with aging. Unless they bleed or grow big enough, no action is required.",
      },
     
      {
        subheader: "Skin Infections",
        text: "Bacterial and fungal infections can cause red spots on skin with itching, or red spots on skin not itchy. Some infections (impetigo & ringworm), spread quickly. They need to be treated with antibiotics or antifungal medicines.",
      },
      {
        subheader: "Autoimmune Disorders",
        text2: "Lupus, psoriasis, and eczema will result in persistent red spots on skin. These issues can flare up because of stress, sunlight, or infections. This will lead to inflammation & discomfort.",
      },
     
      {
        subheader: "Viral Illnesses (Measles, Chickenpox, and Hand-Foot-Mouth Disease)",
        text: "Certain viral infections cause rashes. Measles begins with flat red spots on skin that spread across the body, usually accompanied by fever. Chickenpox leads to itchy, fluid-filled blisters, while hand-foot-mouth disease causes painful sores on the hands, feet, and inside the mouth.",
      },
      {
        subheader: "Blood Disorders and Leukemia",
        text: "A sudden appearance of leukemia tiny red spots on skin may indicate a blood disorder affecting platelet function. If these cancer red spots on skin are accompanied by frequent bruising, fatigue, or infections, seeking medical advice is essential.",
      },
      {
        subheader: "Skin Cancer (Cancer Red Spots on Skin)",
        text: "While most red spots on skin are harmless, some may be linked to skin cancer. Cancer red spots on skin may bleed, change shape, or fail to heal over time. If a red spot shows any of these characteristics, a dermatologist should be consulted for further evaluation.",
      },
     
      {
        subheader: "When to Seek Medical Attention",
        text: "Although many flat red spots on skin are harmless, medical attention is necessary if the spots persist for more than two weeks, spread rapidly, or are accompanied by fever, fatigue, or joint pain. If they show signs of infection, change in size or color, or appear alongside unexplained bruising or bleeding, a doctor's consultation is required.",
      },
      
      {
        subheader: "Diagnosis of Red Spots on Skin",
        text: "Doctors use different methods to diagnose red spots on skin, depending on the suspected cause. A physical examination helps identify the pattern and appearance of the spots. Blood tests may be conducted to check for infections, allergies, or blood disorders. In some cases, a skin biopsy is necessary to analyze a small sample of skin tissue under a microscope. Imaging tests may be used to diagnose internal causes.",
      },
      {
        subheader: "Treatment Options",
        text: "The therapy for red spots on skin needs to address their basic source. Doctor treats allergic reactions through antihistamine medication while also instructing patients to stay away from allergens. Medicine for bacterial infections requires antibiotics, yet antifungal creams act as a treatment for fungal infections. Physicians use immunosuppressants together with corticosteroids for managing autoimmune disorders. A patient with skin cancer requires one of three treatment options, which include surgical elimination, along with radiation therapy and chemotherapy.",
      },
      {
        subheader: "Preventive Measures",
        text: "Preventing red spots on skin involves maintaining good hygiene, using sunscreen to protect against skin damage, and identifying allergens that trigger skin reactions. Keeping the skin hydrated with moisturizers helps prevent dryness and irritation. Seeking medical advice for unexplained or persistent red spots ensures early detection and treatment of potential health concerns.",
      },
      {
        subheader: "Conclusion",
        text: "Several different causes exist, which produce red spots on skin ranging from light dermatological problems to significant medical conditions. The disappearance of certain spots occurs naturally, yet spots that persist may point to infections, blood disorders, and occasionally skin cancer. The process of monitoring symptoms combined with proper medical consultation allows for prompt diagnosis of any existing issues.",
        text2: "For a better understanding, searching for pictures of red spots on skin can help compare symptoms. But a professional diagnosis will be the best way to determine the cause along with the correct handling methods. Prioritizing skin health & early medical intervention will solve complications. This will promote complete well-being.",
        text3: "For expert diagnosis & hi-tech treatments, visit Amrit Hospital, your trusted multi-specialty healthcare provider.",
      },
      {
        subheader: "For Appointments & Enquiries-",
        text: "Purasaiwalkam – +91 76959 57120 | Sowcarpet – 044-2529 0468",
        text2: "Call Us – +91 9600 711818 | WhatsApp – +91 76959 57120",
      },
    ],
  },
  {
    id: "23",
    img: b23,
    header: "Understanding Pyelonephritis: A Severe Kidney Infection Explained",
    data: [
      {
        //subheader: "Introduction:",
        text: "The essential role of kidneys includes filtering waste and excessive fluids from the blood. Bacteria that enter the body can develop into dangerous health complications through infections. The severe condition pyelonephritis exists as a kidney infection that needs urgent medical intervention. This article explores the symptoms of kidney infection while explaining its causes, medical treatment solutions, and protective strategies.",
       
      },
     
      {
        subheader: "What is Pyelonephritis?",
       text: "Pyelonephritis is a bacterial infection of one or both kidneys.It usually starts as a urinary tract infection (UTI) that spreads upward. If untreated, it may cause damage to the kidneys, sepsis, or other life-threatening conditions.",
       text2: "This infection is more prevalent in women because their urethra is shorter, and thus bacteria can easily reach the bladder and kidneys. Men, children, and elderly people can also get this infection.",
      },
      {
        subheader: "Cause of Kidney Infection",
        text: "The most frequent reason for kidney infection (pyelonephritis) is a bacterial infection, most often Escherichia coli (E. coli), which typically resides in the intestines. When these bacteria travel up into the urinary tract through the urethra, they can multiply and move into the kidneys and cause infection.",
        text2: "Other risk factors are urinary tract blockages like kidney stones or an enlarged prostate, a compromised immune system from diabetes or other illnesses, recurrent UTIs, having a urinary catheter, and pregnancy, which compresses the urinary tract. Individuals with structural abnormalities of the urinary tract are also at greater risk of having recurrent infections.",
      },
      {
        subheader: "Kidney Infection Symptoms in Women and Men",
        text: "The symptoms and signs of kidney infection may arise suddenly and worsen quickly. Frequent symptoms include high fever and shivering, back, side, or groin pain, frequent painful urination, burning urination, cloudy and smelly urine, nausea and vomiting, fatigue, and blood in the urine.",
        text2: "In females, due to the anatomical differences, the symptoms can be more intensified. Females have the above symptoms along with pelvic pain. In some cases, women's kidney infection symptoms can be mistaken for menstrual pain or bladder infection, so early diagnosis is essential. Pregnant women should be especially careful since infections that are not treated properly will result in complications such as premature labor and low birth weights in infants.",
      },
      {
        subheader: "Diagnosis and Treatment of Kidney Infection",
        text: "If you have any of the following symptoms, get immediate medical care. A physician can conduct urine tests to check for bacteria, white blood cells, or blood, blood tests to determine the severity of the infection, and imaging tests like an ultrasound or CT scan to see if there is a blockage or abnormality.",
        text2: "The treatment of kidney infection usually involves antibiotics. The duration and type of antibiotics used are based on the severity of the infection. Some of the medications commonly used to treat kidney infection are Ciprofloxacin, Levofloxacin, Trimethoprim-sulfamethoxazole (Bactrim), and Amoxicillin in certain instances. In severe cases, hospitalization is necessary, with intravenous (IV) antibiotics being directly injected into the blood.",
        text3: "Besides antibiotics, painkillers can be given to control the symptoms. It is important to finish the full course of the medication to eliminate the kidney infection and avoid antibiotic resistance.",
      },
      {
        subheader: "Home Remedies and Lifestyle Changes",
        text: "Though antibiotics are necessary, you can also aid recovery by drinking lots of water to flush bacteria out of your system, applying heat to your lower back to relieve pain, resting extensively, and avoiding caffeine, alcohol, and spiced foods that may irritate your bladder. Drinking cranberry juice has been proposed as a natural method of avoiding UTIs, although more research is needed to confirm its effectiveness.",
      },
     
      {
        subheader: "Preventing Kidney Infections",
        text: "To minimize the risk of kidney infection (pyelonephritis), maintain good hygiene by wiping from front to back after using the toilet, drink plenty of fluids, urinate regularly, avoid rough feminine products that can upset natural bacteria balance, and treat UTIs early to keep them from spreading to the kidneys. Individuals susceptible to UTIs can be helped by lifestyle changes like using cotton underwear that allows for airflow, limiting excessive sugar consumption, and eating a balanced diet of fruits and vegetables to enhance immunity.",
      },
      {
        subheader: "When to Seek Emergency Care",
        text2: "Though mild kidney infections can be treated at home using antibiotics as prescribed, severe infections need urgent attention. Seek emergency care if you are experiencing repeated vomiting, extreme dehydration, intense fever, or excruciating pain that doesn't allow you to move. These symptoms may indicate a severe infection that will lead to kidney failure or sepsis.",
      },
     
      {
        subheader: "Conclusion",
        text: "Pyelonephritis is a serious medical illness that should be diagnosed and treated early. If you are familiar with the signs and symptoms of kidney infection, you will be able to receive medical attention promptly and avoid complications. With adequate treatment of kidney infection, most people recover well. Take care, maintain good hygiene, and protect your kidneys from future infection.",
        text2: "If you or a member of your family have symptoms of a kidney infection, wait no more. Come to Amrit Hospital and get the proper diagnosis and treatment from our professionals. Our team is here to provide the best care for your kidney well-being. Get an appointment now and start your recovery.",
      },
    ],
  },
  {
    id: "24",
    img: b24,
    header: "Understanding Musculoskeletal Pain: A Complete Guide",
    data: [
      {
        //subheader: "Introduction:",
        text: "Musculoskeletal pain is a very common disorder that happens to millions of people all around the world. Musculoskeletal pain can impact muscles, bones, ligaments, tendons, and nerves and cause limitations in movements. The musculoskeletal pain may inhibit normal daily activity and worsen the quality of life. In this guide, we will explore the reasons, symptoms, and management of musculoskeletal pain, so you will be aware of how you can prevent and manage this condition effectively. ",
       
      },
     
      {
        subheader: "What is Musculoskeletal Pain?",
       text: "Musculoskeletal pain is any pain experienced by the muscles, bones, joints, tendons, or ligaments. It may be acute, resulting from a sudden injury, or chronic, persisting for weeks, months, or years. Pain ranges from mild discomfort to severe. Musculoskeletal pain is frequently caused by overuse, improper posture, repeated motion, or medical conditions like arthritis or fibromyalgia.",
      },
      {
        subheader: "Musculoskeletal Pain ICD 10 Classification",
        text: "For doctors, musculoskeletal pain classification is necessary for diagnosis and treatment. The ICD-10 code for musculoskeletal pain differs from condition to condition. For example, myalgia is M79.1 and lower back pain is M54.5. The ICD-10 coding system enables healthcare professionals to identify and treat musculoskeletal disorders better, making it easier to diagnose and provide the right treatment plan.",
      },
      {
        subheader: "Common Types of Musculoskeletal Pain",
      },
      {
        subheader: "Musculoskeletal Pain in Chest",
        text: "Musculoskeletal chest pain can be alarming, as it may mimic cardiac illnesses. However, unlike chest pain related to the heart, musculoskeletal pain typically increases with activity or deep breathing. Muscle strain, costochondritis (inflammation of the cartilage that joins ribs to the breastbone), and trauma are some common reasons. An accurate diagnosis should be done to differentiate it from cardiac illness and determine appropriate management.",
      },
      {
        subheader: "Musculoskeletal Pain in the Breast",
        text: "A large number of individuals experience musculoskeletal pain in the breast, and this can be concerning. Musculoskeletal pain is usually linked to strained muscles, a wrong posture, or inflammation in the chest wall. It is different from breast pain associated with hormonal changes or infections. Relief of discomfort is commonly seen with the practice of stretching exercises, correction in posture, and soothing massage.",
      },
     
      {
        subheader: "Musculoskeletal Pain in Lower Back",
        text: "Lower back musculoskeletal pain is one of the most common complaints among adults. It is caused by poor posture, prolonged sitting, heavy lifting, or degenerative diseases like arthritis. The symptoms are stiffness, pain, and immobility. Pain management and mobility can be enhanced by interventions such as physical therapy, ergonomic adjustments, and exercise.",
      },
      {
        subheader: "Musculoskeletal Pain Management",
        text2: "Management of musculoskeletal pain involves a combination of lifestyle modification, medical treatments, and therapeutic interventions. Some of the key methods for managing musculoskeletal pain are mentioned below:",
      },
     
      {
        subheader: "1. Physical Therapy and Exercise",
        text: "Physical therapy assists in strengthening muscles, flexibility, and easing pain. Low-impact exercises such as walking, swimming, and yoga are beneficial for chronic musculoskeletal pain management.",
      },
      {
        subheader: "1. Physical Therapy and Exercise",
        text: "Physical therapy assists in strengthening muscles, flexibility, and easing pain. Low-impact exercises such as walking, swimming, and yoga are beneficial for chronic musculoskeletal pain management.",
      },
      {
        subheader: "1. Physical Therapy and Exercise",
        text: "Physical therapy assists in strengthening muscles, flexibility, and easing pain. Low-impact exercises such as walking, swimming, and yoga are beneficial for chronic musculoskeletal pain management.",
      },
      {
        subheader: "2. Pain Medications",
        text: "Over-the-counter pain relievers like ibuprofen and acetaminophen can also ease inflammation and pain. In severe situations, doctors can prescribe more powerful painkillers or muscle relaxants.",
      },
      {
        subheader: "3. Heat and Cold Therapy",
        text: "Applying hot and cold packs to the affected area may provide temporary relief. Heat eases the tension in muscles, while cold therapy reduces inflammation and numbs pain.",
      },
      {
        subheader: "4. Lifestyle Modifications",
        text: "Simple lifestyle changes, such as maintaining a healthy weight, good posture, and regular breaks from sitting for long periods, can prevent and reduce musculoskeletal pain.",
      },
      {
        subheader: "5. Alternative Therapies",
        text: "Many people get relief by seeking natural therapies such as acupuncture, chiropractic manipulation, and massage therapy. These treatments can complement conventional medical care and contribute to pain relief.",
      },
      {
        subheader: "When to Seek Medical Help",
        text: "While minor musculoskeletal aches can most often be addressed at home, medical attention should be sought in cases of:",
      },
      {
        text: "• Severe or persistent pain.",
        text2: "• You experience swelling, redness, or warmth in the affected area.",
        text3: "• Pain worsens at night or interferes with daily activities.",
        text4: "• Numbness, paresthesias, or limb weakness.",
      },
      {
        text: "A doctor can also perform test diagnostics such as X-rays, MRIs, or blood tests to identify the underlying cause and administer relevant treatment.",
      },
      {
        subheader: "Conclusion",
        text: "Musculoskeletal pain is a highly prevalent disorder that can affect anyone, irrespective of age. Knowing the causes, symptoms, and treatment can help people manage pain effectively. Whether it is musculoskeletal pain in the breast, musculoskeletal pain in the chest, or musculoskeletal pain in the lower back, early consultation with a doctor and a healthy lifestyle can make a big difference. If you adopt proper musculoskeletal pain management protocols, you can reduce discomfort and also improve overall well-being. If there is recurrent pain, never wait to visit a medical practitioner for the proper treatment.",
        text2: "If you are suffering from musculoskeletal pain and need expert, professional healthcare, visit Amrit Hospital. Our experienced healthcare professionals specialize in the diagnosis and treatment of all musculoskeletal diseases. Book an appointment today and receive individualized treatment to start your journey towards living a life free of pain.",
      },
    ],
  },
];
