import { Box } from "@mui/material";

import {

  // JoinUs,
  OurDoctors,
  OurServices,
  Testimonial,
  Blog,
} from "components";

import { WhyAmrit, OurExcellence, OurFacility, Banner, Programs } from "./components";

const HealthCheckup = () => {
  return (
    <Box>
      <Banner />
      <Programs />
      
      {/* <JoinUs /> */}
      <Testimonial />
      <Blog />
    </Box>
  );
};

export default HealthCheckup;
