import banner from "assets/images/banners/home/ortho.png";
import aboutus from "assets/images/banners/about/about.png";
import facility from "assets/images/banners/facility/ortho.png";
import info from "assets/images/banners/info/ortho.png";

export const data = {
  id: "ortho",
  label: "Orthopedic",
  banner: ["Best Orthopaedic Hospital in Chennai for Pain Relief", "Comprehensive Care for Stronger Bones & Joints"],
  bannerSize: ["3.375rem", "2.375rem"],

  bannerImg: banner,
  aboutImg: aboutus,
  about: [
    {
      text: "40+",
      subText: "Years of Experience",
    },
    {
      text: "2 Lac+",
      subText: "Surgeries",
    },
    {
      text: "4.8 Lac+",
      subText: "Happy Patients",
    },
    {
      text: "25000+",
      subText: "Specs Removed",
    },
  ],
  facilityImg: facility,
  infoImg: info,
  info: {
    heder: "SMART KNEE REPLACEMENT",
    subheader: "Get Back to Routine in 2-3 Weeks",
    point1: "Painless Surgery, Low Complication",
    point2: "Quick Recovery, Swift Discharge",
  },
  facility: {
    mainHeader: "Care That Gets You Moving Again",
    subHeader: "Best Orthopaedic in Chennai for Recovery",
    content:
      "Searching for the best orthopaedic hospital in Chennai? We offer specialist care for joint pain, fractures, and spine problems with the latest treatments and seasoned professionals. Our cutting-edge facilities provide quicker recovery and extended relief. Let's restore your mobility and enhance your lifestyle.",
  },
  serviceHeader: "Our Orthopedic Services",
  services: [
    {
      id: "jointReplacement",
      label: "Joint Replacement Surgery",
      content:
        "Advanced & minimally invasive joint replacement surgeries to relieve pain and restore mobility, ensuring you return to your normal life swiftly and comfortably.",
    },
    {
      id: "spineSurgery",
      label: "Spine Surgery",
      content:
        "Delivering comprehensive spine surgery with orthopedic and neurological expertise, ensuring outstanding standards of care for effective and lasting results.",
    },
    {
      id: "totalKneeReplacement",
      label: "Total Knee Replacement",
      content:
        "Offering top-tier total knee replacement surgery, where damaged bone is replaced with a durable artificial joint made of metal alloys and polymers.",
    },
    {
      id: "fractureTreatment",
      label: "Fracture Treatment",
      content:
        "Providing expert fracture care, from sports injuries to complex spinal fractures. We focus on faster recovery, and comprehensive care.",
    },
  ],
  bestPediatricServices: {
    heading: "Best Orthopaedic  Services in Chennai",
    services: [
      {
        id: "generalPediatrics",
        label: "1.Knee, Hip & Shoulder Replacement Surgery",
        content:
          "We provide replacement of knee, hip, and shoulder at the top orthopaedic hospital in Chennai with faster recovery, increased mobility, and long-term relief through intensive care. ",
      },
      {
        id: "pediatricEmergencyCare",
        label: "2.Sports Injuries Treatment",
        content:
          "Our best orthopaedic experts in Chennai treat sports injuries with accuracy, including muscle strain, ligament strain, and bone fracture, to regain strength and prevent future damage.",
      },
      {
        id: "pediatricVaccinesPreventiveCare",
        label: "3.Back Pain & Spine Care",
        content:
          "Are you suffering from chronic back pain? Our qualified spine care specialists offer minimum invasive treatment and physiotherapy for long-term comfort and healthy spinal functioning.",
      },
      {
        id: "pediatricSurgeryRecovery",
        label: "4.Fracture & Trauma Care",
        content:
          "Our Chennai orthopaedic specialists identify even the smallest fractures and major traumas accurately, deliver best-in-class surgical care, and customize rehabilitation for injury-free quick recovery. ",
      },
      {
        id: "childDevelopmentGrowthMonitoring",
        label: "5.Arthritis & Joint Pain Management",
        content:
          "Our orthopaedists in Chennai provide personalized management of arthritis through medication, physiotherapy, and joint intervention for pain reduction, flexibility, and enhanced joint mobility in the long term.",
      },
      {
        id: "pediatricNeurologyCare",
        label: "6.Pediatric Orthopaedics",
        content:
          "We provide advanced orthopaedic care to children with fractures, birth defects, and bone growth anomalies. Treatment is given in the most up-to-date manner to ensure healthy movement and growth.",
      },
      {
        id: "pediatricCardiologyServices",
        label: "7.Advanced Physiotherapy & Rehabilitation",
        content:
          "We excel at restoring mobility, strength, and flexibility through expertly crafted physiotherapy with the attention of Chennai's top orthopaedic specialists after surgery or injury.",
      },
      {
        id: "pediatricDermatologyCare",
        label: "8.Shoulder & Elbow Conditions",
        content:
          "Receive expert treatment for shoulder and elbow conditions such as rotator cuff injuries, dislocations, and arthritis for pain-free, long-lasting relief, improved function, and increased joint mobility.",
      },
    ],
  },
  faqsSection: {
    heading: "FAQs Section",
    services: [
      {
        label: "1.What Sets Amrit Hospital as a Leading Joint Replacement Center?",
        content:
          "Being a reliable name in Chennai orthopaedic care, we undertake knee, hip, and shoulder replacements with the latest surgical techniques for reduced pain, quicker healing, and long-term mobility. We offer customized post-surgical care by our expert orthopaedic surgeons and rehabilitation professionals to ensure greater movement and improved quality of life for all our patients.",
      },
      {
        label: "2.How Does Amrit Hospital Treat Sports Injuries?",
        content:
          "Our orthopaedic specialists in Chennai provide world-class treatment for muscle strains, ligament tears, and fractures with minimally invasive procedures, advanced physiotherapy, and tailored rehabilitation plans. We specialize in restoring strength, flexibility, and mobility to enable sportsmen and fitness enthusiasts to recover safely and prevent future injuries.",
      },
      {
        label: "3.What are Spine and Back Pain Treatments?",
        content:
          "Being one of the best orthopaedic hospitals in Chennai, we offer a complete range of treatments for slipped discs, back pain, and spinal issues. Our spine specialists use a combination of non-surgical interventions, physiotherapy, and minimally invasive spine surgical procedures to offer lasting relief from pain, improved posture, and greater mobility of the spine.",
      },
      {
        label: "4.How does Amrit Hospital address fractures and trauma cases?",
        content:
          "Our orthopaedic trauma unit provides 24/7 emergency care for fractures, dislocations, and complex bone injuries. As the best orthopaedic center in Chennai, we utilize advanced imaging, high-precision surgical techniques, and customized rehabilitation programs to enable quick healing and safe recovery for all patients.",
      },
      {
        label: "5.What Are the Best Treatment Options for Arthritis and Joint Pain?",
        content:
          "Our Chennai-based orthopaedic experts provide personalized arthritis treatment with a combination of pain relief, physiotherapy, lifestyle changes, and joint preservation therapies. For severe arthritis, we have advanced joint replacement surgeries, which provide long-lasting pain relief, improved flexibility, and better joint function to allow for increased mobility.",
      },
      {
        label: "6.Do you offer Pediatric Orthopaedic Services?",
        content:
          "A: Yes, our pediatric orthopaedic doctors provide specialized treatment for congenital bone disorders, fractures, and growth-related orthopaedic disorders. As part of the best orthopaedic care in Chennai, we use child-friendly approaches to treatment to offer safe, effective, and comfortable treatments for children.",
      },
      {
        label: "7.What Rehabilitation Services Are Provided After Orthopedic Surgery?",
        content:
          "As the top orthopaedic hospitals in Chennai, we provide extensive physiotherapy and rehabilitation services to support recovery following joint replacements, fractures, or sports injuries. Our skilled rehabilitation experts provide customized recovery plans, enabling patients to regain mobility, strength, and flexibility and avoid future complications.",
      },
      {
        label: "8.How Can I Book a Consultation with an Orthopedic Specialist?",
        content:
          "Scheduling a consultation at one of Chennai's top orthopaedic hospitals is simple. You can call our helpline, log on to our website, or visit our facility for an appointment. Our skilled orthopaedic experts conduct thorough evaluations, state-of-the-art diagnostic tests, and customized treatment plans to deliver the finest orthopaedic care suited to your requirements.",
      },
    ],
  },
};
