import eyecare from "assets/images/banners/home/gyno.png";
import aboutus from "assets/images/banners/about/about.png";
import facility from "assets/images/banners/facility/gyno.png";
import info from "assets/images/banners/info/gyno.png";

export const data = {
  id: "gyno",
  label: "Gynaecology",
  banner: [
    "Best Gynaecology Hospital in Chennai—Expert Care",
    "Safe, Reliable, and Advanced Gynaecology Care",
  ],
  bannerSize: ["3.25rem", "2.375rem"],

  bannerImg: eyecare,
  aboutImg: aboutus,
  about: [
    {
      text: "48+",
      subText: "Years of Experience",
    },
    {
      text: "2 Lac+",
      subText: "Surgeries",
    },
    {
      text: "4.8 Lac+",
      subText: "Happy Patients",
    },
    {
      text: "30,000+",
      subText: "Babies Born",
    },
  ],
  facilityImg: facility,
  infoImg: info,
  info: {
    heder: "C-Section Surgery",
    subheader: "Expert Care for a Safe Delivery",
    point1: "EXPERT SURGEONS , ADVANCED TECHNIQUES",
    point2: "SAFE RECOVERY, COMPASSIONATE CARE",
  },
  facility: {
    mainHeader: "Nurturing Health, Empowering Women Every Day",
    subHeader: "Best Gynaecologists in Chennai for Your Care",
    content:
      "Searching for the best gynaecology hospital in Chennai? Our skilled gynaecologists offer superior maternity, fertility, and general women's health care services. With state-of-the-art treatments and a compassionate touch, we ensure safety, comfort, and personalized attention at every step. Rely on us with professional care throughout your healthcare journey.",
  },
  serviceHeader: "Our Comprehensive Gynae Care Services",
  services: [
    {
      id: "childBirth",
      label: "Child Birth (Normal/Cesarean)",
      content:
        "Expert care for a smooth and safe delivery, whether naturally or via cesarean section. We ensure both mother and baby receive top-notch care.",
    },
    {
      id: "criticalCare",
      label: "24 Hrs Critical Care (Mother & Child)",
      content:
        "Round-the-clock critical care services for both mothers and newborns, providing immediate and expert attention to ensure their well-being.",
    },
    {
      id: "infertilityTreatment",
      label: "Infertility Treatment",
      content:
        "Comprehensive support for infertility, including follicular scans and ovulation monitoring, to help you achieve your dream of starting a family.",
    },
    {
      id: "tubectomy",
      label: "Tubectomy (Family Planning)",
      content:
        "A reliable and permanent family planning solution through tubectomy, designed to help you make informed decisions about your reproductive health.",
    },
    {
      id: "hysterectomy",
      label: "Hysterectomy",
      content:
        "Comprehensive solutions for treating gynaecological conditions through hysterectomy, removing the uterus via minimally invasive or traditional techniques based on your needs.",
    },
    {
      id: "laparoscopicSterilisation",
      label: "Laparoscopic Sterilisation",
      content:
        "Get permanent contraception with this minimally invasive procedure ensuring a quicker recovery and less discomfort, enhancing your overall comfort and care.",
    },
    {
      id: "laparoscopicHysterectomy",
      label: "Laparoscopic Hysterectomy",
      content:
        "Offering a modern approach to uterine removal with reduced recovery times and minimized surgical risks. We ensure a smoother and faster recovery.",
    },
    {
      id: "laparoscopicOvarianCystectomy",
      label: "Laparoscopic Ovarian Cystectomy",
      content:
        "Designed to effectively remove cysts with minimal impact on your body. Enjoy a faster recovery and enhanced outcomes with our advanced techniques.",
    },
    {
      id: "polypectomy",
      label: "Polypectomy, D&C",
      content:
        "Expert polypectomy and dilation and curettage (D&C) services for effective removal of uterine polyps and thorough diagnostic care. We ensure treatment with optimal precision.",
    },
    {
      id: "vaccinations",
      label: "Vaccinations",
      content:
        "Our vaccination services, including postpartum cervical cancer vaccines, are tailored to safeguard both your health and your baby’s. Receive expert care with a focus on preventing illness.",
    },
    {
      id: "papSmear",
      label: "Pap Smear",
      content:
        "Stay ahead with our advanced pap smear screening using liquid-based cytology (LBC). Our precise and reliable testing helps detect cervical abnormalities early.",
    },
    {
      id: "labService",
      label: "24 Hrs Lab Service & Dedicated Ultrasound",
      content:
        "Access round-the-clock lab services and specialized ultrasound scans, including NT and anomaly scans. We provide prenatal monitoring with a commitment to accuracy.",
    },
    {
      id: "psychologicalCounselling",
      label: "Psychological Counselling",
      content:
        "Receive compassionate psychological counselling through all stages of your journey, from pre-pregnancy to postpartum. Our supportive approach helps manage your emotional well-being.",
    },
  ],
  bestPediatricServices: {
    heading: "Best Gynaecology Services in Chennai",
    services: [
      {
        id: "generalPediatrics",
        label: "1. Comprehensive Maternity Care",
        content:
          "Welcome motherhood with confidence at Chennai's best hospital. Our best gynaecologists offer personalized prenatal care, safe delivery, and post-delivery care to ensure the best for you and your baby.",
      },
      {
        id: "pediatricEmergencyCare",
        label: "2. Latest Fertility Treatments",
        content:
          "Facing infertility problems? Our expert professionals provide IVF, IUI, and customized treatments via cutting-edge reproductive technology, allowing couples to give birth safely in the best gynaecology hospital in Chennai.",
      },
      {
        id: "pediatricVaccinesPreventiveCare",
        label: "3. Treatment of PCOS and Endometriosis",
        content:
          "Are you suffering from hormonal imbalance? Our skilled gynaecologists offer advanced endometriosis and PCOS treatment with long-lasting relief and improved reproductive health through personalized care and innovative medical therapy.",
      },
      {
        id: "pediatricSurgeryRecovery",
        label: "4. Safe & Painless Delivery Options",
        content:
          "Relish worry-free delivery with quality care. We offer painless delivery, C-section, and natural postpartum care to make your experience in the best gynaecology hospital in Chennai stress-free.",
      },
      {
        id: "childDevelopmentGrowthMonitoring",
        label: "5. Preventive Women's Health Checkups",
        content:
          "Stay ahead of your health with periodic check-ups, pap smears, breast cancer screening, and hormonal assessment by the finest gynaecologists for early diagnosis and total care in every phase of life.",
      },
      {
        id: "pediatricNeurologyCare",
        label: "6. Minimal Discomfort Laparoscopic Procedures",
        content:
          "Require specialized surgery? Our Chennai best Gynaecologists are experts at laparoscopic surgeries for fibroids, ovarian cysts, and endometriosis for minimal pain, rapid recovery, and improved safety for optimal healthcare.",
      },
      {
        id: "pediatricCardiologyServices",
        label: "7. Menopause & Hormone Health Care",
        content:
          "Experiencing menopause symptoms? Our experts offer hormone therapy, lifestyle counseling, and personalized treatments to alleviate symptoms without sacrificing long-term health and well-being.",
      },
      {
        id: "pediatricDermatologyCare",
        label: "8. Teenage & Adolescent Gynaecology Care",
        content:
          "Teenage girls must also be given special care. Our well-experienced gyanecologists guide them through puberty, menstruation, immunization, and reproductive health in general and bring them to healthy adulthood.",
      },
    ],
  },
  faqsSection: {
    heading: "FAQs Section",
    services: [
      {
        label: "1. Why is Amrit Hospital the Best Gynaecology Hospital in Chennai?",
        content:
          "Amrit Hospital is considered the best for gynaecology treatment, providing professional care in maternity, fertility, and laparoscopic surgery. Our highly qualified gynaecologists use state-of-the-art medical technology for customized treatment to provide safe and successful treatment. We emphasize high-quality women's healthcare services, which makes us the best gynaecology hospital in Chennai.",
      },
      {
        label: "2. Are the fertility treatments at Amrit Hospital advanced?",
        content:
          "Yes, we offer high-success rate fertility treatments such as IVF, IUI, and ovulation induction. Our experienced gynaecologists use the latest reproductive methods for safe and successful conception treatment. Our patient-centric approach assists couples in overcoming infertility issues, which has made our hospital one of the most popular gynaecology fertility hospitals in Chennai.",
      },
      {
        label: "3. What is the treatment of PCOS and endometriosis in Amrit Hospital?",
        content:
          "In Amrit Hospital, we manage PCOS and endometriosis with overall PCOS and endometriosis treatment under hormonal therapy, lifestyle modifications, and advanced laparoscopic management. Our Chennai best gynaecologists attempt to detect solutions for long-term cycle control, controlling pain, and enhancing fertility. Utilizing the latest technology, we address the underlying reason, which brings customized efficient treatment for all-around women's healthcare in Chennai.",
      },
      {
        label: "4. What kind of laparoscopic procedures are done at Amrit Hospital?",
        content:
          "We offer minimally invasive laparoscopic surgery for fibroids, ovarian cysts, endometriosis, and hysterectomy. These revolutionary procedures guarantee rapid recovery, fewer discomforts, and minimal scarring. Our professional gynaecologists equipped with the finest medical technology make us one of the best gynaecology hospitals in Chennai to perform laparoscopic surgery.",
      },
      {
        label: "5. Why would I choose Amrit Hospital to treat menopause?",
        content:
          "Menopause is a hormonal change in health, but our professional gynaecologists give personalized hormone treatment, diet, and lifestyle counseling. We manage osteoporosis, heart disease, and menopausal syndrome comfortably. Our treatment of personalized care ensures long-term health; thus, we are the most reliable gynaecology hospital in Chennai to manage menopause.",
      },
      {
        label: "6. Does Amrit Hospital provide teenage and adolescent gynaecology facilities?",
        content:
          "Yes, we also offer teenage girls specialized gynaecology services such as regulation of the menstrual cycle, hormonal disorder treatment, and HPV vaccination. Our highly experienced gynaecologists offer advice for puberty-related issues in a safe and comfortable environment. We educate young girls about reproductive health and offer them the best care from professional experts at our best hospital in Chennai.",
      },
      {
        label: "7. How can I schedule an appointment with Amrit Hospital's top gynaecologist?",
        content:
          "Taking an appointment is an easy task. Patients can reach out to our hospital either through calling or through our online appointment booking system. The staff at our facility will organize an appointment with Chennai’s best gynaecologists in a time slot that is convenient for you. We offer prompt, efficient treatment to you.",
      },
    ],
  },
};
