import { Typography } from "@mui/material";
import { BannerContainer } from "./Banner.styled";
import contact from "assets/images/banners/healthcheckup.png";

const Banner = () => {
  return (
    <>
      <BannerContainer
        image={contact}
        px={{ md: 10, xs: 5 }}
        display="flex" 
        justifyContent="center"
        flexDirection="column"
      >
        <Typography
          fontSize={{ md: "4.125rem", xs: "2.25rem" }}
          fontWeight={600}
        >
          Your Health, Our Priority
        </Typography>
        <Typography
            fontSize={{ md: "3.375rem", xs: "2.375rem" }}
            fontWeight="500"
          >
            Stay Ahead Of Your Health!
          </Typography>
      </BannerContainer>
    </>
  );
};

export default Banner;
