import { Box, Typography, Link, Grid, Pagination } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import img1 from "assets/images/blog/1.jpg";
import img2 from "assets/images/blog/2.jpg";
import img3 from "assets/images/blog/3.jpg";
import img4 from "assets/images/blog/4.jpg";
import img5 from "assets/images/blog/5.jpg";
import img6 from "assets/images/blog/6.jpg";
import img7 from "assets/images/blog/7.jpg";
import img8 from "assets/images/blog/8.jpg";
import img9 from "assets/images/blog/9.jpg";
import img10 from "assets/images/blog/10.jpg";
import img11 from "assets/images/blog/11.jpg";
import img12 from "assets/images/blog/12.jpg";
import img13 from "assets/images/blog/13.jpg";
import img14 from "assets/images/blog/14.jpg";
import img15 from "assets/images/blog/15.jpg";
import img16 from "assets/images/blog/16.jpg";
import img17 from "assets/images/blog/17.jpg";
import img18 from "assets/images/blog/18.jpg";
import img19 from "assets/images/blog/19.jpg";
import img20 from "assets/images/blog/20.jpg";
import img21 from "assets/images/blog/21.jpg";
import img22 from "assets/images/blog/22.jpg";
import img23 from "assets/images/blog/23.jpg";
import img24 from "assets/images/blog/24.jpg";

const blogs = [
  { img: img1, title: "Chennai’s Leading Endoscopic Thyroid Surgery at Amrit Hospital", text: "Thyroid surgery has traditionally involved a visible neck incision, which can leave patients with noticeable scars and longer recovery times. ", link: "/blog/1" },
  { img: img2, title: "The Importance of Regular Eye Check-Ups", text: "Regular eye check-ups are vital to maintaining clear vision and overall eye health. Unfortunately, many people overlook the importance of visiting an ophthalmologist until a problem arises.", link: "/blog/2" },
  { img: img3, title: "Navigating Multi-Specialty Healthcare", text: "When it comes to healthcare, convenience and comprehensive care are crucial. Amrit Hospital in Chennai excels in providing multi-specialty healthcare, making it a one-stop solution for all your medical needs. ", link: "/blog/3" },
  { img: img4, title: "Expert Cataract Surgery at Amrit Hospital", text: "Cataracts are one of the leading causes of vision impairment, particularly in older adults. If left untreated, cataracts can lead to significant vision loss, severely affecting your quality of life.", link: "/blog/4" },
  { img: img5, title: "5 Cataract Surgery Side Effects & How to Recover", text: "Cataract surgery is one of the most successful medical procedures, with very high success rates and rapid recovery for most patients. But it’s not necessarily risk-free, like any surgical procedure.", link: "/blog/5" },
  { img: img6, title: "5 Best Foods for Eye Health", text: "A healthy, balanced diet is required at all times, not just to improve your health but also to improve the health of your eyes and prevent a host of issues.", link: "/blog/6" },
  { img: img7, title: "Femto Laser Cataract Surgery: Revolutionizing Modern Cataract Treatment", text: "Cataracts, one of the most common causes of vision loss worldwide, are no longer the challenge they once were.", link: "/blog/7" },
  { img: img8, title: "Post Cataract Surgery Precautions & Recovery", text: "Cataract is easily operated, and it is one of the most successful surgeries through which you can get your vision back. However, the recovery process is very effective across different countries and cultures", link: "/blog/8" },
  { img: img9, title: "5 Important Stages of Child Growth and Development", text: "Understanding the stages of growth and development is crucial for parents, educators, and healthcare professionals. Children's growth is a dynamic process influenced by genetics, environment, and nutrition.", link: "/blog/9" },
  { img: img10, title: "10 Foods That Increase Fertility in Women", text: "Food intake plays an important role in fertility boosters and chances of conception. Balanced and nutritious dieting helps control your hormonal health, supports ovulation, and improves the quality of eggs in the ovaries.", link: "/blog/10" },
  { img: img11, title: "Apple Cider Vinegar During Pregnancy: Good or Bad?", text: "Apple cider vinegar has recently gained much attention for its various health benefits. But when it comes to pregnancy, all expectant mothers have the following query: Is it safe for pregnant women to drink apple cider vinegar?", link: "/blog/11" },
  { img: img12, title: "Cosmetic Gynaecology: Everything You Need to Know", text: "Cosmetic gynaecology has become very popular as women seek treatments that enhance the look and function of their intimate areas. These treatments address a variety of issues ranging", link: "/blog/12" },
  { img: img13, title: "Normal Delivery Tips: What to Do to Avoid a C-Section", text: "Pregnancy is indeed one of the most exciting times; however, most expectant mothers fear whether they will have a normal delivery or will require a C-section. ", link: "/blog/13" },
  { img: img14, title: "Best Diet for Renal Calculi Patients: How to Prevent Recurrence", text: "Renal calculi, or kidney stones, are a painful condition that affects millions of people around the globe. If you have experienced kidney stones, you understand how important it is to avoid their recurrence. ", link: "/blog/14" },
  { img: img15, title: "Signs of a Fungal Ear Infection: How to Identify and Treat It", text: "Ear fungal infection, or otomycosis, is a commonly misunderstood condition that affects the outer ear. A fungal infection differs from bacterial infections because it persists stubbornly, which leads to discomfort", link: "/blog/15" },
  { img: img16, title: "Understanding Gallbladder Stone Disease: What You Need to Know", text: "Human bodies operate through complex interconnected systems even while specific minor issues sometimes create major discomfort. Gallbladder stone disease represents a common condition that affects millions across the globe.", link: "/blog/16" },
  { img: img17, title: "Hair Fall Treatment Solutions: Medical and Natural Remedies That Work", text: "Hair fall is something that concerns both men and women, leading to distress and loss of confidence. Whatever the cause—be it genetic, hormonal changes, stress, or external factors—finding the ideal", link: "/blog/17" },
  { img: img18, title: "Lump Behind Ear: Causes, Symptoms, and When to See a Doctor", text: "Finding a lump behind the ear can be alarming. If it's a pea-sized lump behind the earlobe with no pain, a hard painless lump behind the ear, or a small lump behind the ear, it's natural to be concerned.", link: "/blog/18" },
  { img: img19, title: "Pinched Nerve in the Shoulder Blade: Causes, Symptoms, and Treatment", text: "Shoulder blade pain can be a painful and frustrating experience, hindering simple activities such as lifting, turning, or even sleeping. Whether left shoulder blade pain or right shoulder blade pain, ", link: "/blog/19" },
  { img: img20, title: "Preventive Measures for Lower Back Pain in Women", text: "Lower back pain among women is a common but typically neglected medical condition. With multiple roles in professional, household, and personal life, women tend to experience pain and discomfort in the lower back.", link: "/blog/20" },
  { img: img21, title: "Small White Patches on Skin: Common Causes and When to Seek Medical Help", text: "Have you ever noticed small white patches on your skin and wondered why they occur? Although most cases are harmless, some can be referring to an underlying health issue that requires diagnosis. ", link: "/blog/21" },
  { img: img22, title: "Tiny Red Spots on Skin: Are They Harmless or a Sign of an Underlying Condition?", text: "Red spots on skin can suddenly emerge & can be alarming for a person. The majority of red spot cases prove harmless, yet particular medical conditions can exist beneath these spots demanding professional healthcare.", link: "/blog/22" },
  { img: img23, title: "Understanding Pyelonephritis: A Severe Kidney Infection Explained", text: "The essential role of kidneys includes filtering waste and excessive fluids from the blood. Bacteria that enter the body can develop into dangerous health complications through infections. ", link: "/blog/23" },
  { img: img24, title: "Understanding Musculoskeletal Pain: A Complete Guide", text: "Musculoskeletal pain is a very common disorder that happens to millions of people all around the world.", link: "/blog/24" },
];

const BlogItem = ({ img, title, text }) => {
  return (
    <Box>
      <Box>
        <img
          src={img}
          alt="blog"
          width="100%"
          height={"100%"}
          style={{ objectFit: "cover", borderRadius: "0.5rem" }}
        />
      </Box>
      <Box>
        <Typography fontSize="1.25rem" fontWeight="bold" mb={2}>
          {title}
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Box>
  );
};

const Blogs = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(blogs.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const displayedBlogs = blogs.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <Box py={{ md: 10, xs: 4 }} px={{ md: 18, xs: 4 }} bgcolor="#f6f6f6">
      <Typography fontSize={"3.125rem"} fontWeight="bold" mb={6} textAlign="center">
        Latest Blog & News
      </Typography>
      <Grid container spacing={5}>
        {displayedBlogs.map((blog, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link
              boxShadow={"0 0 10px 0 rgba(153, 153, 153, 0.2)"}
              p={4}
              height={"calc(100% - 3rem)"}
              borderRadius={"1rem"}
              bgcolor={"common.white"}
              component={RouterLink}
              to={blog.link}
              textDecoration="none"
              color="common.black"
              sx={{ textDecoration: "none", display: "inline-table", "&:hover": { textDecoration: "none" } }}
            >
              <BlogItem img={blog.img} title={blog.title} text={blog.text} />
            </Link>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
      </Box>
    </Box>
  );
};

export default Blogs;
